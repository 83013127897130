import { FC, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

import { ThreeDotsIcon } from '../../assets/icons';
import { Popover } from './Popover';
import { PopoverClose } from './PopoverClose';
import { PopoverContent } from './PopoverContent';
import { PopoverTrigger } from './PopoverTrigger';

export type PopoverWrapperProps = Optional<
  { children: ReactNode },
  {
    items: {
      onClick: () => void;
      text: string;
      icon: ReactNode;
      className?: string;
      condition?: boolean;
    }[];
  }
>;

export type PopoverWrapperItemProps = Optional<
  {
    children: ReactNode;
  },
  { icon: ReactNode; text: string }
> & {
  onClick: () => void;
  className?: string;
};

export const PopoverWrapperItem: FC<PopoverWrapperItemProps> = ({
  children,
  onClick,
  className,
  icon,
  text,
}) => (
  <div
    className={twMerge(
      'flex w-full items-center gap-2 p-[6px] pr-8 text-sm font-normal text-gray-700 hover:bg-gray-50',
      className,
    )}
    onClick={onClick}
  >
    {icon}
    {text}
    {children}
  </div>
);

export const PopoverWrapper: FC<PopoverWrapperProps> = ({ children, items }) => (
  <Popover>
    <PopoverTrigger className="h-fit w-fit rounded data-[state=open]:shadow-md">
      <div className="flex size-6 rotate-90 items-center justify-center rounded bg-transparent transition-colors hover:bg-gray-100">
        <ThreeDotsIcon />
      </div>
    </PopoverTrigger>
    <PopoverContent className="flex w-fit flex-col gap-[2px] rounded p-2 font-normal shadow-2xl">
      <PopoverClose>
        {items
          ? items.map(
              ({ condition = true, ...item }, i) =>
                condition && <PopoverWrapperItem {...item} key={`${i}_${new Date().getTime()}`} />,
            )
          : children}
      </PopoverClose>
    </PopoverContent>
  </Popover>
);

import React, { FC, ReactNode, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { BackendRoute } from '../config';
import { HttpException } from '../exceptions';
import { ApiService } from '../services';

interface AuthGuardProps {
  children: ReactNode;
}

const AuthGuard: FC<AuthGuardProps> = ({ children }) => {
  const navigate = useNavigate();

  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const validateAccess = useCallback(async () => {
    try {
      await ApiService.get({ endpoint: BackendRoute.VALIDATE });
      setIsAuthenticated(true);
    } catch (e) {
      if (e instanceof HttpException) {
        navigate('/login');
      } else throw e;
    } finally {
      setIsLoading(false);
    }
  }, [navigate]);

  useEffect(() => {
    validateAccess();
  }, [navigate, validateAccess]);

  if (isLoading || !isAuthenticated) return <></>;

  return <>{children}</>;
};

export default AuthGuard;

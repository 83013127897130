import { FC } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { NotificationHooks } from '../../../hooks';
import {
  notificationActionTitle,
  requestNotificationBuilder,
} from '../../../types/notification.types';
import Loader from '../../Loader';
import { ResolvedNotification } from '../Notification';

export type ResolvedTabProps = {
  adminEmail: string;
};

export const ResolvedTab: FC<ResolvedTabProps> = ({ adminEmail }) => {
  const { notifications, fetchNextPage, hasNextPage } = NotificationHooks.useNotifications({
    adminEmail,
    resolved: true,
  });

  return (
    <>
      <InfiniteScroll
        className="flex w-full flex-col gap-2"
        dataLength={notifications.length || 0}
        hasMore={hasNextPage}
        loader={<Loader />}
        next={fetchNextPage}
        scrollableTarget="scroll-notifications-target"
      >
        {notifications.map(({ createdAt, id, action, adminEmail, companyName }) => (
          <ResolvedNotification
            createdAt={new Date(createdAt)}
            key={id}
            message={requestNotificationBuilder({
              companyName,
            })}
            requestDate={new Date(createdAt)}
            requestMessage={`${adminEmail} marked the request as resolved`}
            type={(action && notificationActionTitle[action]) || ''}
          />
        ))}
      </InfiniteScroll>
    </>
  );
};

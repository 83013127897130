import React, { FC, useContext } from 'react';

import { BurgerMenuIcon, CloseIcon, LogoutIcon } from '../../assets/icons';
import adminAvatar from '../../assets/images/adminAvatar.png';
import { accessTokenStorage } from '../../storage/accessTokenStorage';
import { sideBarContext } from '../../storage/sideBarContext';
import { pageTitle, replaceIdWithPath } from '../../types/page.types';
import Button from '../Button';
import { Popover, PopoverClose, PopoverContent, PopoverTrigger } from '../Popover';
import { Tag, TagVariant } from '../Tag';

interface HeaderProps {
  pageName?: string;
}

const Header: FC<HeaderProps> = () => {
  const { closeSideBar, isOpen, openSideBar } = useContext(sideBarContext);

  const handleLogout = () => {
    accessTokenStorage.delete();
    window.location.reload();
  };

  return (
    <div className="flex w-full items-center justify-between rounded-b-[12px] bg-white px-4 py-3 font-inter shadow-xs lg:rounded-b-[24px]">
      <div className="flex w-full items-center gap-2">
        <Button
          className="size-5 lg:hidden"
          onClick={isOpen ? closeSideBar : openSideBar}
          styleType="NONE"
        >
          {isOpen ? (
            <CloseIcon className="size-[10px]" />
          ) : (
            <BurgerMenuIcon height={20} width={20} />
          )}
        </Button>
        <div className="text-base font-[550] text-gray-700 lg:text-xl">
          {pageTitle[replaceIdWithPath(location.pathname)]}
        </div>
      </div>
      <div className="flex w-1/2 items-center justify-end gap-4">
        <Tag title=" Admin Control Center" variant={TagVariant.BLUE} />
        <Popover>
          <PopoverTrigger className="flex size-8 items-center justify-center overflow-hidden rounded-[32px] bg-brand-100 ring-brand-700 data-[state=open]:ring-2 max-lg:hidden">
            <img alt="admin avatar" className="size-8" src={adminAvatar} />
          </PopoverTrigger>
          <PopoverContent className="flex w-fit flex-col gap-[6px] rounded p-2 text-sm font-normal shadow-2xl">
            <PopoverClose className="w-[168px] divide-y-[1px] divide-gray-200">
              <>
                <div className="flex items-center gap-[6px] px-2 py-[6px]" onClick={handleLogout}>
                  <LogoutIcon />
                  <span className="text-sm font-[450] text-fireside-600">Log out</span>
                </div>
              </>
            </PopoverClose>
          </PopoverContent>
        </Popover>
      </div>
    </div>
  );
};

export default Header;

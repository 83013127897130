import { FC } from 'react';
import { twMerge } from 'tailwind-merge';

import Button from '../../../components/Button';

export type ModalProps = {
  onDismiss: () => void;
  onSuccess: () => void;
  title: string;
  text: string;
  cancelButtonText: string;
  submitButtonText: string;
};

export const Modal: FC<ModalProps> = ({
  onDismiss,
  onSuccess,
  cancelButtonText,
  submitButtonText,
  text,
  title,
}) => (
  <div className="m-2 flex w-full max-w-[453px] flex-col gap-8 rounded bg-white p-4">
    <div className="flex flex-col gap-6 pr-[90px]">
      <div className="flex flex-col gap-1">
        <span className={twMerge('text-xl font-[550] text-gray-700')}>{title}</span>
        <span className={twMerge('text-xs font-[450] text-gray-500')}>{text}</span>
      </div>
    </div>
    <div className="flex h-11 justify-end gap-3">
      <Button
        className={twMerge(
          'border-[1px] border-gray-300 px-6 py-[10px] text-sm font-[450] text-gray-700 transition-colors hover:bg-gray-50',
        )}
        onClick={onDismiss}
        styleType="DEFAULT_ROUNDED"
      >
        {cancelButtonText}
      </Button>
      <Button
        className={twMerge(
          '"bg-brand-700 w-fit rounded bg-brand-700 px-6 py-[10px] text-sm font-[550] text-white',
        )}
        onClick={onSuccess}
        styleType="NONE"
      >
        {submitButtonText}
      </Button>
    </div>
  </div>
);

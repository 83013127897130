import { FC } from 'react';
import { twMerge } from 'tailwind-merge';

export enum TagVariant {
  GREEN = 'bg-forest-50 text-forest-600',
  BLUE = 'bg-brand-50 text-brand-700',
  RED = 'bg-fireside-50 text-fireside-600',
  GRAY = 'bg-gray-100 text-gray-500',
  YELLOW = 'bg-sunbeam-50 text-sunbeam-700',
}

export type TagProps = { variant: TagVariant; title: string };

export const Tag: FC<TagProps> = ({ title, variant }) => {
  return (
    <span
      className={twMerge(
        'whitespace-nowrap rounded-2xl px-2 py-[2px] !text-label-md !font-medium',
        variant,
      )}
    >
      {title}
    </span>
  );
};

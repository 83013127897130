export const localStorageUtil = <T>(key: string) => ({
  delete: () => localStorage.removeItem(key),
  get: () => {
    const response = localStorage.getItem(key);
    if (!response) return null;
    return JSON.parse(response) as T;
  },
  key,
  set: (value: Partial<T>) => {
    if (value instanceof Object) {
      const response = localStorage.getItem(key);
      const parsedResponse = response && (JSON.parse(response) as T);
      if (parsedResponse instanceof Object)
        return localStorage.setItem(key, JSON.stringify({ ...parsedResponse, ...value }));
    }

    if (value instanceof Array) {
      const response = localStorage.getItem(key);
      if (!response) return null;
      const parsedResponse = JSON.parse(response) as T;

      if (parsedResponse instanceof Array)
        return localStorage.setItem(key, JSON.stringify([...parsedResponse, ...value]));
    }

    return localStorage.setItem(key, JSON.stringify(value));
  },
});

//// Rebuild util into a class

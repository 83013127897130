import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query';
import { useMemo } from 'react';

import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { ApiService } from '../../services';
import { AppNotification, NotificationType } from '../../types/notification.types';

export type NotificationResponse = {
  notifications: AppNotification[];
  totalPages: number;
};

export const useNotifications = ({
  adminEmail,
  currentPage = 0,
  take = 100,
  resolved,
  type,
  unreadOnly,
}: {
  adminEmail: string;
  take?: number;
  currentPage?: number;
  type?: NotificationType;
  resolved?: boolean;
  unreadOnly?: boolean;
}) => {
  const queryClient = useQueryClient();

  const skip = (currentPage - 1 <= 0 ? 0 : currentPage - 1) * take;
  const { data, fetchNextPage, hasNextPage, isLoading } = useInfiniteQuery<NotificationResponse>({
    queryKey: [
      QueryKey.GET_NOTIFICATIONS,
      {
        adminEmail,
        resolved,
        type,
        take,
        skip,
        unreadOnly,
      },
    ],
    queryFn: ({ pageParam = 0 }) =>
      ApiService.get(
        { endpoint: BackendRoute.NOTIFICATIONS },
        {
          queryParams: {
            adminEmail,
            take,
            skip: currentPage ? skip : pageParam,
            resolved,
            type,
            unreadOnly,
          },
        },
      ),
    initialPageParam: 0,
    getNextPageParam: (lastPage, allPages) =>
      lastPage.totalPages > allPages.length ? allPages.length * take : undefined,
    enabled: !!adminEmail,
  });

  const notificationList = useMemo(
    () =>
      data?.pages?.reduce<AppNotification[]>((acc, page) => {
        return [...acc, ...page.notifications];
      }, []),
    [data?.pages],
  );

  const invalidateQuery = () => {
    queryClient.invalidateQueries({ queryKey: [QueryKey.GET_NOTIFICATIONS] });
  };
  const refetchQuery = () => {
    queryClient.refetchQueries({ queryKey: [QueryKey.GET_NOTIFICATIONS] });
  };
  return {
    invalidateQuery,
    notifications: notificationList || [],
    fetchNextPage,
    totalPages: data?.pages[0]?.totalPages || 0,
    hasNextPage,
    isLoading,
    refetchQuery,
  };
};

import React, { FC, ReactNode } from 'react';

import HeaderLayout from '../HeaderLayout';
import SideBarLayout from '../SideBarLayout';

export type AppLayoutProps = {
  children: ReactNode;
  className?: string;
};

const AppLayout: FC<AppLayoutProps> = ({ children, className }) => (
  <>
    <SideBarLayout>
      <HeaderLayout className={className}>{children}</HeaderLayout>
    </SideBarLayout>
  </>
);

AppLayout.displayName = 'AppLayout';

export default AppLayout;

import React, { FC } from 'react';

import { SlashCircleIcon } from '../../../assets/icons';
import Button from '../../../components/Button';
import { Checkbox } from '../../../components/Checkbox';
import { Sheet, SheetContent } from '../../../components/Sheet';
import { Company } from '../../../types/company.types';

export type BulkActionsModalProps = {
  selectedCompaniesIds: string[];
  companies: Company[];
  isOpenModal: boolean;
  onRemoveSelectAll: () => void;
  handleDeactivate: (ids: string[]) => void;
};

export const BulkActionsModal: FC<BulkActionsModalProps> = ({
  isOpenModal,
  companies,
  selectedCompaniesIds,
  onRemoveSelectAll,
  handleDeactivate,
}) => {
  const selectedCompanies = companies.filter(({ id }) => selectedCompaniesIds.includes(id));

  const hasDeactivatedCompanies = selectedCompanies.some((company) => company.toCancelAt);

  const isPlural = selectedCompaniesIds.length > 1;

  return (
    <Sheet modal={false} open={isOpenModal}>
      <SheetContent
        className="m-auto flex h-fit w-fit justify-center border-transparent bg-transparent p-0 pb-6 shadow-none"
        side="BOTTOM"
      >
        <div className="flex h-10 items-center justify-center divide-x divide-gray-100 rounded-[40px] bg-white px-4 py-2 shadow-2xl">
          <div
            className="flex h-5 items-center gap-3 text-nowrap pr-3 text-sm font-[450] text-gray-700"
            onClick={onRemoveSelectAll}
          >
            <Checkbox checked={selectedCompaniesIds.length > 0} />
            {selectedCompaniesIds.length} {isPlural ? 'rows' : 'row'} selected
          </div>
          {!hasDeactivatedCompanies && (
            <Button
              className="flex h-5 gap-2 text-nowrap pl-3 text-sm font-[450] text-fireside-500"
              onClick={() => handleDeactivate(selectedCompaniesIds)}
              styleType="NONE"
            >
              <SlashCircleIcon iconColor="#F04438" />
              {isPlural ? 'Deactivate Companies' : 'Deactivate Company'}
            </Button>
          )}
        </div>
      </SheetContent>
    </Sheet>
  );
};

import React, { FC, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

import {
  CapQuestLogo,
  CompaniesIcon,
  DashboardIcon,
  DoubleChevronIcon,
  LogoutIcon,
  LogsIcon,
  SettingsIcon,
  StakeholdersIcon,
} from '../../assets/icons';
import { IconProps } from '../../assets/icons/type';
import { useScreenSize } from '../../hooks';
import { sideBarContext } from '../../storage/sideBarContext';
import { PageRoute } from '../../types/page.types';
import Button from '../Button';
import { HoverCard, HoverCardContent, HoverCardTrigger } from '../HoverCard';
import { Sheet, SheetContent, SheetFooter } from '../Sheet';
import { SideBarItem } from './SideBarItem';

export type SideBarOption = {
  IconComponent: FC<IconProps>;
  link: string;
  title: string;
};

export const sideBarOptions: SideBarOption[] = [
  {
    link: PageRoute.DASHBOARD,
    title: 'Dashboard',
    IconComponent: DashboardIcon,
  },
  {
    link: PageRoute.STAKEHOLDERS,
    title: 'Stakeholders',
    IconComponent: StakeholdersIcon,
  },
  {
    link: PageRoute.COMPANIES,
    title: 'Companies',
    IconComponent: CompaniesIcon,
  },
  {
    link: PageRoute.LOGS,
    title: 'Logs',
    IconComponent: LogsIcon,
  },
  {
    link: PageRoute.SETTINGS,
    title: 'Settings',
    IconComponent: SettingsIcon,
  },
];

const SideBar: FC = () => {
  const navigate = useNavigate();
  const { width: screenWidth } = useScreenSize();

  const { closeSideBar, isOpen: isExpanded, openSideBar } = useContext(sideBarContext);

  const location = useLocation();
  const isMobile = screenWidth <= 1023;

  return (
    <>
      <div className={twMerge('bg-gray-50 px-4 py-3 max-lg:hidden')}>
        <div className={twMerge('flex h-full flex-col justify-between')}>
          <div
            className={twMerge(
              'flex w-10 flex-col justify-stretch gap-3 transition-all duration-300',
              isExpanded && 'w-[230px]',
            )}
          >
            <Button className="size-10" onClick={() => navigate(PageRoute.HOME)} styleType="NONE">
              <CapQuestLogo className="mb-3 mt-1" size={32} />
            </Button>
            <div className={twMerge('flex w-full flex-col gap-3')}>
              {sideBarOptions.map((props, i) => {
                return (
                  <HoverCard key={`${props.title}_${i}`}>
                    <HoverCardTrigger>
                      <SideBarItem
                        Icon={props.IconComponent}
                        isExpanded={isExpanded}
                        isSelected={location.pathname.includes(props.link)}
                        onClick={() => {
                          navigate(props.link);
                        }}
                        {...props}
                      />
                    </HoverCardTrigger>
                    {!isExpanded && (
                      <HoverCardContent
                        className="z-20 w-max rounded-lg bg-[#101828] px-3 py-2"
                        isShownTriangle
                        side="right"
                        triangleClassName="left-0 top-1/2 transform -translate-y-1/2 "
                      >
                        <span className="text-label-md font-[550] text-white">{props.title}</span>
                      </HoverCardContent>
                    )}
                  </HoverCard>
                );
              })}
            </div>
          </div>
          <Button
            className={twMerge(
              'relative flex size-10 shrink-0 items-center justify-start overflow-hidden transition-all duration-300',
              isExpanded && 'w-full',
            )}
            onClick={isExpanded ? closeSideBar : openSideBar}
            styleType="NONE"
          >
            <div className="z-1 flex size-10 shrink-0 items-center justify-center">
              <DoubleChevronIcon
                className={twMerge('transition-all', !isExpanded && 'rotate-180')}
                iconColor={isExpanded ? '#344054' : '#98A2B3'}
              />
            </div>
            <span
              className={twMerge(
                'absolute left-3 z-0 w-0 overflow-hidden text-xs font-[450] text-gray-700',
                isExpanded && 'w-[100px]',
              )}
            >
              Collapse
            </span>
          </Button>
        </div>

        {isMobile && (
          <Sheet modal={false} open={isExpanded}>
            <SheetContent className="mt-[64px] flex w-full gap-0 border-transparent bg-transparent p-0 shadow-none">
              <div
                className="flex h-full min-w-full flex-col gap-3 overflow-y-auto rounded-lg bg-gray-50 p-3"
                style={{ height: 'calc(100% - 64px)' }}
              >
                <div className="flex h-fit w-full flex-col gap-3 rounded-lg bg-white px-3 py-4">
                  {sideBarOptions.map((props, i) => (
                    <SideBarItem
                      Icon={props.IconComponent}
                      isExpanded={isExpanded}
                      isSelected={location.pathname.includes(props.link)}
                      key={`${props.title}_${i}`}
                      onClick={() => {
                        closeSideBar();
                        navigate(props.link);
                      }}
                      {...props}
                    />
                  ))}
                  <SheetFooter className="flex h-fit w-full flex-col rounded-lg bg-white px-3 py-4">
                    <SideBarItem
                      Icon={LogoutIcon}
                      isExpanded={isExpanded}
                      isSelected={false}
                      onClick={() => {
                        closeSideBar();
                      }}
                      specialIconColor="#D92D20"
                      specialTitleColor="text-fireside-600"
                      title="Log out"
                    />
                  </SheetFooter>
                </div>
              </div>
              <div className="h-full w-full shrink-0" onClick={closeSideBar} />
            </SheetContent>
          </Sheet>
        )}
      </div>
    </>
  );
};

SideBar.displayName = 'SideBar';

export default SideBar;

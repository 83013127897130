import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { polyfillCountryFlagEmojis } from 'country-flag-emoji-polyfill';
import React, { FC, ReactNode } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { NotificationsProvider } from './context/notifications.context';

interface AppProps {
  children: ReactNode;
}

const queryClient = new QueryClient();

const App: FC<AppProps> = ({ children }) => {
  polyfillCountryFlagEmojis();
  // useRedirectToHttps();

  return (
    <QueryClientProvider client={queryClient}>
      <NotificationsProvider>
        <div className="App [&>*]:font-inter [&>*]:font-normal">{children}</div>
      </NotificationsProvider>

      {/* {process.env.REACT_APP_ENV === 'development' && (
          <ReactQueryDevtools initialIsOpen={false} />
        )} */}

      <ToastContainer
        bodyClassName={'h-full p-[3px]'}
        className="flex flex-col items-center gap-2 pb-1"
        closeButton={false}
        closeOnClick
        limit={3}
        position="bottom-center"
        toastClassName={() =>
          'rounded-[40px] h-fit shadow-2xl overflow-hidden relative bg-white text-gray-700 text-sm font-[450] p-2 lg:text-nowrap w-fit px-3 py-2'
        }
      />
    </QueryClientProvider>
  );
};

export default App;

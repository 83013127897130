import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query';
import { useMemo } from 'react';

import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { ApiService } from '../../services';
import {
  Company,
  CompanyAllFreqFilter,
  CompanyAllPaymentFilter,
  CompanyAllPlanFilter,
  CompanyOrder,
  CompanySortBy,
} from '../../types/company.types';

type GetCompaniesResponse = {
  companies: Company[];
  totalPages: number;
  totalCount: number;
};

export const useCompanies = ({
  take = 100,
  currentPage = 0,
  search,
  onlyNames,
  orderBy,
  orderMode,
  planType,
  planFrequency,
  subscriptionStatus,
  isEnterpriseRequested = false,
}: {
  take?: number;
  currentPage?: number;
  search?: string;
  onlyNames?: boolean;
  orderBy?: CompanySortBy;
  orderMode?: CompanyOrder;
  planType?: CompanyAllPlanFilter;
  planFrequency?: CompanyAllFreqFilter;
  subscriptionStatus?: CompanyAllPaymentFilter;
  isEnterpriseRequested?: boolean;
}) => {
  const queryClient = useQueryClient();

  const skip = (currentPage - 1 <= 0 ? 0 : currentPage - 1) * take;
  const { data, fetchNextPage, hasNextPage } = useInfiniteQuery<GetCompaniesResponse>({
    queryKey: [
      QueryKey.GET_COMPANIES,
      {
        page: currentPage,
        take,
        search,
        onlyNames,
        orderBy,
        orderMode,
        planType,
        planFrequency,
        subscriptionStatus,
        isEnterpriseRequested,
      },
    ],
    queryFn: ({ pageParam = 0 }) =>
      ApiService.get(
        { endpoint: BackendRoute.COMPANIES },
        {
          queryParams: {
            take,
            skip: currentPage ? skip : pageParam,
            search,
            onlyNames,
            orderBy,
            orderMode,
            planType: planType === 'all' ? '' : planType,
            planFrequency: planFrequency === 'all' ? '' : planFrequency,
            subscriptionStatus: subscriptionStatus === 'all' ? '' : subscriptionStatus,
            isEnterpriseRequested: isEnterpriseRequested ? true : undefined,
          },
        },
      ),
    initialPageParam: 0,
    getNextPageParam: (lastPage, allPages) =>
      lastPage.totalPages > allPages.length ? allPages.length * take : undefined,
  });

  const companiesList = useMemo(
    () =>
      data?.pages?.reduce<Company[]>((acc, page) => {
        return [...acc, ...page.companies];
      }, []),
    [data?.pages],
  );

  const invalidateQuery = () => {
    queryClient.invalidateQueries({ queryKey: [QueryKey.GET_COMPANIES] });
  };

  return {
    fetchNextPage,
    hasNextPage,
    invalidateQuery,
    companies: companiesList || [],
    totalPages: data?.pages[0]?.totalPages || 0,
  };
};

import React, { FC } from 'react';

import { SummaryItem } from '../../../components/SummaryItem';
import { CompanyDetailsProps } from './CompanyDetails';

export const CompanyAnalytics: FC<CompanyDetailsProps> = ({ company }) => {
  return (
    <div className="flex h-fit flex-col gap-2 rounded-lg bg-gray-50 pt-2">
      <span className="px-4 text-sm font-[550] text-gray-700">Details</span>
      <div className="flex flex-col divide-y divide-gray-100 rounded-b-lg border border-gray-100 bg-white p-4 pb-3">
        <SummaryItem
          className="pt-0"
          title="No of Investors"
          value={company?.investorsCount || '-'}
        />
        <SummaryItem title="Stakeholders" value={company?.stakeholdersCount || '-'} />
        <SummaryItem
          title="Total Investment"
          value={`$ ${company?.totalInvestment?.toLocaleString('en-US') || 0}`}
        />
        <SummaryItem
          className="pb-0"
          title="NAV (Latest Valuation)"
          value={`$ ${company?.nav?.toLocaleString('en-US') || 0}`}
        />
      </div>
    </div>
  );
};

import React, { FC } from 'react';
import { twMerge } from 'tailwind-merge';

import { IconProps } from './type';

export type CompaniesIconProps = IconProps;

const CompaniesIcon: FC<CompaniesIconProps> = ({ iconColor = '#98A2B3', className, ...props }) => (
  <svg
    className={twMerge('shrink-0', className)}
    fill="none"
    height="18"
    viewBox="0 0 20 18"
    width="20"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.8334 8.16667H14.8334C15.7668 8.16667 16.2335 8.16667 16.5901 8.34832C16.9037 8.50811 17.1586 8.76308 17.3184 9.07668C17.5001 9.4332 17.5001 9.89991 17.5001 10.8333V16.5M10.8334 16.5V4.16667C10.8334 3.23325 10.8334 2.76654 10.6518 2.41002C10.492 2.09641 10.237 1.84144 9.9234 1.68166C9.56688 1.5 9.10017 1.5 8.16675 1.5H5.16675C4.23333 1.5 3.76662 1.5 3.4101 1.68166C3.09649 1.84144 2.84153 2.09641 2.68174 2.41002C2.50008 2.76654 2.50008 3.23325 2.50008 4.16667V16.5M18.3334 16.5H1.66675M5.41675 4.83333H7.91675M5.41675 8.16667H7.91675M5.41675 11.5H7.91675"
      stroke={iconColor}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
  </svg>
);

export default CompaniesIcon;

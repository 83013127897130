export enum QueryKey {
  GET_COMPANIES,
  GET_COMPANY,
  DEACTIVATE_COMPANY,
  GET_SUBSCRIPTION_DATA,
  CHANGE_SUBSCRIPTION,
  GET_STAKEHOLDERS,
  GET_STAKEHOLDER,
  GET_COMPANY_ADMIN_STAKEHOLDER,
  DELETE_STAKEHOLDER,
  TOGGLE_STAKEHOLDER_ACCESS,
  BULK_STAKEHOLDER_ACTIONS,
  PATCH_STAKEHOLDER_ROLE,
  GET_NOTIFICATIONS,
  GET_UNREAD_NOTIFICATION_COUNT,
  MARK_NOTIFICATION_AS_READ,
  MARK_NOTIFICATION_AS_RESOLVED,
}

import { useQueryClient } from '@tanstack/react-query';

import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { ApiService } from '../../services';
import { NotificationType } from '../../types/notification.types';
import { useAppQuery } from '../useAppQuery';

export const useUnreadNotificationCount = ({
  adminEmail,
  type,
}: {
  adminEmail: string;
  type?: NotificationType;
}) => {
  const queryClient = useQueryClient();

  const { data: count, isLoading } = useAppQuery({
    queryKey: [QueryKey.GET_UNREAD_NOTIFICATION_COUNT, adminEmail, type],
    queryFn: () =>
      ApiService.get<number>(
        {
          endpoint: BackendRoute.NOTIFICATIONS,
          routePath: ['unread-count'],
        },
        { queryParams: { type, adminEmail } },
      ),
    enabled: !!adminEmail,
  });

  const invalidateQuery = () => {
    queryClient.invalidateQueries({ queryKey: [QueryKey.GET_UNREAD_NOTIFICATION_COUNT] });
  };

  return {
    unread: {
      count,
      type,
    },
    isLoading,
    invalidateQuery,
  };
};

import React, { FC, useCallback, useEffect } from 'react';
import { useWatch } from 'react-hook-form';
import { toast } from 'react-toastify';
import { twMerge } from 'tailwind-merge';

import Button from '../../../components/Button';
import { CloseModalButton } from '../../../components/CloseModalButton';
import { FormInput } from '../../../components/Input';
import { Sheet, SheetContent } from '../../../components/Sheet';
import { Tag, TagVariant } from '../../../components/Tag';
import { CompanyHooks, useFormat, useReactForm } from '../../../hooks';
import { useCompany } from '../../../hooks/company/useCompany';
import { CompanyFreqFilter } from '../../../types/company.types';
import {
  SubscriptionDuration,
  SubscriptionStatus,
  subscriptionStatusTitle,
  SubscriptionType,
} from '../../../types/plan.types';
import { companyTypeTranslation } from '../ViewCompanyModal/types';
import { FrequencyCombobox } from './FrequencyCombobox';
import { SubscriptionTypeCombobox } from './SubscriptionTypeCombobox';
import { FormSchema, formSchema } from './validation';

export type EditSubscriptionModalProps = {
  isOpenModal: boolean;
  handleClose: () => void;
  invalidateQuery: () => void;
  companyId: string;
  className?: string;
};

export const EditSubscriptionModal: FC<EditSubscriptionModalProps> = ({
  isOpenModal,
  handleClose,
  invalidateQuery,
  companyId,
  className,
}) => {
  const { company } = useCompany(companyId);
  const { changeSubscription, isPending } = CompanyHooks.useChangeSubscription();

  const {
    control,
    reset,
    handleSubmit,
    setValue,
    clearErrors,
    formState: { errors, isDirty },
  } = useReactForm({
    schema: formSchema,
  });

  const { planType, frequency } = useWatch<FormSchema>({ control });
  const { data: subscription } = CompanyHooks.useSubscriptionData({
    companyId,
    type: planType || SubscriptionType.ENTERPRISE,
    frequency: frequency || SubscriptionDuration.MONTHLY,
  });

  const { format } = useFormat();

  useEffect(() => {
    if (!company) return;

    reset({
      planType: company?.planType || SubscriptionType.ENTERPRISE,
      amount: (company?.latestSubscriptionData?.plan?.amount || 0) / 100,
      stakeholderLimit: company?.stakeholdersLimit,
      frequency:
        company?.planFrequency === CompanyFreqFilter.MONTH
          ? SubscriptionDuration.MONTHLY
          : SubscriptionDuration.ANNUALY,
      currency: subscription?.currency?.toUpperCase(),
    });
  }, [company, reset]);

  const handleCloseModal = useCallback(() => {
    handleClose();
    invalidateQuery();
    reset();
  }, [handleClose, invalidateQuery, reset]);

  const submitHandler = useCallback(
    (data: FormSchema) => {
      const { amount, stakeholderLimit, frequency, planType } = data;

      changeSubscription(
        {
          companyId,
          data: {
            amount: amount * 100,
            frequency: frequency,
            stakeholderLimit,
            type: planType,
          },
        },
        {
          onSuccess: () => {
            toast.success('Subscription successfully updated');
            invalidateQuery();
            handleCloseModal();
          },
        },
      );
    },
    [companyId, handleCloseModal, invalidateQuery],
  );

  const handleUpdate = () => {
    handleSubmit(submitHandler)();
  };

  useEffect(() => {
    if (subscription) {
      setValue('currency', subscription.currency.toUpperCase());

      if (
        company?.planType === SubscriptionType.ENTERPRISE &&
        planType === SubscriptionType.ENTERPRISE
      ) {
        setValue('amount', (company?.latestSubscriptionData?.plan?.amount || 0) / 100);
        setValue('stakeholderLimit', company?.stakeholdersLimit || 0);
      } else {
        setValue('amount', subscription.amount / 100);
        setValue('stakeholderLimit', subscription.stakeholderLimit);
        clearErrors();
      }
    }
  }, [subscription, setValue, planType, company?.planType]);

  return (
    <Sheet open={isOpenModal}>
      <SheetContent
        className={twMerge(
          'w-full max-w-[485px] border-transparent bg-transparent p-2 shadow-none',
          className,
        )}
        onInteractOutside={handleCloseModal}
        side="RIGHT"
      >
        <div className="flex h-full w-full flex-col overflow-hidden rounded-lg border-[1px] border-gray-300 bg-gray-100 pb-4">
          <div className="flex h-fit w-full items-center justify-between bg-white px-6 py-3">
            <span className="truncate text-xl font-semibold text-gray-700">Edit Subscription</span>
            <CloseModalButton onClose={handleCloseModal} />
          </div>
          <div className="flex h-full flex-col gap-6 overflow-y-auto rounded-b-xl border-b border-gray-300 bg-white px-6 py-2">
            <div className="flex w-full justify-between gap-2">
              <div className="flex w-full flex-col gap-1 rounded-t-[4px] bg-gray-50 px-3 py-2">
                <span className="text-xs font-[450] text-gray-500">Creation Date</span>
                <span className="text-sm font-[450] text-gray-700">
                  {company?.createdAt && format(company?.createdAt, 'dd/MM/yyyy')}
                </span>
              </div>
              <div className="flex w-full flex-col gap-1 rounded-t-[4px] bg-gray-50 px-3 py-2">
                <span className="text-xs font-[450] text-gray-500">Company Type</span>
                <span className="text-sm font-[450] text-gray-700">
                  {company?.type && companyTypeTranslation[company.type]}
                </span>
              </div>
            </div>

            <div className="flex flex-col gap-4">
              <div className="flex justify-between gap-1">
                <span className="py-[6px] text-m font-semibold text-gray-700">Subscription</span>
                <div className="flex items-center gap-3">
                  {company?.isEnterpriseRequested ? (
                    <Tag title="Awaiting for approval" variant={TagVariant.YELLOW} />
                  ) : company?.isActive && !company?.toCancelAt ? (
                    <Tag title="Active" variant={TagVariant.GREEN} />
                  ) : company?.toCancelAt && new Date(company?.toCancelAt) > new Date() ? (
                    <Tag
                      title={`Active until ${format(company?.toCancelAt, 'dd/MM/yyyy')}`}
                      variant={TagVariant.YELLOW}
                    />
                  ) : (
                    <Tag title="Inactive" variant={TagVariant.GRAY} />
                  )}

                  {company?.subscriptionStatus === SubscriptionStatus.ACTIVE ? (
                    <Tag title="Active" variant={TagVariant.GREEN} />
                  ) : (
                    <Tag
                      title={
                        company?.subscriptionStatus
                          ? subscriptionStatusTitle[company?.subscriptionStatus]
                          : 'No subscription'
                      }
                      variant={TagVariant.GRAY}
                    />
                  )}
                </div>
              </div>
              <div className="flex w-full justify-between gap-2">
                <div className="flex w-full flex-col gap-1 rounded-t-[4px] bg-gray-50 px-3 py-2">
                  <span className="text-xs font-[450] text-gray-500">Subscription start</span>
                  <span className="text-sm font-[450] text-gray-700">
                    {company?.latestSubscriptionData?.plan?.created
                      ? format(company?.latestSubscriptionData?.plan?.created * 1000, 'dd/MM/yyyy')
                      : '-'}
                  </span>
                </div>
                <div className="flex w-full flex-col gap-1 rounded-t-[4px] bg-gray-50 px-3 py-2">
                  <span className="text-xs font-[450] text-gray-500">Next Payment</span>
                  <span className="text-sm font-[450] text-gray-700">
                    {company?.nextPayment
                      ? format(Number(company?.nextPayment) * 1000, 'dd/MM/yyyy')
                      : '-'}
                  </span>
                </div>
              </div>
            </div>
            <div className="flex w-full flex-col gap-2">
              <SubscriptionTypeCombobox control={control} />
              <FrequencyCombobox control={control} />
              <FormInput
                control={control}
                disabled={true}
                name={'currency'}
                placeholder="Currency"
                wrapperClassName="w-full"
              />
              <FormInput
                control={control}
                disabled={planType !== SubscriptionType.ENTERPRISE}
                name={'amount'}
                numberOnly
                placeholder="Amount"
                wrapperClassName="w-full"
              />
              <FormInput
                control={control}
                disabled={planType !== SubscriptionType.ENTERPRISE}
                name={'stakeholderLimit'}
                numberOnly
                placeholder="Stakeholder limit"
                wrapperClassName="w-full"
              />
            </div>
          </div>

          <div className="mt-4 flex h-9 w-full justify-between gap-3 px-6">
            <Button
              className="w-fit bg-transparent px-4 py-[6px] text-sm font-[450] text-gray-700"
              onClick={handleCloseModal}
              styleType="NONE"
              type="button"
            >
              Cancel
            </Button>

            <Button
              className="h-full w-fit px-4 py-[6px] text-sm font-[550] text-white"
              disabled={Object.keys(errors).length > 0 || !isDirty}
              isLoading={isPending}
              onClick={handleUpdate}
              type="button"
            >
              Update
            </Button>
          </div>
        </div>
      </SheetContent>
    </Sheet>
  );
};

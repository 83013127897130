import { createBrowserRouter } from 'react-router-dom';

import AppLayout from './components/AppLayout';
import { AuthGuard } from './guards';
import Companies from './pages/Companies';
import Dashboard from './pages/Dashboard';
import Error from './pages/Error';
import Home from './pages/Home';
import Login from './pages/Login';
import Logs from './pages/Logs';
import Settings from './pages/Settings';
import Stakeholders from './pages/Stakeholders';
import { PageRoute } from './types/page.types';

const router = createBrowserRouter([
  {
    path: PageRoute.HOME,
    element: (
      <AuthGuard>
        <AppLayout>
          <Home />
        </AppLayout>
      </AuthGuard>
    ),
  },
  {
    path: PageRoute.DASHBOARD,
    element: (
      <AuthGuard>
        <AppLayout>
          <Dashboard />
        </AppLayout>
      </AuthGuard>
    ),
  },
  {
    path: PageRoute.COMPANIES,
    element: (
      <AuthGuard>
        <AppLayout>
          <Companies />
        </AppLayout>
      </AuthGuard>
    ),
  },
  {
    path: PageRoute.STAKEHOLDERS,
    element: (
      <AuthGuard>
        <AppLayout>
          <Stakeholders />
        </AppLayout>
      </AuthGuard>
    ),
  },
  {
    path: PageRoute.LOGS,
    element: (
      <AuthGuard>
        <AppLayout>
          <Logs />
        </AppLayout>
      </AuthGuard>
    ),
  },
  {
    path: PageRoute.SETTINGS,
    element: (
      <AuthGuard>
        <AppLayout>
          <Settings />
        </AppLayout>
      </AuthGuard>
    ),
  },
  {
    path: PageRoute.LOGIN,
    element: <Login />,
  },
  {
    path: PageRoute.ERROR,
    element: <Error />,
  },
]);

export default router;

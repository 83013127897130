import React, { FC, memo } from 'react';
import { twMerge } from 'tailwind-merge';

import { IconProps } from '../../assets/icons/type';

export type SideBarItemProps = {
  Icon: FC<IconProps>;
  isExpanded: boolean;
  isSelected: boolean;
  specialIconColor?: string;
  specialTitleColor?: string;
  title: string;
  onClick?: () => void;
  className?: string;
};

export const SideBarItem: FC<SideBarItemProps> = memo(
  ({
    Icon,
    isExpanded,
    isSelected,
    onClick,
    title,
    className,
    specialIconColor,
    specialTitleColor,
  }) => {
    return (
      <div
        className={twMerge(
          'flex w-10 cursor-pointer items-center gap-[10px] transition-all duration-300',
          isExpanded && 'w-full pr-8',
          isSelected && 'rounded-lg bg-white shadow-sm',
          className,
        )}
        onClick={onClick}
      >
        <div className={twMerge('flex size-10 shrink-0 items-center justify-center')}>
          {Icon ? (
            <Icon
              iconColor={specialIconColor ? specialIconColor : isSelected ? '#2565C8' : '#98A2B3'}
            />
          ) : null}
        </div>
        <span
          className={twMerge(
            'w-fit overflow-hidden text-nowrap text-sm font-[450] text-gray-400',
            specialTitleColor ? specialTitleColor : isSelected && 'text-brand-700',
          )}
        >
          {title}
        </span>
      </div>
    );
  },
);

SideBarItem.displayName = 'SideBarItem';

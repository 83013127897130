import {
  CompanyAnnualRevenue,
  CompanyIndustry,
  CompanyNumberOfEmployees,
  CompanyType,
} from '../../../types/company.types';

export enum ViewCompanyTabType {
  DETAILS = 'details',
  ANALYTICS = 'analytics',
  SUBSCRIPTIONS = 'subscriptions',
  REQUESTS = 'requests',
}

export const ViewCompanyTabTitle: Record<ViewCompanyTabType, string> = {
  [ViewCompanyTabType.DETAILS]: 'Details',
  [ViewCompanyTabType.ANALYTICS]: 'Analytics',
  [ViewCompanyTabType.SUBSCRIPTIONS]: 'Subscription',
  [ViewCompanyTabType.REQUESTS]: 'Requests',
};

export const arrayOfViewCompanyTab = Object.entries(ViewCompanyTabTitle);

export const companyTypeTranslation: Record<CompanyType, string> = {
  [CompanyType.PRIVATE_EQUITY]: 'Private Equity',
  [CompanyType.VENTURE_CAPITAL]: 'Venture Capital',
  [CompanyType.SPV]: 'SPV',
  [CompanyType.FAMILY_OFFICE]: 'Family Office',
  [CompanyType.COMPANY]: 'Company',
  [CompanyType.OTHER]: 'Other financial institutions',
};

export const companyIndustryTranslation: Record<CompanyIndustry, string> = {
  [CompanyIndustry.AGRICULTURE_FARMING]: 'Agriculture & Farming',
  [CompanyIndustry.AEROSPACE]: 'Aerospace',
  [CompanyIndustry.ART]: 'Art',
  [CompanyIndustry.ARTIFICIAL_INTELLIGENCE]: 'Artificial Intelligence',
  [CompanyIndustry.AUTOMOTIVE]: 'Automotive',
  [CompanyIndustry.BANKING_FINANCIAL_SERVICES]: 'Banking & Financial Services',
  [CompanyIndustry.BIOTECHNOLOGY]: 'Biotechnology',
  [CompanyIndustry.BUILDING_MATERIALS_SUPPLIES]: 'Building Materials & Supplies',
  [CompanyIndustry.BUSINESS_SERVICES]: 'Business Services',
  [CompanyIndustry.CHEMICAL_INDUSTRY]: 'Chemical Industry',
  [CompanyIndustry.CLEANING_PRODUCTS_SERVICES]: 'Cleaning Products & Services',
  [CompanyIndustry.CLOUD_COMPUTING]: 'Cloud Computing',
  [CompanyIndustry.COMPUTER_HARDWARE]: 'Computer Hardware',
  [CompanyIndustry.CONSTRUCTION]: 'Construction',
  [CompanyIndustry.CONSUMER_ELECTRONICS]: 'Consumer Electronics',
  [CompanyIndustry.CONSUMER_GOODS]: 'Consumer Goods',
  [CompanyIndustry.CONSUMER_HEALTHCARE]: 'Consumer Healthcare',
  [CompanyIndustry.CONSULTING]: 'Consulting',
  [CompanyIndustry.CULTURE]: 'Culture',
  [CompanyIndustry.CYBERSECURITY]: 'Cybersecurity',
  [CompanyIndustry.DATA_STORAGE_MANAGEMENT]: 'Data Storage & Management',
  [CompanyIndustry.DEFENSE]: 'Defense',
  [CompanyIndustry.E_COMMERCE]: 'E-commerce',
  [CompanyIndustry.EDUCATION_TRAINING]: 'Education & Training',
  [CompanyIndustry.ELECTRIC_VEHICLES]: 'Electric Vehicles',
  [CompanyIndustry.ENERGY]: 'Energy (Oil & Gas, Renewable Energy)',
  [CompanyIndustry.ENTERTAINMENT]: 'Entertainment',
  [CompanyIndustry.ENVIRONMENTAL_SERVICES]: 'Environmental Services',
  [CompanyIndustry.EVENTS]: 'Events',
  [CompanyIndustry.FABRICS_TEXTILES]: 'Fabrics & Textiles',
  [CompanyIndustry.FASHION]: 'Fashion',
  [CompanyIndustry.FINTECH]: 'Fintech',
  [CompanyIndustry.FOOD_BEVERAGES]: 'Food & Beverages',
  [CompanyIndustry.GOVERNMENT_PUBLIC_INSTITUTIONS]: 'Government & Public institutions',
  [CompanyIndustry.HEALTHCARE]: 'Healthcare',
  [CompanyIndustry.HOSPITALITY]: 'Hospitality',
  [CompanyIndustry.INFORMATION_TECHNOLOGIES]: 'Information technologies',
  [CompanyIndustry.INTERNET_OF_THINGS]: 'Internet of Things',
  [CompanyIndustry.INSURANCE]: 'Insurance',
  [CompanyIndustry.LEGAL]: 'Legal',
  [CompanyIndustry.LEISURE_RECREATION]: 'Leisure & Recreation',
  [CompanyIndustry.LOGISTICS_SUPPLY_CHAIN]: 'Logistics & Supply chain',
  [CompanyIndustry.LUXURY_GOODS]: 'Luxury goods',
  [CompanyIndustry.MACHINERY_HEAVY_EQUIPMENT]: 'Machinery & Heavy Equipment',
  [CompanyIndustry.MANUFACTURING]: 'Manufacturing',
  [CompanyIndustry.MARKETING_ADVERTISING]: 'Marketing & Advertising',
  [CompanyIndustry.MEDIA]: 'Media',
  [CompanyIndustry.MEDICAL_DEVICES_SUPPLIES]: 'Medical devices & Supplies',
  [CompanyIndustry.MINING]: 'Mining',
  [CompanyIndustry.MUSIC]: 'Music',
  [CompanyIndustry.MOBILITY_TRANSPORTATION]: 'Mobility & Transportation',
  [CompanyIndustry.PERSONAL_SERVICES]: 'Personal Services',
  [CompanyIndustry.PET_CARE_SUPPLIES]: 'Pet care & Supplies',
  [CompanyIndustry.PHARMACEUTICALS]: 'Pharmaceuticals',
  [CompanyIndustry.PROFESSIONAL_SERVICES]: 'Professional Services',
  [CompanyIndustry.PUBLISHING]: 'Publishing',
  [CompanyIndustry.REAL_ESTATE_PROPTECH]: 'Real Estate & Proptech',
  [CompanyIndustry.RESTAURANTS_FOOD_SERVICES]: 'Restaurants & Food Services',
  [CompanyIndustry.RETAIL]: 'Retail',
  [CompanyIndustry.ROBOTICS]: 'Robotics',
  [CompanyIndustry.SMART_CITY]: 'Smart City',
  [CompanyIndustry.SOCIAL_MEDIA]: 'Social Media',
  [CompanyIndustry.SOFTWARE_DEVELOPMENT]: 'Software Development',
  [CompanyIndustry.SPORTS_FITNESS]: 'Sports & Fitness',
  [CompanyIndustry.TELECOM_CONNECTIVITY]: 'Telecom & Connectivity',
  [CompanyIndustry.TRAVEL_TOURISM]: 'Travel & Tourism',
  [CompanyIndustry.VIDEO_GAMES]: 'Video Games',
  [CompanyIndustry.WASTE_MANAGEMENT]: 'Waste Management',
  [CompanyIndustry.NON_PROFIT]: 'Non Profit',
  [CompanyIndustry.OTHER]: 'Other',
};

export const companyAnnualRevenueTranslation: Record<CompanyAnnualRevenue, string> = {
  [CompanyAnnualRevenue.BETWEEN_1_5_MIL]: 'No Rev',
  [CompanyAnnualRevenue.BETWEEN_5_20_MIL]: '<1M',
  [CompanyAnnualRevenue.LESS_1_MIL]: '6-25M',
  [CompanyAnnualRevenue.MORE_20_MIL]: '25-100M',
  [CompanyAnnualRevenue.NO_REV]: '>100M',
};

export const companyNumberOfEmployeesTranslation: Record<CompanyNumberOfEmployees, string> = {
  [CompanyNumberOfEmployees.BETWEEN_1_10]: '1-10',
  [CompanyNumberOfEmployees.BETWEEN_11_25]: '11-25',
  [CompanyNumberOfEmployees.BETWEEN_26_50]: '26-50',
  [CompanyNumberOfEmployees.BETWEEN_51_250]: '51-250',
  [CompanyNumberOfEmployees.MORE_250]: '>250',
};

import { Field, Label } from '@headlessui/react';
import React, { FC } from 'react';
import { twMerge } from 'tailwind-merge';

import {
  CopyLinkIcon,
  EditIcon,
  EyeIconSmall,
  SlashCircleIcon,
  ThreeDotsIcon,
} from '../../../assets/icons';
import { Checkbox } from '../../../components/Checkbox';
import { Popover, PopoverClose, PopoverContent, PopoverTrigger } from '../../../components/Popover';
import { Tag, TagVariant } from '../../../components/Tag';
import { useFormat } from '../../../hooks';
import { Company, CompanyFreqFilterTitle } from '../../../types/company.types';
import {
  SubscriptionStatus,
  subscriptionStatusTitle,
  subscriptionTypeTitle,
} from '../../../types/plan.types';
import { copyToClipboard } from '../../../utils/copy-to-clipboard';

export type CompanyTableItemProps = {
  isSelected: (id: string) => boolean;
  onSelect: (id: string) => void;
  onRemoveSelect: (id: string) => void;
  handleView: (id: string) => void;
  handleEdit: (id: string) => void;
  handleDeactivate: (id: string) => void;
  company: Company;
};

export const CompanyTableItem: FC<CompanyTableItemProps> = ({
  isSelected,
  onSelect,
  onRemoveSelect,
  handleView,
  handleEdit,
  handleDeactivate,
  company,
}) => {
  const {
    id,
    name,
    lastInvoiceLink,
    isActive,
    toCancelAt,
    subscriptionStatus,
    stakeholdersCount,
    planType,
    planFrequency,
    nextPayment,
    adminName,
    isEnterpriseRequested,
  } = company;

  const { format } = useFormat();

  return (
    <tr className={twMerge('w-full bg-white', isSelected(id) && 'bg-gray-50')}>
      <td className="relative">
        <div className="flex h-full w-full cursor-pointer items-center justify-center">
          <Field className="flex items-center">
            <Checkbox
              checked={isSelected(id)}
              id={id}
              onChange={(checked) => {
                checked ? onSelect(id) : onRemoveSelect(id);
              }}
            />
            <Label className="absolute left-0 top-0 h-full w-full cursor-pointer" htmlFor={id} />
          </Field>
        </div>
      </td>
      <td className="max-w-[137px] truncate text-nowrap p-4 text-sm font-[450] text-gray-700">
        {name}
      </td>
      <td className="max-w-[200px] truncate p-4 text-sm text-gray-700">{adminName}</td>
      <td className="overflow-hidden p-4">
        <span className="max-w-[100px] truncate text-sm text-gray-700">{stakeholdersCount}</span>
      </td>
      <td className="p-4 text-sm text-gray-700">
        {planType ? subscriptionTypeTitle[planType] : '-'}
      </td>
      <td className="p-4 text-sm text-gray-700">
        {planFrequency ? CompanyFreqFilterTitle[planFrequency] : '-'}
      </td>
      <td className="p-4 text-sm">
        {isEnterpriseRequested ? (
          <Tag title="Awaiting for enterprise request approval" variant={TagVariant.YELLOW} />
        ) : isActive && !toCancelAt ? (
          <Tag title="Active" variant={TagVariant.GREEN} />
        ) : toCancelAt && new Date(toCancelAt) > new Date() ? (
          <Tag
            title={`Active until ${format(toCancelAt, 'dd/MM/yyyy')}`}
            variant={TagVariant.YELLOW}
          />
        ) : (
          <Tag title="Inactive" variant={TagVariant.GRAY} />
        )}
      </td>
      <td className="p-4 text-sm">
        {subscriptionStatus === SubscriptionStatus.ACTIVE ||
        subscriptionStatus === SubscriptionStatus.TRIALING ? (
          <Tag title={subscriptionStatusTitle[subscriptionStatus]} variant={TagVariant.GREEN} />
        ) : (
          <Tag
            title={
              subscriptionStatus ? subscriptionStatusTitle[subscriptionStatus] : 'No subscription'
            }
            variant={TagVariant.GRAY}
          />
        )}
      </td>
      <td className="p-4 text-sm text-gray-700">
        {nextPayment ? format(nextPayment * 1000, 'dd/MM/yyyy') : '-'}
      </td>
      <td className="px-4 py-[22px]">
        <Popover>
          <PopoverTrigger className="h-fit w-fit rounded data-[state=open]:shadow-md">
            <div className="flex size-6 rotate-90 items-center justify-center rounded bg-transparent transition-colors hover:bg-gray-100">
              <ThreeDotsIcon />
            </div>
          </PopoverTrigger>
          <PopoverContent className="flex w-fit flex-col gap-[2px] rounded p-2 font-normal shadow-2xl [&>*]:font-inter [&>*]:font-normal">
            <PopoverClose>
              <div
                className="flex w-full items-center gap-2 p-[6px] pr-8 text-sm text-gray-700 hover:bg-gray-50"
                onClick={() => handleView(id)}
              >
                <EyeIconSmall />
                <span className="text-sm font-normal text-gray-700">View Company</span>
              </div>

              <div
                className="flex w-full items-center gap-2 p-[6px] pr-8 text-sm text-gray-700 hover:bg-gray-50"
                onClick={() => handleEdit(id)}
              >
                <EditIcon />
                <span className="text-sm font-normal text-gray-700">Edit subscription</span>
              </div>

              <div
                className="flex w-full items-center gap-2 p-[6px] pr-8 text-sm text-gray-700 hover:bg-gray-50"
                onClick={() => copyToClipboard(lastInvoiceLink)}
              >
                <CopyLinkIcon />
                <span className="text-sm font-normal text-gray-700">Copy Link</span>
              </div>

              {!toCancelAt && subscriptionStatus && (
                <div
                  className="flex w-full items-center gap-2 p-[6px] pr-8 text-sm text-gray-700 hover:bg-gray-50"
                  onClick={() => handleDeactivate(id)}
                >
                  <SlashCircleIcon />
                  <span className="text-sm font-normal text-gray-700">Deactivate Company</span>
                </div>
              )}
            </PopoverClose>
          </PopoverContent>
        </Popover>
      </td>
    </tr>
  );
};

import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { ApiService } from '../../services';
import { Company } from '../../types/company.types';
import { useAppQuery } from '../useAppQuery';

export const useCompany = (companyId: string) => {
  const queryClient = useQueryClient();

  const { data: company, isLoading } = useAppQuery<Company>({
    queryKey: [QueryKey.GET_COMPANY, companyId],
    queryFn: () => ApiService.get({ endpoint: BackendRoute.COMPANIES, routePath: [companyId] }),
    enabled: !!companyId,
  });

  const invalidateQuery = useCallback(() => {
    queryClient.invalidateQueries({ queryKey: [QueryKey.GET_COMPANY] });
  }, [queryClient]);

  return {
    invalidateQuery,
    company,
    isLoading,
  };
};

import { useQueryClient } from '@tanstack/react-query';

import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { ApiService } from '../../services';
import { useAppMutation } from '../useAppMutation';

export const useMarkResolved = ({ onSuccess }: { onSuccess?: () => void }) => {
  const { invalidateQueries } = useQueryClient();
  const { mutate: markAsResolved } = useAppMutation([QueryKey.MARK_NOTIFICATION_AS_RESOLVED], {
    mutationFn: ({ id, data }: { id: string; data: { adminEmail: string } }) =>
      ApiService.post(
        {
          endpoint: BackendRoute.NOTIFICATIONS,
          routePath: [id, 'mark-resolved'],
        },
        { body: data },
      ),
    onSuccess: () => {
      onSuccess?.();
      invalidateQueries({ queryKey: [QueryKey.GET_NOTIFICATIONS] });
    },
  });

  return {
    markAsResolved,
  };
};

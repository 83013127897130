import React, { FC } from 'react';
import { twMerge } from 'tailwind-merge';

import { IconProps } from './type';

export type BurgerMenuIconProps = IconProps;

const BurgerMenuIcon: FC<BurgerMenuIconProps> = ({
  iconColor = '#344054',
  className,
  ...props
}) => (
  <svg
    className={twMerge('shrink-0', className)}
    fill="none"
    height="12"
    viewBox="0 0 18 12"
    width="16"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.5 6H16.5M1.5 1H16.5M1.5 11H16.5"
      stroke={iconColor}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.66667"
    />
  </svg>
);

export default BurgerMenuIcon;

import React, { FC } from 'react';
import { twMerge } from 'tailwind-merge';

import { CopyLinkIcon } from '../../../assets/icons';
import Button from '../../../components/Button';
import { SummaryItem } from '../../../components/SummaryItem';
import { useFormat, useModalState } from '../../../hooks';
import { Company, CompanyFreqFilterTitle } from '../../../types/company.types';
import {
  SubscriptionStatus,
  subscriptionStatusTitle,
  subscriptionTypeTitle,
} from '../../../types/plan.types';
import { copyToClipboard } from '../../../utils/copy-to-clipboard';
import { EditSubscriptionModal } from '../EditSubscriptionModal';

export type CompanySubscriptionProps = {
  company?: Company;
  invalidateQuery: () => void;
};

export const CompanySubscription: FC<CompanySubscriptionProps> = ({ company, invalidateQuery }) => {
  const {
    handleCloseModal: handleCloseEditSubscriptionModal,
    handleOpenModal: handleOpenEditSubscriptionModal,
    isOpen: isOpenEditSubscriptionModal,
  } = useModalState({});

  const { format } = useFormat();

  return (
    <>
      <EditSubscriptionModal
        className="mr-6"
        companyId={company?.id || ''}
        handleClose={handleCloseEditSubscriptionModal}
        invalidateQuery={invalidateQuery}
        isOpenModal={isOpenEditSubscriptionModal}
      />
      <div className="flex h-fit flex-col gap-2 rounded-lg bg-gray-50 pt-2">
        <span className="px-4 text-sm font-[550] text-gray-700">Subscription Details</span>
        <div className="flex flex-col divide-y divide-gray-100 rounded-b-lg border border-gray-100 bg-white p-4 pb-3">
          <div className="flex w-full gap-3 py-3 pt-0">
            <span className="w-[120px] flex-shrink-0 text-xs font-[450] text-gray-500">
              Status:
            </span>
            <span
              className={twMerge(
                'max-w-[250px] truncate font-[450]',
                company?.isActive
                  ? 'text-xs text-forest-600'
                  : company?.isEnterpriseRequested
                    ? 'text-xs text-sunbeam-600'
                    : 'text-xs text-fireside-600',
              )}
            >
              {company?.isActive
                ? 'Active'
                : company?.isEnterpriseRequested
                  ? 'Awaiting for enterprise request approval'
                  : 'Inactive'}
            </span>
          </div>
          <SummaryItem
            title="Plan Type"
            value={company?.planType ? subscriptionTypeTitle[company?.planType] : '-'}
          />
          <SummaryItem
            title="Frequency"
            value={company?.planFrequency ? CompanyFreqFilterTitle[company?.planFrequency] : '-'}
          />
          <SummaryItem
            title="Currency"
            value={company?.latestSubscriptionData?.plan?.currency?.toUpperCase() || '-'}
          />
          <SummaryItem
            title="Amount"
            value={`$ ${((company?.latestSubscriptionData?.plan?.amount || 0) / 100)?.toLocaleString('en-US')}`}
          />
          <SummaryItem title="Stakeholder Limit" value={company?.stakeholdersLimit} />
          <div className="flex w-full gap-3 py-3">
            <span className="w-[120px] flex-shrink-0 text-xs font-[450] text-gray-500">
              Payment Status:
            </span>
            <span
              className={twMerge(
                'max-w-[250px] truncate font-[450]',
                company?.subscriptionStatus === SubscriptionStatus.ACTIVE ||
                  company?.subscriptionStatus === SubscriptionStatus.TRIALING
                  ? 'text-xs text-forest-600'
                  : 'text-xs text-fireside-600',
              )}
            >
              {company?.subscriptionStatus
                ? subscriptionStatusTitle[company.subscriptionStatus]
                : '-'}
            </span>
          </div>
          <SummaryItem
            title="Subscription Start"
            value={
              company?.latestSubscriptionData?.plan?.created
                ? format(company?.latestSubscriptionData?.plan?.created * 1000, 'dd/MM/yyyy')
                : '-'
            }
          />
          <SummaryItem
            title="Next Payment"
            value={company?.nextPayment ? format(company?.nextPayment * 1000, 'dd/MM/yyyy') : '-'}
          />
          <div className="flex w-full gap-3 py-3">
            <span className="w-[120px] flex-shrink-0 text-xs font-[450] text-gray-500">Link:</span>
            <div className="flex items-center gap-4">
              {company?.lastInvoiceLink ? (
                <>
                  <span className="max-w-[220px] truncate text-xs font-[450] text-brand-700 underline">
                    {company?.lastInvoiceLink}
                  </span>

                  <CopyLinkIcon
                    className="cursor-pointer p-[1px]"
                    iconColor="#2565C8"
                    onClick={() => copyToClipboard(company?.lastInvoiceLink)}
                  />
                </>
              ) : (
                <span className="max-w-[220px] truncate text-xs font-[450] text-gray-700">-</span>
              )}
            </div>
          </div>
        </div>
      </div>
      <Button
        className="border-[1px] border-gray-300 bg-white py-2 font-[450] text-gray-700"
        onClick={() => handleOpenEditSubscriptionModal(company?.id || '')}
        type="button"
      >
        Edit Subscription
      </Button>
    </>
  );
};

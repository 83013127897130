export enum StakeholderFilter {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export type FilterVariant = 'all' | StakeholderFilter;

export const stakeholderFilterTitle: Record<FilterVariant, string> = {
  all: 'All',
  [StakeholderFilter.ACTIVE]: 'Active',
  [StakeholderFilter.INACTIVE]: 'Inactive',
};

export const stakeholderFilterTitleMap = Object.entries(stakeholderFilterTitle);

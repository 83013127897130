import React, { FC } from 'react';
import { twMerge } from 'tailwind-merge';

import { IconProps } from './type';

export type MessageIconProps = IconProps;

const MessageIcon: FC<MessageIconProps> = ({ iconColor = '#344054', className, ...props }) => (
  <svg
    className={twMerge('shrink-0', className)}
    fill="none"
    height="21"
    viewBox="0 0 21 21"
    width="21"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.2558 3.00109C6.65349 3.33421 3.833 6.36474 3.833 10.054C3.833 10.8457 3.96287 11.607 4.20248 12.3179C4.29264 12.5854 4.33772 12.7191 4.34586 12.8219C4.35389 12.9234 4.34782 12.9945 4.32271 13.0932C4.29729 13.1931 4.24116 13.2969 4.12891 13.5047L2.76586 16.0277C2.57144 16.3875 2.47422 16.5675 2.49598 16.7063C2.51493 16.8273 2.58612 16.9338 2.69065 16.9976C2.81065 17.0708 3.01408 17.0497 3.42095 17.0077L7.68847 16.5665C7.81765 16.5532 7.88233 16.5465 7.94122 16.5488C7.99915 16.551 8.04004 16.5564 8.09653 16.5694C8.15396 16.5827 8.22618 16.6105 8.37063 16.6661C9.16067 16.9705 10.019 17.1374 10.9163 17.1374C14.6087 17.1374 17.6412 14.3122 17.9701 10.7057M17.2674 3.70294C18.2437 4.67925 18.2437 6.26216 17.2674 7.23847C16.2911 8.21478 14.7082 8.21478 13.7319 7.23847C12.7556 6.26216 12.7556 4.67925 13.7319 3.70294C14.7082 2.72663 16.2911 2.72663 17.2674 3.70294Z"
      stroke={iconColor}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
  </svg>
);

export default MessageIcon;

import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { ApiService } from '../../services';
import { StakeholderBulkAction, StakeholderBulkActionDto } from '../../types/stakeholder.types';
import { useAppMutation } from '../useAppMutation';
import { useInvalidateQueries } from '../useInvalidateQueries';

export const useBulkActions = () => {
  const { invalidateQuery } = useInvalidateQueries(QueryKey.GET_STAKEHOLDERS);
  const { mutate: bulkActions, isPending } = useAppMutation([QueryKey.BULK_STAKEHOLDER_ACTIONS], {
    mutationFn: ({
      action,
      ...body
    }: {
      action: StakeholderBulkAction;
    } & StakeholderBulkActionDto) =>
      ApiService.post<StakeholderBulkActionDto>(
        {
          endpoint: BackendRoute.STAKEHOLDERS,
          routePath: ['bulk', action],
        },
        { body },
      ),
    onSuccess: () => {
      invalidateQuery();
    },
  });
  return { bulkActions, isPending };
};

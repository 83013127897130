import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { ApiService } from '../../services';
import { SubscriptionDuration, SubscriptionType } from '../../types/plan.types';
import { useAppMutation } from '../useAppMutation';

export type ChangeSubscriptionDto = {
  type: SubscriptionType;
  frequency: SubscriptionDuration;
  amount: number;
  stakeholderLimit: number;
};

export const useChangeSubscription = () => {
  const { mutate: changeSubscription, isPending } = useAppMutation([QueryKey.CHANGE_SUBSCRIPTION], {
    mutationFn: ({ companyId, data }: { companyId: string; data: ChangeSubscriptionDto }) =>
      ApiService.patch(
        {
          endpoint: BackendRoute.COMPANIES,
          routePath: [companyId, 'subscription'],
        },
        { body: data },
      ),
  });
  return { changeSubscription, isPending };
};

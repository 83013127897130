export enum SubscriptionDuration {
  MONTHLY = 'monthly',
  ANNUALY = 'annualy',
}

export const subscriptionDurationTitle: Record<SubscriptionDuration, string> = {
  [SubscriptionDuration.MONTHLY]: 'Monthly',
  [SubscriptionDuration.ANNUALY]: 'Annually',
};

export enum SubscriptionType {
  START_UP = 'startup',
  GROWTH = 'growth',
  ENTERPRISE = 'enterprise',
}

export const subscriptionTypeTitle: Record<SubscriptionType, string> = {
  [SubscriptionType.START_UP]: 'Start up',
  [SubscriptionType.GROWTH]: 'Growth',
  [SubscriptionType.ENTERPRISE]: 'Enterprise',
};

export enum SubscriptionStatus {
  INCOMPLETE = 'incomplete',
  INCOMPLETE_EXPIRED = 'incomplete_expired',
  TRIALING = 'trialing',
  ACTIVE = 'active',
  PAST_DUE = 'past_due',
  CANCELED = 'canceled',
  UNPAID = 'unpaid',
}

export const subscriptionStatusTitle: Record<SubscriptionStatus, string> = {
  [SubscriptionStatus.INCOMPLETE]: 'Incomplete',
  [SubscriptionStatus.INCOMPLETE_EXPIRED]: 'Incomplete expired',
  [SubscriptionStatus.TRIALING]: 'Trialing',
  [SubscriptionStatus.ACTIVE]: 'Active',
  [SubscriptionStatus.PAST_DUE]: 'Past due',
  [SubscriptionStatus.CANCELED]: 'Canceled',
  [SubscriptionStatus.UNPAID]: 'Unpaid',
};

export type Plan = {
  id: string;
  active: boolean;
  amount: number;
  created: number;
  currency: string;
};

import { FC } from 'react';

import { ChevronDownIcon } from '../../../assets/icons';
import { StakeholderOrder } from '../../../types/stakeholder.types';

export type SortProps = { isSelected: boolean; sortOrder: StakeholderOrder | null };

export const Sort: FC<SortProps> = ({ isSelected, sortOrder }) => (
  <div className="relative flex size-4 cursor-pointer justify-center rounded hover:bg-gray-200">
    {isSelected ? (
      sortOrder == null ? (
        <>
          <ChevronDownIcon className="absolute -top-[3px] w-2 rotate-180" />
          <ChevronDownIcon className="absolute -bottom-[3px] w-2" />
        </>
      ) : (
        <>
          {sortOrder === StakeholderOrder.ASC ? (
            <ChevronDownIcon className="w-2" />
          ) : (
            <ChevronDownIcon className="w-2 rotate-180" />
          )}
        </>
      )
    ) : (
      <>
        <ChevronDownIcon className="absolute -top-[3px] w-2 rotate-180" />
        <ChevronDownIcon className="absolute -bottom-[3px] w-2" />
      </>
    )}
  </div>
);

import { Field, Label } from '@headlessui/react';
import React, { FC } from 'react';

import { MinusIcon } from '../../../assets/icons';
import { Checkbox } from '../../../components/Checkbox';
import { Company, CompanyOrder, CompanySortBy } from '../../../types/company.types';
import { CompanyTableItem } from './CompanyTableItem';
import { TableSortingCell } from './TableSortingCell';

export type CompanyTableProps = {
  companies: Company[];
  isSelectStarted: boolean;
  isSelected: (id: string) => boolean;
  onSelectAll: (id: string[]) => void;
  onRemoveSelectAll: () => void;
  onSortClick: (field: CompanySortBy) => void;
  onSelect: (id: string) => void;
  onRemoveSelect: (id: string) => void;
  handleView: (id: string) => void;
  handleEdit: (id: string) => void;
  handleDeactivate: (id: string) => void;
  sortMode: CompanyOrder | null;
  sortField: CompanySortBy | null;
};

export const CompanyTable: FC<CompanyTableProps> = ({
  companies,
  isSelectStarted,
  isSelected,
  onSelectAll,
  onRemoveSelectAll,
  onSortClick,
  onSelect,
  onRemoveSelect,
  handleView,
  handleEdit,
  handleDeactivate,
  sortMode,
  sortField,
}) => {
  return (
    <div className="w-full shrink-0 overflow-x-auto *:text-nowrap">
      <table className="h-fit w-full divide-y-[1px] divide-[#F2F2F2] border-b-[1px] border-[#F2F2F2]">
        <colgroup>
          <col className="w-12 shrink-0" span={1} />
        </colgroup>
        <thead>
          <tr className="h-11 bg-gray-50">
            <td className="relative">
              <div className="flex h-full w-full items-center justify-center px-4">
                <Field className="flex cursor-pointer items-center">
                  <Checkbox
                    checked={isSelectStarted}
                    id="select-all"
                    onChange={(checked) =>
                      checked
                        ? onSelectAll(companies?.map(({ id }) => id) || [])
                        : onRemoveSelectAll()
                    }
                    selectIcon={<MinusIcon />}
                  />
                  <Label
                    className="absolute left-0 top-0 h-full w-full cursor-pointer"
                    htmlFor="select-all"
                  />
                </Field>
              </div>
            </td>

            <td className="pl-4">
              <TableSortingCell
                onSortClick={onSortClick}
                sortByCell={CompanySortBy.NAME}
                sortField={sortField}
                sortMode={sortMode}
                title="Name"
              />
            </td>

            <td className="pl-4 text-label-md font-[450] text-[#172335]">POC (Admin)</td>

            <td className="pl-4">
              <TableSortingCell
                onSortClick={onSortClick}
                sortByCell={CompanySortBy.STAKEHOLDERS_COUNT}
                sortField={sortField}
                sortMode={sortMode}
                title="Stake."
              />
            </td>

            <td className="pl-4">
              <TableSortingCell
                onSortClick={onSortClick}
                sortByCell={CompanySortBy.PLAN_TYPE}
                sortField={sortField}
                sortMode={sortMode}
                title="Plan"
              />
            </td>

            <td className="pl-4">
              <TableSortingCell
                onSortClick={onSortClick}
                sortByCell={CompanySortBy.PLAN_FREQUENCY}
                sortField={sortField}
                sortMode={sortMode}
                title="Frequency"
              />
            </td>

            <td className="pl-4">
              <TableSortingCell
                onSortClick={onSortClick}
                sortByCell={CompanySortBy.STATUS}
                sortField={sortField}
                sortMode={sortMode}
                title="Status"
              />
            </td>

            <td className="pl-4">
              <TableSortingCell
                onSortClick={onSortClick}
                sortByCell={CompanySortBy.NEXT_PAYMENT}
                sortField={sortField}
                sortMode={sortMode}
                title="Payment"
              />
            </td>

            <td className="pl-4">
              <TableSortingCell
                onSortClick={onSortClick}
                sortByCell={CompanySortBy.NEXT_PAYMENT}
                sortField={sortField}
                sortMode={sortMode}
                title="Next Payment"
              />
            </td>
            <td></td>
          </tr>
        </thead>
        <tbody className="divide-y-[1px] divide-[#F2F2F2] bg-gray-600">
          {companies?.map((company) => (
            <CompanyTableItem
              company={company}
              handleDeactivate={handleDeactivate}
              handleEdit={handleEdit}
              handleView={handleView}
              isSelected={isSelected}
              key={company.id}
              onRemoveSelect={onRemoveSelect}
              onSelect={onSelect}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { ApiService } from '../../services';
import { useAppMutation } from '../useAppMutation';
import { useNotifications } from '../../context/notifications.context';
import { NotificationType } from '../../types/notification.types';

export const useMarkRead = ({ onSuccess }: { onSuccess?: () => void }) => {
  const { handleReadNotification } = useNotifications();
  const { mutate: markAsRead } = useAppMutation([QueryKey.MARK_NOTIFICATION_AS_READ], {
    mutationFn: ({
      id,
      data,
      type,
    }: {
      id: string;
      data: { adminEmail: string };
      type: NotificationType;
    }) =>
      ApiService.post(
        {
          endpoint: BackendRoute.NOTIFICATIONS,
          routePath: [id, 'mark-read'],
        },
        { body: data },
      ),
    onSuccess: (_, { type }) => {
      onSuccess?.();
      handleReadNotification(type);
    },
  });

  return {
    markAsRead,
  };
};

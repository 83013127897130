import React, { FC } from 'react';

import Button from '../../../components/Button';
import { useModalState } from '../../../hooks';
import { requestNotificationBuilder } from '../../../types/notification.types';
import { EditSubscriptionModal } from '../EditSubscriptionModal';
import { CompanySubscriptionProps } from './CompanySubscription';

export const CompanyRequests: FC<CompanySubscriptionProps> = ({ company, invalidateQuery }) => {
  const {
    handleCloseModal: handleCloseEditSubscriptionModal,
    handleOpenModal: handleOpenEditSubscriptionModal,
    isOpen: isOpenEditSubscriptionModal,
  } = useModalState({});

  return (
    <>
      <EditSubscriptionModal
        className="mr-6"
        companyId={company?.id || ''}
        handleClose={handleCloseEditSubscriptionModal}
        invalidateQuery={invalidateQuery}
        isOpenModal={isOpenEditSubscriptionModal}
      />
      {company?.isEnterpriseRequested && (
        <div className="flex flex-col gap-4 rounded-lg border border-gray-200 bg-white px-4 py-3">
          <div className="flex w-full justify-between text-label-md font-medium text-gray-500">
            <span>New enterprise request</span>
          </div>
          <div className="flex w-full gap-3">
            <div className="size-10 shrink-0 rounded-full bg-brand-700" />
            <div className="flex w-full flex-col gap-4">
              <span className="text-sm font-[450] text-black">
                {requestNotificationBuilder({
                  companyName: company?.name || '',
                })}
              </span>
              <div className="flex h-9 justify-end gap-2 text-sm font-[450] text-gray-700">
                <Button
                  className="w-fit px-4 font-[550] text-white"
                  onClick={() => handleOpenEditSubscriptionModal(company?.id || '')}
                >
                  Activate
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

import { Transition } from '@headlessui/react';
import React, { FC, Fragment, useCallback, useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { ChevronDownIcon, CloseIcon, SearchIcon } from '../../assets/icons';
import Button from '../../components/Button';
import { Input } from '../../components/Input';
import { Radio, RadioGroup } from '../../components/RadioGroup';
import { Select, SelectButton, SelectOption, SelectOptions } from '../../components/Select';
import { Sheet, SheetContent, SheetTrigger } from '../../components/Sheet';
import { QueryKey } from '../../constants';
import {
  useDebounce,
  useEventOutsideElement,
  useInvalidateQueries,
  useScreenSize,
} from '../../hooks';
import { StakeholderRole } from '../../types/stakeholder.types';
import { CompanyFilter } from './CompanyFilter/CompanyFilter';
import { EditModal, ViewModal } from './Modals';
import { StakeholdersTable } from './Table';
import { FilterVariant, stakeholderFilterTitle, stakeholderFilterTitleMap } from './types';

export type SelectedStakeholder = {
  isActive: boolean;
  role: StakeholderRole;
  isAccessRevoked: boolean;
  id: string;
};

const Stakeholders: FC = () => {
  const { invalidateQuery } = useInvalidateQueries(QueryKey.GET_STAKEHOLDERS);

  const [currentPage, setCurrentPage] = useState(1);
  const [selectedFilter, setSelectedFilter] = useState<FilterVariant | 'deleted'>('all');
  const [exceptCompanyFilterIds, setExceptCompanyFilterId] = useState<string[]>([]);
  const [companyFilterIds, setCompanyFilterId] = useState<string[]>([]);

  const [isFocusedInput, setInputFocus] = useState(false);
  const inputRef = useRef<HTMLDivElement>(null);
  const [search, setSearch] = useState<null | string>(null);
  const [isOpenSearch, setOpenSearch] = useState(false);

  useEventOutsideElement(inputRef, 'click', () => !search && setOpenSearch(false));

  const { debounceCallback } = useDebounce({
    callback: () => {},
  });

  const handleSearch = useCallback(
    (value: string) => {
      setCurrentPage(1);
      setSearch(value);
      debounceCallback(value);
    },
    [debounceCallback],
  );

  const handleSelectFilter = (filter: FilterVariant | 'deleted') => {
    setSelectedFilter(filter);
    setCurrentPage(1);
  };
  const { isMobile } = useScreenSize();
  const showMobileSearchBar = isMobile;

  return (
    <>
      <ViewModal />

      <EditModal />

      <div className="flex h-fit min-h-full w-full flex-col divide-y divide-gray-200 rounded-md bg-white shadow-sm">
        <div className="flex w-full justify-between p-3 lg:p-4">
          <div className="flex h-9 lg:gap-4">
            <RadioGroup
              className="h-full divide-x divide-gray-200 overflow-hidden rounded border border-gray-200 max-lg:hidden"
              onChange={handleSelectFilter}
              value={selectedFilter}
            >
              {stakeholderFilterTitleMap.map(([filterKey, filter]) => (
                <Radio
                  className="flex h-full items-center justify-center px-4 text-sm font-[450] text-[#858593] data-[checked]:bg-brand-25 data-[checked]:font-[550] data-[checked]:text-[#172335]"
                  key={filterKey}
                  styleType="NONE"
                  value={filterKey}
                >
                  {filter}
                </Radio>
              ))}
            </RadioGroup>
            <Button
              className={twMerge(
                'flex h-full items-center justify-center rounded border border-gray-200 px-4 text-sm font-[450] text-[#858593] max-lg:hidden',
                selectedFilter === 'deleted' && 'bg-brand-25 font-[550] text-[#172335]',
              )}
              onClick={() => setSelectedFilter('deleted')}
              styleType="DEFAULT_ROUNDED"
            >
              Deleted
            </Button>
            <Select
              onChange={(value: FilterVariant | 'deleted') => {
                setCurrentPage(1);
                handleSelectFilter(value);
              }}
              value={selectedFilter}
            >
              {({ open }) => (
                <>
                  <div className="relative rounded border-gray-100 lg:hidden">
                    <SelectButton className="flex h-9 justify-between border-gray-100 bg-gray-50">
                      <span className="text-sm font-[450] text-[#344054]">
                        {stakeholderFilterTitle[selectedFilter as FilterVariant]}
                      </span>
                      <ChevronDownIcon className="h-4 w-4 text-gray-400" />
                    </SelectButton>

                    <Transition
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                      show={open}
                    >
                      <SelectOptions>
                        {stakeholderFilterTitleMap.map(([filterKey, filter]) => (
                          <SelectOption key={filterKey} value={filterKey}>
                            {({ selected }) => (
                              <span
                                className={twMerge(
                                  selected ? 'font-[450]' : 'font-normal',
                                  'block text-sm text-gray-700',
                                )}
                              >
                                {filter}
                              </span>
                            )}
                          </SelectOption>
                        ))}
                      </SelectOptions>
                    </Transition>
                  </div>
                </>
              )}
            </Select>
          </div>
          <div className="flex h-9 gap-4">
            <div className="relative flex h-full w-fit items-center gap-3">
              <Sheet modal={false} open={showMobileSearchBar && isOpenSearch}>
                <div className="flex items-center justify-end" ref={inputRef}>
                  <Input
                    onBlur={() => setInputFocus(false)}
                    onChange={handleSearch}
                    onFocus={() => {
                      setInputFocus(true);
                    }}
                    placeholder={search ? '' : 'Search'}
                    value={search || ''}
                    wrapperClassName={twMerge(
                      'transition-all duration-500 w-[200px] overflow-hidden max-h-9',
                      isOpenSearch ? 'delay-500' : 'w-0',
                      showMobileSearchBar && 'w-0',
                    )}
                  />
                  {!showMobileSearchBar && isOpenSearch && typeof search === 'string' && (
                    <div
                      className="absolute mr-8 cursor-pointer p-2"
                      onClick={() => {
                        setSearch('');
                        invalidateQuery();
                      }}
                    >
                      <CloseIcon />
                    </div>
                  )}
                  <SheetTrigger
                    className={twMerge(
                      'flex size-9 items-center justify-center rounded border-[1px] border-gray-100 transition-all duration-300',
                      isOpenSearch
                        ? 'h-9 rounded-l-none rounded-br-none border-x-0 border-t-0 border-b-black bg-gray-50'
                        : 'delay-1000',
                      isFocusedInput && 'border-b-[2px] border-b-brand-700 transition-none',
                    )}
                    onClick={() => {
                      setOpenSearch((prev) => !prev);
                      setSearch(null);
                    }}
                  >
                    <SearchIcon iconColor={isFocusedInput ? '#2565C8' : '#344054'} />
                  </SheetTrigger>
                </div>
                <SheetContent
                  className="w-full"
                  onClick={(e) => e.stopPropagation()}
                  onInteractOutside={() => {
                    if (!search) {
                      setOpenSearch(false);
                      setSearch(null);
                      invalidateQuery();
                    }
                  }}
                  side="TOP"
                >
                  <div className={twMerge('flex')}>
                    <Input
                      icon={search && <CloseIcon />}
                      onBlur={() => setInputFocus(false)}
                      onChange={handleSearch}
                      onFocus={(e) => {
                        setInputFocus(true);
                        e.stopPropagation();
                      }}
                      onIconClick={() => {
                        setTimeout(() => {
                          setSearch('');
                          invalidateQuery();
                        }, 0);
                      }}
                      placeholder="Search"
                      value={search || ''}
                      wrapperClassName={twMerge('transition-all duration-1000 w-full')}
                    />
                  </div>
                </SheetContent>
              </Sheet>
            </div>

            <CompanyFilter
              onSubmit={(selectedIds, exceptSelectedIds) => {
                setCurrentPage(1);
                setCompanyFilterId(selectedIds);
                setExceptCompanyFilterId(exceptSelectedIds);
              }}
            />
          </div>
        </div>
        <div className="flex w-full shrink-0 flex-col overflow-x-auto">
          <StakeholdersTable
            companyFilterIds={companyFilterIds}
            currentPage={currentPage}
            deletedOnly={selectedFilter === 'deleted'}
            exceptCompanyIds={exceptCompanyFilterIds}
            search={search || ''}
            selectedFilter={selectedFilter !== 'deleted' ? selectedFilter : undefined}
            setCurrentPage={setCurrentPage}
          />
        </div>
      </div>
    </>
  );
};
export default Stakeholders;

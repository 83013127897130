import { format, FormatOptions } from 'date-fns';

export const useFormat = () => {
  return {
    format: <DateType extends Date>(
      date: DateType | number | string | undefined,
      formatStr: string,
      options?: Except<FormatOptions, 'locale'>,
    ) => {
      if (!date) return '';
      return format(date, formatStr, {
        ...options,
      });
    },
  };
};

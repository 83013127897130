import React, { FC, ReactNode, useState } from 'react';
import { toast } from 'react-toastify';
import { twMerge } from 'tailwind-merge';

import Button from '../../../components/Button';
import { CloseModalButton } from '../../../components/CloseModalButton';
import { Sheet, SheetContent } from '../../../components/Sheet';
import { CompanyHooks } from '../../../hooks';
import { useCompany } from '../../../hooks/company/useCompany';
import { Company } from '../../../types/company.types';
import { CompanyAnalytics } from './CompanyAnalytics';
import { CompanyDetails } from './CompanyDetails';
import { CompanyRequests } from './CompanyRequests';
import { CompanySubscription } from './CompanySubscription';
import { arrayOfViewCompanyTab, ViewCompanyTabType } from './types';

export type CompanyComponentProps = {
  company?: Company;
  invalidateQuery?: () => void;
};

const companyTabComponentVariant: Record<
  ViewCompanyTabType,
  (props: CompanyComponentProps) => ReactNode
> = {
  [ViewCompanyTabType.DETAILS]: (props) => <CompanyDetails company={props.company} />,
  [ViewCompanyTabType.ANALYTICS]: (props) => <CompanyAnalytics company={props.company} />,
  [ViewCompanyTabType.SUBSCRIPTIONS]: (props) => (
    <CompanySubscription
      company={props.company}
      invalidateQuery={props.invalidateQuery ?? (() => {})}
    />
  ),
  [ViewCompanyTabType.REQUESTS]: (props) => (
    <CompanyRequests
      company={props.company}
      invalidateQuery={props.invalidateQuery ?? (() => {})}
    />
  ),
};

export type ViewCompanyModalProps = {
  isOpenModal: boolean;
  handleClose: () => void;
  invalidateQuery: () => void;
  companyId: string;
};

export const ViewCompanyModal: FC<ViewCompanyModalProps> = ({
  isOpenModal,
  handleClose,
  invalidateQuery,
  companyId,
}) => {
  const [selectedTab, setTab] = useState<ViewCompanyTabType>(ViewCompanyTabType.DETAILS);
  const { company } = useCompany(companyId);
  const { deactivateCompany } = CompanyHooks.useDeactivate();

  const handleDeactivate = () => {
    deactivateCompany(
      { companyId },
      {
        onSuccess: () => {
          invalidateQuery();
          toast.success('Company has been deactivated');
        },
      },
    );
    invalidateQuery();
    handleClose();
  };

  return (
    <Sheet open={isOpenModal}>
      <SheetContent
        className="w-full max-w-[485px] border-transparent bg-transparent p-2 shadow-none"
        onInteractOutside={handleClose}
        side="RIGHT"
      >
        <div className="flex h-full w-full flex-col overflow-hidden rounded-lg border-[1px] border-gray-300 bg-gray-100 pb-4">
          <div className="flex h-fit w-full items-center justify-between bg-white px-6 py-3">
            <span className="truncate text-xl font-semibold text-gray-700">{company?.name}</span>
            <CloseModalButton onClose={handleClose} />
          </div>
          <div className="flex h-full flex-col gap-6 overflow-y-auto rounded-b-xl border-b border-gray-300 bg-white p-6 pt-0">
            <div className="flex h-[54px] min-h-[54px] w-full gap-4 border-b-[1px] border-gray-200">
              {arrayOfViewCompanyTab.map(([tabKey, tab]) => (
                <Button
                  className={twMerge(
                    'flex h-full w-fit gap-1 rounded-none border-b-2 border-transparent text-sm font-[450] text-gray-400',
                    selectedTab === tabKey && 'border-brand-700 font-[550] text-brand-700',
                  )}
                  key={tab}
                  onClick={() => {
                    setTab(tabKey);
                  }}
                  styleType="NONE"
                >
                  {tab}
                  {tabKey === ViewCompanyTabType.REQUESTS && company?.isEnterpriseRequested && (
                    <span
                      className="flex size-4 shrink-0 items-center justify-center rounded-full bg-fireside-500 text-white"
                      style={{
                        fontSize: '9px',
                        fontWeight: '600',
                        lineHeight: '11.2px',
                        textAlign: 'center',
                      }}
                    >
                      1
                    </span>
                  )}
                </Button>
              ))}
            </div>
            <div className="flex flex-col gap-6">
              {companyTabComponentVariant[selectedTab]({ company, invalidateQuery })}
            </div>
          </div>

          <div className="mt-4 flex h-9 w-full justify-end gap-3 px-6">
            {!company?.toCancelAt && company?.subscriptionStatus && (
              <Button
                className="h-full w-fit rounded border border-gray-300 bg-white px-3 py-[6px] text-sm font-[450] text-gray-700 shadow-xs"
                onClick={handleDeactivate}
                styleType="NONE"
                type="button"
              >
                Deactivate
              </Button>
            )}
            <Button
              className="h-full w-fit px-4 py-[6px] text-sm font-[550] text-white"
              onClick={handleClose}
              type="button"
            >
              Close
            </Button>
          </div>
        </div>
      </SheetContent>
    </Sheet>
  );
};

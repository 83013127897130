import { z } from 'zod';

import { SubscriptionDuration, SubscriptionType } from '../../../types/plan.types';

export const formSchema = z.object({
  planType: z.nativeEnum(SubscriptionType),
  amount: z.coerce.number().min(1, 'Required'),
  stakeholderLimit: z.coerce.number().min(1, 'Required'),
  currency: z.string(),
  frequency: z.nativeEnum(SubscriptionDuration),
});

export type FormSchema = z.infer<typeof formSchema>;

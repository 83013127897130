import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { ApiService } from '../../services';
import { Stakeholder } from '../../types/stakeholder.types';
import { useAppQuery } from '../useAppQuery';

export const useAdmin = (companyId: string) => {
  const queryClient = useQueryClient();
  const { data: adminStakeholder, isLoading } = useAppQuery({
    queryKey: [QueryKey.GET_COMPANY_ADMIN_STAKEHOLDER],
    queryFn: () =>
      ApiService.get<Stakeholder>({
        endpoint: BackendRoute.COMPANIES,
        routePath: [companyId, 'admin'],
      }),
    enabled: !!companyId,
  });

  const invalidateQuery = useCallback(() => {
    queryClient.invalidateQueries({ queryKey: [QueryKey.GET_COMPANY_ADMIN_STAKEHOLDER] });
  }, [queryClient]);

  return {
    invalidateQuery,
    adminStakeholder,
    isLoading,
  };
};

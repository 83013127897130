import { Plan, SubscriptionStatus, SubscriptionType } from './plan.types';

export enum CompanyTabType {
  ALL = 'all',
  ENTERPRISE_REQUEST = 'enterprise-request',
}

export const CompanyTabTitle: Record<CompanyTabType, string> = {
  [CompanyTabType.ALL]: 'All',
  [CompanyTabType.ENTERPRISE_REQUEST]: 'Enterprise Request',
};

export const arrayOfCompanyTab = Object.entries(CompanyTabTitle);

export type CompanyAllPlanFilter = 'all' | SubscriptionType;

export const CompanyPlanFilterTitle: Record<CompanyAllPlanFilter, string> = {
  all: 'All',
  [SubscriptionType.GROWTH]: 'Growth',
  [SubscriptionType.START_UP]: 'Startup',
  [SubscriptionType.ENTERPRISE]: 'Enterprise',
};

export const arrayOfCompanyPlanFilter = Object.entries(CompanyPlanFilterTitle);

export enum CompanyFreqFilter {
  MONTH = 'month',
  YEAR = 'year',
}

export type CompanyAllFreqFilter = 'all' | CompanyFreqFilter;

export const CompanyFreqFilterTitle: Record<CompanyAllFreqFilter, string> = {
  all: 'All',
  [CompanyFreqFilter.MONTH]: 'Monthly',
  [CompanyFreqFilter.YEAR]: 'Annually',
};

export const arrayOfCompanyFreqFilter = Object.entries(CompanyFreqFilterTitle);

export type CompanyAllPaymentFilter = 'all' | SubscriptionStatus;

export const CompanyPaymentFilterTitle: Record<CompanyAllPaymentFilter, string> = {
  all: 'All',
  [SubscriptionStatus.INCOMPLETE]: 'Incomplete',
  [SubscriptionStatus.INCOMPLETE_EXPIRED]: 'Incomplete expired',
  [SubscriptionStatus.TRIALING]: 'Trialing',
  [SubscriptionStatus.ACTIVE]: 'Active',
  [SubscriptionStatus.PAST_DUE]: 'Past due',
  [SubscriptionStatus.CANCELED]: 'Canceled',
  [SubscriptionStatus.UNPAID]: 'Unpaid',
};

export const arrayOfCompanyPaymentFilter = Object.entries(CompanyPaymentFilterTitle);

export enum CompanyOrder {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum CompanySortBy {
  NAME = 'name',
  STAKEHOLDERS_COUNT = 'stakeholdersCount',
  PLAN_TYPE = 'planType',
  PLAN_FREQUENCY = 'planFrequency',
  STATUS = 'isActive',
  SUBSCRIPTION_STATUS = 'subscriptionStatus',
  NEXT_PAYMENT = 'nextPayment',
}

export enum CompanyIndustry {
  AGRICULTURE_FARMING = 'agriculture-farming',
  AEROSPACE = 'aerospace',
  ART = 'art',
  ARTIFICIAL_INTELLIGENCE = 'artificial-intelligence',
  AUTOMOTIVE = 'automotive',
  BANKING_FINANCIAL_SERVICES = 'banking-financial-services',
  BIOTECHNOLOGY = 'biotechnology',
  BUILDING_MATERIALS_SUPPLIES = 'building-materials-supplies',
  BUSINESS_SERVICES = 'business-services',
  CHEMICAL_INDUSTRY = 'chemical-industry',
  CLEANING_PRODUCTS_SERVICES = 'cleaning-products-services',
  CLOUD_COMPUTING = 'cloud-computing',
  COMPUTER_HARDWARE = 'computer-hardware',
  CONSTRUCTION = 'construction',
  CONSUMER_ELECTRONICS = 'consumer-electronics',
  CONSUMER_GOODS = 'consumer-goods',
  CONSUMER_HEALTHCARE = 'consumer-healthcare',
  CONSULTING = 'consulting',
  CULTURE = 'culture',
  CYBERSECURITY = 'cybersecurity',
  DATA_STORAGE_MANAGEMENT = 'data-storage-management',
  DEFENSE = 'defense',
  E_COMMERCE = 'e-commerce',
  EDUCATION_TRAINING = 'education-training',
  ELECTRIC_VEHICLES = 'electric-vehicles',
  ENERGY = 'energy',
  ENTERTAINMENT = 'entertainment',
  ENVIRONMENTAL_SERVICES = 'environmental-services',
  EVENTS = 'events',
  FABRICS_TEXTILES = 'fabrics-textiles',
  FASHION = 'fashion',
  FINTECH = 'fintech',
  FOOD_BEVERAGES = 'food-beverages',
  GOVERNMENT_PUBLIC_INSTITUTIONS = 'government-public-institutions',
  HEALTHCARE = 'healthcare',
  HOSPITALITY = 'hospitality',
  INFORMATION_TECHNOLOGIES = 'information-technologies',
  INTERNET_OF_THINGS = 'internet-of-things',
  INSURANCE = 'insurance',
  LEGAL = 'legal',
  LEISURE_RECREATION = 'leisure-recreation',
  LOGISTICS_SUPPLY_CHAIN = 'logistics-supply-chain',
  LUXURY_GOODS = 'luxury-goods',
  MACHINERY_HEAVY_EQUIPMENT = 'machinery-heavy-equipment',
  MANUFACTURING = 'manufacturing',
  MARKETING_ADVERTISING = 'marketing-advertising',
  MEDIA = 'media',
  MEDICAL_DEVICES_SUPPLIES = 'medical-devices-supplies',
  MINING = 'mining',
  MUSIC = 'music',
  MOBILITY_TRANSPORTATION = 'mobility-transportation',
  PERSONAL_SERVICES = 'personal-services',
  PET_CARE_SUPPLIES = 'pet-care-supplies',
  PHARMACEUTICALS = 'pharmaceuticals',
  PROFESSIONAL_SERVICES = 'professional-services',
  PUBLISHING = 'publishing',
  REAL_ESTATE_PROPTECH = 'real-estate-proptech',
  RESTAURANTS_FOOD_SERVICES = 'restaurants-food-services',
  RETAIL = 'retail',
  ROBOTICS = 'robotics',
  SMART_CITY = 'smart-city',
  SOCIAL_MEDIA = 'social-media',
  SOFTWARE_DEVELOPMENT = 'software-development',
  SPORTS_FITNESS = 'sports-fitness',
  TELECOM_CONNECTIVITY = 'telecom-connectivity',
  TRAVEL_TOURISM = 'travel-tourism',
  VIDEO_GAMES = 'video-games',
  WASTE_MANAGEMENT = 'waste-management',
  NON_PROFIT = 'non-profit',
  OTHER = 'other',
}
export enum CompanyType {
  PRIVATE_EQUITY = 'private-equity',
  VENTURE_CAPITAL = 'venture-capital',
  SPV = 'spv',
  FAMILY_OFFICE = 'family-office',
  COMPANY = 'company',
  OTHER = 'other',
}
export enum CompanyAnnualRevenue {
  NO_REV = 'no-rev',
  LESS_1_MIL = 'less-1-mil',
  BETWEEN_5_20_MIL = 'between-5_20-mil',
  BETWEEN_1_5_MIL = 'between-1-5-mil',
  MORE_20_MIL = 'more-20-mil',
}
export enum CompanyNumberOfEmployees {
  BETWEEN_1_10 = '1-10',
  BETWEEN_11_25 = '11-25',
  BETWEEN_26_50 = '26-50',
  BETWEEN_51_250 = '51-250',
  MORE_250 = '>250',
}

export type Company = {
  id: string;
  name: string;
  url?: string;
  industry?: CompanyIndustry;
  type?: CompanyType;
  annualRevenue?: CompanyAnnualRevenue;
  numberOfEmployees?: CompanyNumberOfEmployees;
  countryName?: string;
  currencyName?: string;
  stateName?: string;
  city?: string;
  zipCode?: string;
  address?: string;
  companyId?: string;
  logoImgSrc?: string;
  isActive: boolean;
  isCompleted: boolean;
  isFirstSubscribed: boolean;
  toCancelAt?: string;
  stripeSubscriptionId?: string;
  planId?: string;
  latestSubscriptionData: { plan?: Plan };
  investorsCount?: number;
  stakeholdersCount?: number;
  totalInvestment?: number;
  nav?: number;
  stakeholdersLimit?: number;
  planType?: SubscriptionType;
  planFrequency?: CompanyFreqFilter;
  nextPayment?: number;
  createdAt?: string;
  lastInvoiceLink?: string;
  subscriptionStatus?: SubscriptionStatus;
  adminEmail?: string;
  adminName?: string;
  isEnterpriseRequested?: boolean;
};

export enum NumberOfFetchedItems {
  FIVE = '5',
  TEN = '10',
  TWENTY = '20',
  FIFTY = '50',
}

export type SelectNumberOfFetchedItems = NumberOfFetchedItems;

export const selectVariant = Object.values(NumberOfFetchedItems);

export enum FilterType {
  BY_TYPE = 'by-type',
  BY_FREQ = 'by-freq',
  BY_PAYMENT = 'by-payment',
}

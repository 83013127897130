import { SubscriptionDuration, SubscriptionType, subscriptionTypeTitle } from './plan.types';

export enum NotificationType {
  ACTIVITY = 'activity',
  REQUEST = 'request',
}

export const notificationTypeMap = Object.values(NotificationType);

export enum NotificationAction {
  ENTERPRISE_REQUEST = 'enterprise-request',
  SUBSCRIPTION_CHANGE = 'company-upgrade',
  STAKEHOLDER_LIMIT_REACHED = 'stakeholder-limit-reached',
}

export const notificationActionTitle: Record<NotificationAction, string> = {
  [NotificationAction.ENTERPRISE_REQUEST]: 'New enterprise request',
  [NotificationAction.SUBSCRIPTION_CHANGE]: 'Subscription Change',
  [NotificationAction.STAKEHOLDER_LIMIT_REACHED]: 'Stakeholder Threshold Reached',
};

export type NotificationAdminsRead = {
  id: string;
  notification: Notification;
  adminId: string;
  notificationId: string;
  stakeholderId: string;
};

export type AppNotification = {
  id: string;
  companyName: string;
  type: NotificationType;
  action?: NotificationAction;
  resolved: boolean;
  createdAt: Date;
  companyId: string;
  is_read: boolean;
  notificationAdminsRead: NotificationAdminsRead[];
  adminEmail?: string;
  prevSubscriptionType?: SubscriptionType;
  prevSubscriptionFreq?: SubscriptionDuration;
  currentType?: SubscriptionType;
  currentFrequency?: SubscriptionDuration;
  stakeholderLimit?: number;
};

type ActiveNotificationBaseOptions = {
  companyName: string;
  action: NotificationAction;
  prevSubscriptionType: SubscriptionType;
  prevSubscriptionFreq: SubscriptionDuration;
  currentType: SubscriptionType;
  currentFrequency: SubscriptionDuration;
  stakeholderLimit: number;
};

type RequestNotificationBaseOptions = {
  companyName: string;
};

const createCompanyUpgradeNotification = (options: ActiveNotificationBaseOptions) =>
  `${options.companyName} - Subscription plan changed from ${subscriptionTypeTitle[options.prevSubscriptionType]} (${options.prevSubscriptionFreq}) to ${subscriptionTypeTitle[options.currentType]} (${options.currentFrequency})`;

const createStakeholderLimitReachedNotification = (options: ActiveNotificationBaseOptions) =>
  `${options.companyName} (${subscriptionTypeTitle[options.currentType]}) has reached it stakeholder threshold limit (${options.stakeholderLimit})`;

const createEEnterpriseRequestNotification = (options: RequestNotificationBaseOptions) =>
  `New enterprise request by ${options.companyName}`;

const NotificationFactory = {
  createActionNotification: (options: ActiveNotificationBaseOptions) => {
    switch (options.action) {
      case NotificationAction.SUBSCRIPTION_CHANGE:
        return createCompanyUpgradeNotification(options);
      case NotificationAction.STAKEHOLDER_LIMIT_REACHED:
        return createStakeholderLimitReachedNotification(options);
      default:
        return createCompanyUpgradeNotification(options);
    }
  },

  createRequestNotification: (options: RequestNotificationBaseOptions) => {
    return createEEnterpriseRequestNotification(options);
  },
};

export const activityNotificationBuilder = NotificationFactory.createActionNotification;
export const requestNotificationBuilder = NotificationFactory.createRequestNotification;

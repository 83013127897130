import { useQueryClient } from '@tanstack/react-query';
import { createContext, FC, ReactNode, useContext, useEffect, useState } from 'react';
import { io } from 'socket.io-client';

import { QueryKey } from '../constants';
import { HttpException } from '../exceptions';
import { NotificationHooks } from '../hooks';
import { accessTokenStorage } from '../storage/accessTokenStorage';
import { AppNotification, NotificationType } from '../types/notification.types';
import { decodeJWT, toNumber, toPositive } from '../utils';

interface NotificationsContext {
  unreadNotification: { total: number } & Record<NotificationType, number>;
  handleReadNotification: (type: NotificationType) => void;
  handleReadAllNotification: () => void;
}

const initialValue: NotificationsContext = {
  unreadNotification: { activity: 0, total: 0, request: 0 },
  handleReadNotification() {},
  handleReadAllNotification() {},
};

export const notificationsContext = createContext(initialValue);

export interface NotificationsProviderProps {
  children: ReactNode;
}

export const NotificationsProvider: FC<NotificationsProviderProps> = ({ children }) => {
  const accessToken = accessTokenStorage.get() || '';
  const decodedToken = decodeJWT(accessToken);
  const adminEmail = decodedToken?.email || '';

  const { unread, isLoading } = NotificationHooks.useUnreadNotificationCount({
    adminEmail,
  });

  const { unread: activityUnread, isLoading: isLoadingActivity } =
    NotificationHooks.useUnreadNotificationCount({
      adminEmail,
      type: NotificationType.ACTIVITY,
    });

  const { unread: requestUnread, isLoading: isLoadingRequest } =
    NotificationHooks.useUnreadNotificationCount({
      adminEmail,
      type: NotificationType.REQUEST,
    });

  const [unreadNotification, setUnread] = useState<
    { total: number } & Record<NotificationType, number>
  >({
    activity: 0,
    total: 0,
    request: 0,
  });

  useEffect(() => {
    if (isLoading || isLoadingActivity || isLoadingRequest) return;

    setUnread({
      activity: toNumber(activityUnread.count),
      request: toNumber(requestUnread.count),
      total: toNumber(unread.count),
    });
  }, [
    isLoading,
    isLoadingActivity,
    isLoadingRequest,
    requestUnread.count,
    unread.count,
    activityUnread.count,
  ]);

  const handleReadNotification = (type: NotificationType) =>
    setUnread((prev) => ({
      ...prev,
      [type]: toPositive(prev[type] - 1),
      total: toPositive(prev.total - 1),
    }));

  const handleReadAllNotification = () =>
    setUnread({
      activity: 0,
      total: 0,
      request: 0,
    });

  const queryClient = useQueryClient();
  useEffect(() => {
    const socket = io(process.env.REACT_APP_WEBSOCKET_URL, {
      transports: ['websocket'],
      auth: { isAdmin: true, accessToken },
      secure: true,
      withCredentials: true,
    });

    socket.on('connect', () => {
      console.log('connected!');

      socket
        .on('disconnect', () => {
          console.log('disconnected');
        })
        .on('error', (e: HttpException) => console.error(e))
        .on('notification', (notificationString: string) => {
          const { type } = JSON.parse<Pick<AppNotification, 'id' | 'type'>>(notificationString);

          setUnread((prev) => ({
            ...prev,
            total: prev.total + 1,
            [type]: toNumber(prev[type] + 1),
          }));

          queryClient.invalidateQueries({ queryKey: [QueryKey.GET_NOTIFICATIONS] });
        });
    });

    return () => {
      socket.disconnect();
    };
  }, [queryClient]);

  return (
    <notificationsContext.Provider
      value={{
        unreadNotification,
        handleReadNotification,
        handleReadAllNotification,
      }}
    >
      {children}
    </notificationsContext.Provider>
  );
};

export const useNotifications = () => useContext(notificationsContext);

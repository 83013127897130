import { FC, ReactNode } from 'react';

import { CloseIcon } from '../../../assets/icons';
import Button from '../../../components/Button';
import { Sheet, SheetContent } from '../../../components/Sheet';

export type ModalBarProps = {
  isOpen: boolean;
  toggler: (open: boolean) => void;
  buttons: ReactNode;
  children: ReactNode;
  title: string;
};

export const ModalBar: FC<ModalBarProps> = ({ buttons, children, isOpen, toggler, title }) => (
  <Sheet onOpenChange={toggler} open={isOpen}>
    <SheetContent
      className="w-full max-w-[485px] border-transparent bg-transparent p-2 shadow-none"
      side="RIGHT"
    >
      <div className="flex h-full w-full flex-col gap-4 overflow-hidden rounded-lg border-[1px] border-gray-300 bg-gray-100 pb-4">
        <div className="flex h-full w-full flex-col gap-4 rounded-lg bg-white px-6 py-3 shadow-xs">
          <div className="flex items-center justify-between">
            <span className="text-xl font-semibold text-gray-700">{title}</span>
            <Button
              className="h-fit w-fit rounded p-2 transition-colors hover:bg-gray-200"
              onClick={() => toggler(false)}
              styleType="NONE"
            >
              <CloseIcon className="h-3 w-3" iconColor="#344054" />
            </Button>
          </div>
          <div className="flex flex-col gap-6">{children}</div>
        </div>
        <div className="flex h-9 justify-between gap-4 overflow-hidden px-6">{buttons}</div>
      </div>
    </SheetContent>
  </Sheet>
);

import { FC, useState } from 'react';

import { ChevronDownIcon } from '../../../assets/icons';
import { AlertDialogWrapper } from '../../../components/AlertDialog';
import Button from '../../../components/Button';
import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
} from '../../../components/Combobox';
import { Input } from '../../../components/Input';
import { QueryKey } from '../../../constants';
import {
  CompanyHooks,
  StakeholderHooks,
  useInvalidateQueries,
  useModalState,
} from '../../../hooks';
import {
  StakeholderRole,
  stakeholderRoleTitle,
  stakeholderRoleTitleMap,
} from '../../../types/stakeholder.types';
import { AdminChangeModal } from './AdminChangeModal';
import { ModalBar } from './ModalBar';
import { RoleChangeModal } from './RoleChangeModal';
import { UnableChangeRoleModal } from './UnableChangeRoleModal';

export const EditModal: FC = () => {
  const {
    isOpen,
    toggler,
    handleCloseModal,
    paramsValue: stakeholderId,
  } = useModalState('edit-modal');

  const { stakeholder } = StakeholderHooks.useStakeholder(stakeholderId || '');
  const [selectedRole, setSelectedRole] = useState<StakeholderRole | undefined | null>(undefined);

  const { invalidateQuery } = useInvalidateQueries(
    QueryKey.GET_STAKEHOLDERS,
    QueryKey.GET_STAKEHOLDER,
  );

  const { changeRole } = StakeholderHooks.useChangeRole();

  const { adminStakeholder } = CompanyHooks.useAdmin(stakeholder?.companyId || '');
  const { company } = CompanyHooks.useCompany(stakeholder?.companyId || '');

  const {
    isOpen: isOpenApproveModal,
    toggler: approveModalToggler,
    handleCloseModal: handleCloseApproveModal,
    handleOpenModal: handleOpenApproveModal,
    handleSuccessModal: handleSuccessApproveModal,
  } = useModalState({
    onSuccess: () => {
      selectedRole &&
        changeRole(
          { role: selectedRole, stakeholderId: stakeholderId || '' },
          {
            onSuccess: () => {
              invalidateQuery();
              handleCloseModal();
            },
          },
        );
    },
  });

  if (!stakeholder) return;

  const { role, email, fullName, isActive } = stakeholder;

  return (
    <>
      <AlertDialogWrapper control={{ open: isOpenApproveModal, onOpenChange: approveModalToggler }}>
        {selectedRole !== StakeholderRole.ADMIN && role !== StakeholderRole.ADMIN && (
          <RoleChangeModal
            onDismiss={handleCloseApproveModal}
            onSuccess={handleSuccessApproveModal}
          />
        )}
        {role !== StakeholderRole.ADMIN && selectedRole === StakeholderRole.ADMIN && (
          <AdminChangeModal
            newAdminName={fullName || ''}
            onDismiss={handleCloseApproveModal}
            onSuccess={handleSuccessApproveModal}
            prevAdminName={adminStakeholder?.fullName || ''}
          />
        )}

        {role === StakeholderRole.ADMIN && (
          <UnableChangeRoleModal
            onDismiss={handleCloseApproveModal}
            onSuccess={handleCloseApproveModal}
          />
        )}
      </AlertDialogWrapper>
      <ModalBar
        buttons={
          <>
            <Button
              className="h-full w-fit px-3 py-[6px] text-sm font-[450] text-gray-700"
              onClick={handleCloseModal}
              styleType="NONE"
              type="button"
            >
              Cancel
            </Button>
            <Button
              className="h-full w-fit px-4 py-[6px] text-sm font-[550] text-white"
              onClick={handleOpenApproveModal}
              type="submit"
            >
              Update
            </Button>
          </>
        }
        isOpen={!!stakeholderId || isOpen}
        title={fullName || ''}
        toggler={toggler}
      >
        <div className="flex flex-col gap-2">
          <Input disabled onChange={() => ''} placeholder="Full name" value={fullName || ''} />
          <Input disabled onChange={() => ''} placeholder="Email" value={email || ''} />
        </div>

        <div className="flex flex-col gap-3">
          <span className="text-base font-semibold text-gray-700">Status in company</span>
          <Input disabled onChange={() => ''} placeholder="Company" value={company?.name || ''} />
          <Input
            disabled
            onChange={() => ''}
            placeholder="Status"
            value={isActive ? 'Active' : 'Inactive'}
          />

          <Combobox
            as={'div'}
            className="relative"
            defaultValue={role}
            onChange={setSelectedRole}
            value={role}
          >
            <div className="relative">
              <ComboboxInput
                className={'absolute z-0 bg-gray-900'}
                icon={<ChevronDownIcon className="mt-3" />}
                onChange={() => ''}
                placeholder="Role"
                readOnly
                value={stakeholderRoleTitle[selectedRole || role]}
              />
              <ComboboxButton className="absolute left-0 top-0 z-1 h-full w-full" />
            </div>
            <ComboboxOptions className="w-full">
              {stakeholderRoleTitleMap.map(([stakeholderRoleKey, stakeholderRole]) => (
                <ComboboxOption key={stakeholderRole} value={stakeholderRoleKey}>
                  <span className="text-sm font-normal text-gray-700">{stakeholderRole}</span>
                </ComboboxOption>
              ))}
            </ComboboxOptions>
          </Combobox>
        </div>
      </ModalBar>
    </>
  );
};

import { FC } from 'react';
import { twMerge } from 'tailwind-merge';

import Button from '../../../components/Button';
import { CompanyHooks, StakeholderHooks, useModalState } from '../../../hooks';
import { stakeholderRoleTitle } from '../../../types/stakeholder.types';
import { ModalBar } from './ModalBar';

export type ViewItemProps = {
  title: string;
  items: { title: string; value: string | undefined; className?: string }[];
};

export const ViewItem: FC<ViewItemProps> = ({ title, items }) => (
  <div
    className={twMerge(
      'flex h-fit w-full flex-col gap-2 rounded-lg bg-gray-50 py-2',
      items.length > 0 && 'pb-0',
    )}
  >
    <span className="px-4 text-sm font-[550] text-gray-700">{title}</span>
    {items.length > 0 && (
      <div className="flex flex-col divide-y divide-gray-100 rounded-b-lg border border-gray-100 bg-white p-4 pb-0">
        {items.map(({ title, value, className }, i) => (
          <div
            className={twMerge('flex w-full gap-3 py-3', i === 0 && 'pt-0')}
            key={`${i}_${value}_${title}`}
          >
            <span className="w-[120px] flex-shrink-0 text-xs font-[450] text-gray-500">
              {title}:
            </span>
            <span
              className={twMerge(
                'max-w-[250px] truncate text-xs font-[450] text-gray-700',
                className,
              )}
            >
              {value}
            </span>
          </div>
        ))}
      </div>
    )}
  </div>
);

export const ViewModal: FC = () => {
  const {
    isOpen,
    toggler,
    paramsValue: stakeholderId,
    handleCloseModal,
  } = useModalState('view-modal');
  const { setParamValue: onEdit } = useModalState('edit-modal');

  const { stakeholder: { fullName, email, companyId = '', isActive, role } = {} } =
    StakeholderHooks.useStakeholder(stakeholderId || '');

  const { company } = CompanyHooks.useCompany(companyId);

  return (
    <ModalBar
      buttons={
        <>
          <Button
            className="h-full w-fit rounded border border-gray-100 bg-white px-3 py-[6px] text-sm font-[450] text-gray-700 shadow-xs"
            onClick={() => onEdit(stakeholderId || '')}
            styleType="NONE"
            type="button"
          >
            Edit
          </Button>

          <Button
            className="h-full w-fit px-4 py-[6px] text-sm font-[550] text-white"
            onClick={handleCloseModal}
            type="submit"
          >
            Close
          </Button>
        </>
      }
      isOpen={isOpen}
      title={fullName || ''}
      toggler={toggler}
    >
      <ViewItem
        items={[
          { title: 'Full name', value: fullName },
          { title: 'Email', value: email },
        ]}
        title="General"
      />
      <ViewItem
        items={[
          { title: 'Company', value: company?.name },
          {
            title: 'Status',
            value: isActive ? 'Active' : 'Inactive',
            className: 'text-forest-600',
          },
          { title: 'Role', value: role && stakeholderRoleTitle[role], className: 'text-brand-700' },
        ]}
        title="Status in company"
      />
    </ModalBar>
  );
};

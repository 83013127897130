import { FC } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate } from 'react-router-dom';

import { NotificationHooks } from '../../../hooks';
import {
  notificationActionTitle,
  NotificationType,
  requestNotificationBuilder,
} from '../../../types/notification.types';
import { PageRoute } from '../../../types/page.types';
import Loader from '../../Loader';
import { RequestNotification } from '../Notification';

export type RequestedTabProps = {
  unreadOnly: boolean;
  isAllRead: boolean;
  adminEmail: string;
  handleCloseModal: () => void;
};

export const RequestedTab: FC<RequestedTabProps> = ({
  unreadOnly,
  isAllRead,
  adminEmail,
  handleCloseModal,
}) => {
  const { notifications, fetchNextPage, hasNextPage } = NotificationHooks.useNotifications({
    type: NotificationType.REQUEST,
    unreadOnly,
    adminEmail,
  });

  const { markAsRead } = NotificationHooks.useMarkRead({});
  const { markAsResolved } = NotificationHooks.useMarkResolved({});

  const navigate = useNavigate();

  const handleActivate = (companyId: string) => {
    const currentPath = window.location.pathname;
    const newPath = `${PageRoute.COMPANIES}?openEditModal=true&companyId=${companyId}`;

    if (currentPath === PageRoute.COMPANIES) {
      handleCloseModal();
      navigate(newPath, { replace: true });
    } else {
      navigate(newPath);
    }
  };

  return (
    <>
      <InfiniteScroll
        className="flex w-full flex-col gap-2"
        dataLength={notifications.length || 0}
        hasMore={hasNextPage}
        loader={<Loader />}
        next={fetchNextPage}
        scrollableTarget="scroll-notifications-target"
      >
        {notifications.map(
          ({ createdAt, is_read: isRead, id, action, companyName, companyId, type, resolved }) => (
            <RequestNotification
              createdAt={new Date(createdAt)}
              isRead={isAllRead || isRead}
              key={id}
              message={requestNotificationBuilder({
                companyName,
              })}
              onActivate={() => handleActivate(companyId)}
              onMarkRead={() => markAsRead({ id, type, data: { adminEmail } })}
              onMarkResolved={() => markAsResolved({ id, data: { adminEmail } })}
              resolved={resolved}
              type={(action && notificationActionTitle[action]) || ''}
            />
          ),
        )}
      </InfiniteScroll>
    </>
  );
};

import { StakeholderRole } from '../../../types/stakeholder.types';

type StakeholderActionsProps = {
  isActive?: boolean;
  role: StakeholderRole;
  isAccessRevoked?: boolean;
};

type StakeholderPermission = {
  revokeAccess?: boolean;
  grantAccess?: boolean;
  canDelete?: boolean;
};

type Status =
  | 'ACTIVE'
  | 'INACTIVE'
  | 'ACTIVE_ACCESS_REVOKED'
  | 'INACTIVE_INVITED'
  | 'TERMINATED'
  | 'TERMINATED_ACCESS_REVOKED'
  | 'INACTIVE_NOT_INVITED';

type RolePermissions = {
  [status in Status]?: StakeholderPermission;
};

type Permissions = {
  [role in StakeholderRole]: RolePermissions;
};

const stakeholderPermissions: Permissions = {
  [StakeholderRole.STAKEHOLDER]: {
    TERMINATED: {
      canDelete: true,
      revokeAccess: true,
    },
    TERMINATED_ACCESS_REVOKED: {
      grantAccess: true,
      canDelete: true,
    },
    INACTIVE_NOT_INVITED: {
      canDelete: true,
    },
    INACTIVE_INVITED: {
      canDelete: true,
    },
    ACTIVE_ACCESS_REVOKED: {
      grantAccess: true,
      canDelete: true,
    },
    ACTIVE: {
      revokeAccess: true,
      canDelete: true,
    },
  },
  [StakeholderRole.CONTRIBUTOR]: {
    TERMINATED: {
      canDelete: true,
    },
    ACTIVE: {
      revokeAccess: true,
      canDelete: true,
    },
    INACTIVE_INVITED: {
      canDelete: true,
    },
    ACTIVE_ACCESS_REVOKED: {
      grantAccess: true,
      canDelete: true,
    },
    INACTIVE_NOT_INVITED: {
      canDelete: true,
    },
  },
  [StakeholderRole.ADMIN]: {
    TERMINATED: {
      canDelete: true,
    },
    ACTIVE: {},
  },
};
const defaultPermissions: StakeholderPermission = {
  canDelete: true,
  grantAccess: false,
  revokeAccess: false,
};

export const getStakeholderCommonActions = ({
  role,
  isAccessRevoked = false,
  isActive = false,
}: StakeholderActionsProps) => {
  switch (role) {
    case StakeholderRole.STAKEHOLDER:
      if (isAccessRevoked)
        return {
          ...defaultPermissions,
          ...stakeholderPermissions.stakeholder.ACTIVE_ACCESS_REVOKED,
        };

      if (isActive)
        return {
          ...defaultPermissions,
          ...stakeholderPermissions.stakeholder.ACTIVE,
        };

      return {
        ...defaultPermissions,
        ...stakeholderPermissions.stakeholder.INACTIVE_NOT_INVITED,
      };
    case StakeholderRole.CONTRIBUTOR:
      if (isAccessRevoked)
        return {
          ...defaultPermissions,
          ...stakeholderPermissions.contributor.ACTIVE_ACCESS_REVOKED,
        };

      if (isActive)
        return {
          ...defaultPermissions,
          ...stakeholderPermissions.contributor.ACTIVE,
        };

      return {
        ...defaultPermissions,
        ...stakeholderPermissions.contributor.INACTIVE_NOT_INVITED,
      };
    case StakeholderRole.ADMIN:
      return {
        ...defaultPermissions,
        ...stakeholderPermissions.admin.ACTIVE,
      };
  }
};

import { FC } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { NotificationHooks } from '../../../hooks';
import {
  activityNotificationBuilder,
  NotificationAction,
  notificationActionTitle,
  NotificationType,
} from '../../../types/notification.types';
import { SubscriptionDuration, SubscriptionType } from '../../../types/plan.types';
import Loader from '../../Loader';
import { ActivityNotification } from '../Notification';

export type ActivityTabProps = {
  unreadOnly: boolean;
  isAllRead: boolean;
  adminEmail: string;
};

export const ActivityTab: FC<ActivityTabProps> = ({ unreadOnly, isAllRead, adminEmail }) => {
  const { notifications, fetchNextPage, hasNextPage, invalidateQuery } =
    NotificationHooks.useNotifications({
      adminEmail,
      resolved: false,
      type: NotificationType.ACTIVITY,
      unreadOnly,
    });

  const { markAsRead } = NotificationHooks.useMarkRead({ onSuccess: invalidateQuery });

  return (
    <>
      <InfiniteScroll
        className="flex w-full flex-col gap-2"
        dataLength={notifications.length || 0}
        hasMore={hasNextPage}
        loader={<Loader />}
        next={fetchNextPage}
        scrollableTarget="scroll-notifications-target"
      >
        {notifications.map(
          ({
            createdAt,
            is_read: isRead,
            id,
            action,
            companyName,
            type,
            prevSubscriptionFreq,
            prevSubscriptionType,
            currentFrequency,
            currentType,
          }) => (
            <ActivityNotification
              createdAt={new Date(createdAt)}
              isRead={isAllRead || isRead}
              key={id}
              message={activityNotificationBuilder({
                action: action || NotificationAction.SUBSCRIPTION_CHANGE,
                companyName,
                prevSubscriptionFreq: prevSubscriptionFreq || SubscriptionDuration.MONTHLY,
                prevSubscriptionType: prevSubscriptionType || SubscriptionType.GROWTH,
                stakeholderLimit: 50,
                currentFrequency: currentFrequency || SubscriptionDuration.ANNUALY,
                currentType: currentType || SubscriptionType.START_UP,
              })}
              onMarkAsRead={() => {
                markAsRead({ id, type, data: { adminEmail } });
              }}
              type={(action && notificationActionTitle[action]) || ''}
            />
          ),
        )}
      </InfiniteScroll>
    </>
  );
};

import React, { FC } from 'react';
import { twMerge } from 'tailwind-merge';

import { IconProps } from './type';

export type CopyLinkIconProps = IconProps;

const CopyLinkIcon: FC<CopyLinkIconProps> = ({ iconColor = '#344054', className, ...props }) => (
  <svg
    className={twMerge('shrink-0', className)}
    fill="none"
    height="16"
    viewBox="0 0 16 16"
    width="16"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.33337 10C2.71212 10 2.40149 10 2.15646 9.89855C1.82976 9.76322 1.57019 9.50366 1.43487 9.17695C1.33337 8.93192 1.33337 8.6213 1.33337 8.00004V3.46671C1.33337 2.71997 1.33337 2.3466 1.4787 2.06139C1.60653 1.8105 1.8105 1.60653 2.06139 1.4787C2.3466 1.33337 2.71997 1.33337 3.46671 1.33337H8.00004C8.6213 1.33337 8.93192 1.33337 9.17695 1.43487C9.50366 1.57019 9.76322 1.82976 9.89855 2.15646C10 2.40149 10 2.71212 10 3.33337M8.13337 14.6667H12.5334C13.2801 14.6667 13.6535 14.6667 13.9387 14.5214C14.1896 14.3936 14.3936 14.1896 14.5214 13.9387C14.6667 13.6535 14.6667 13.2801 14.6667 12.5334V8.13337C14.6667 7.38664 14.6667 7.01327 14.5214 6.72805C14.3936 6.47717 14.1896 6.2732 13.9387 6.14537C13.6535 6.00004 13.2801 6.00004 12.5334 6.00004H8.13337C7.38664 6.00004 7.01327 6.00004 6.72805 6.14537C6.47717 6.2732 6.2732 6.47717 6.14537 6.72805C6.00004 7.01327 6.00004 7.38664 6.00004 8.13337V12.5334C6.00004 13.2801 6.00004 13.6535 6.14537 13.9387C6.2732 14.1896 6.47717 14.3936 6.72805 14.5214C7.01327 14.6667 7.38664 14.6667 8.13337 14.6667Z"
      stroke={iconColor}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.3"
    />
  </svg>
);

export default CopyLinkIcon;

import React, { FC, useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Loader from '../../components/Loader';
import { QueryKey } from '../../constants';
import {
  CompanyHooks,
  useInvalidateQueries,
  useModalState,
  useQueryParams,
  useScreenSize,
} from '../../hooks';
import { companiesSortStore } from '../../storage/companiesSortStorage';
import {
  CompanyAllFreqFilter,
  CompanyAllPaymentFilter,
  CompanyAllPlanFilter,
  CompanyOrder,
  CompanySortBy,
  CompanyTabType,
  NumberOfFetchedItems,
  SelectNumberOfFetchedItems,
} from '../../types/company.types';
import { BulkActionsModal } from './BullkActionsModal/BulkActionsModal';
import { CompanyHeader } from './CompanyHeader';
import { MobileFilterModal } from './CompanyMobile';
import { CompanyTab } from './CompanyTab';
import { EditSubscriptionModal } from './EditSubscriptionModal';
import { ViewCompanyModal } from './ViewCompanyModal';

const Companies: FC = () => {
  const { invalidateQuery } = useInvalidateQueries(QueryKey.GET_COMPANIES, QueryKey.GET_COMPANY);
  const { width } = useScreenSize();
  const { get } = useQueryParams();
  const isMobile = width <= 1023;

  const [selectedTab, setTab] = useState<CompanyTabType>(CompanyTabType.ALL);
  const [search, setSearch] = useState<null | string>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [numberOfFetchedItems, setNumberOfFetchedItems] = useState<SelectNumberOfFetchedItems>(
    NumberOfFetchedItems.TEN,
  );

  const [selectedPlanFilter, setSelectedPlanFilter] = useState<CompanyAllPlanFilter>('all');
  const [selectedFreqFilter, setSelectedFreqFilter] = useState<CompanyAllFreqFilter>('all');
  const [selectedPaymentFilter, setSelectedPaymentFilter] =
    useState<CompanyAllPaymentFilter>('all');
  const [selectedFilters, setSelectedFilters] = useState<{
    planType: CompanyAllPlanFilter;
    frequency: CompanyAllFreqFilter;
    paymentStatus: CompanyAllPaymentFilter;
  }>({
    planType: 'all',
    frequency: 'all',
    paymentStatus: 'all',
  });

  const [selectedCompaniesIds, setSelectedCompaniesIds] = useState<string[]>([]);
  const [selectedCompanyId, setSelectedCompanyId] = useState<string | null>(null);
  const [sortState, setSortState] = useState<{
    field: CompanySortBy | null;
    sortMode: CompanyOrder | null;
  }>(companiesSortStore.get() || { field: null, sortMode: null });

  const { companies, totalPages } = CompanyHooks.useCompanies({
    take: Number(numberOfFetchedItems),
    currentPage,
    search: search || undefined,
    orderBy: sortState.field || undefined,
    orderMode: sortState.sortMode || undefined,
    planType: isMobile ? selectedFilters.planType : selectedPlanFilter,
    planFrequency: isMobile ? selectedFilters.frequency : selectedFreqFilter,
    subscriptionStatus: isMobile ? selectedFilters.paymentStatus : selectedPaymentFilter,
  });

  const { companies: filteredCompanies, totalPages: filteredTotalPages } =
    CompanyHooks.useCompanies({
      take: Number(numberOfFetchedItems),
      currentPage,
      search: search || undefined,
      orderBy: sortState.field || undefined,
      orderMode: sortState.sortMode || undefined,
      planType: isMobile ? selectedFilters.planType : selectedPlanFilter,
      planFrequency: isMobile ? selectedFilters.frequency : selectedFreqFilter,
      subscriptionStatus: isMobile ? selectedFilters.paymentStatus : selectedPaymentFilter,
      isEnterpriseRequested: true,
    });

  const { deactivateCompany, isPending } = CompanyHooks.useDeactivate();

  const handleBulkDeactivate = async (companyIds: string[]) => {
    let hasError = false;

    for (const companyId of companyIds) {
      try {
        await new Promise<void>((resolve, reject) => {
          deactivateCompany(
            { companyId },
            {
              onSuccess: () => {
                resolve();
              },
              onError: (error) => {
                reject(error);
              },
            },
          );
        });
      } catch (error) {
        hasError = true;
      }
    }

    if (hasError) {
      toast.error('Error deactivating some companies');
    } else {
      invalidateQuery();
      setSelectedCompanyId(null);
      setSelectedCompaniesIds([]);
      toast.success(`${companyIds.length} companies have been deactivated`);
    }
  };

  const handleSelectNumberOfFetchedItems = useCallback(
    (select: SelectNumberOfFetchedItems) => {
      setNumberOfFetchedItems(select);
      invalidateQuery();
    },
    [invalidateQuery],
  );

  const handleSortField = useCallback(
    (newField: CompanySortBy) => {
      setSortState((prevState) => {
        if (prevState.field === newField) {
          const nextSortMode = !prevState.sortMode
            ? CompanyOrder.ASC
            : prevState.sortMode === CompanyOrder.ASC
              ? CompanyOrder.DESC
              : null;

          companiesSortStore.set({ ...prevState, sortMode: nextSortMode });
          return { ...prevState, sortMode: nextSortMode };
        } else {
          companiesSortStore.set({
            field: newField,
            sortMode: CompanyOrder.ASC,
          });
          return { field: newField, sortMode: CompanyOrder.ASC };
        }
      });
      invalidateQuery();
    },
    [invalidateQuery],
  );

  const {
    isOpen: isOpenBulkActionsModal,
    handleDismissModal: handleDismissBulkActionsModal,
    handleOpenModal: handleOpenBulkActionsModal,
  } = useModalState({
    onSuccess: () => setSelectedCompaniesIds([]),
  });

  const {
    handleCloseModal: handleCloseViewCompanyModal,
    handleOpenModal: handleOpenViewCompanyModal,
    isOpen: isOpenViewCompanyModal,
  } = useModalState({});

  const {
    handleCloseModal: handleCloseEditSubscriptionModal,
    handleOpenModal: handleOpenEditSubscriptionModal,
    isOpen: isOpenEditSubscriptionModal,
  } = useModalState({ initialValue: !!get('openEditModal') });

  const {
    isOpen: isOpenMobileFilterModal,
    toggler: togglerMobileFilterModal,
    handleOpenModal: handleOpenMobileFilterModal,
    handleCloseModal: handleCloseMobileFilterModal,
    handleSuccessModal: handleSuccessMobileFilterModal,
  } = useModalState();

  const handleResetFilters = () => {
    setSelectedFilters({
      planType: 'all',
      frequency: 'all',
      paymentStatus: 'all',
    });
    setSelectedPlanFilter('all');
    setSelectedFreqFilter('all');
    setSelectedPaymentFilter('all');
  };

  const handleApplyFilters = () => {
    setSelectedFilters({
      planType: selectedPlanFilter,
      frequency: selectedFreqFilter,
      paymentStatus: selectedPaymentFilter,
    });
    handleSuccessMobileFilterModal();
  };

  useEffect(() => {
    if (selectedCompaniesIds.length !== 0) return handleOpenBulkActionsModal();
    handleDismissBulkActionsModal();
  }, [handleDismissBulkActionsModal, handleOpenBulkActionsModal, selectedCompaniesIds.length]);

  useEffect(() => {
    if (isMobile) {
      setNumberOfFetchedItems(NumberOfFetchedItems.TEN);
    }
  }, [isMobile]);

  useEffect(() => {
    const isOpen = !!get('openEditModal');
    const companyId = get('companyId');

    if (isOpen && companyId) {
      handleOpenEditSubscriptionModal();
    } else {
      handleCloseEditSubscriptionModal();
    }
  }, [get('openEditModal'), get('companyId')]);

  if (isPending) return <Loader />;

  return (
    <>
      <BulkActionsModal
        companies={companies}
        handleDeactivate={(companiesIds) => handleBulkDeactivate(companiesIds)}
        isOpenModal={isOpenBulkActionsModal}
        onRemoveSelectAll={() => setSelectedCompaniesIds([])}
        selectedCompaniesIds={selectedCompaniesIds}
      />
      <ViewCompanyModal
        companyId={selectedCompanyId || ''}
        handleClose={handleCloseViewCompanyModal}
        invalidateQuery={invalidateQuery}
        isOpenModal={isOpenViewCompanyModal}
      />
      <EditSubscriptionModal
        companyId={selectedCompanyId || get('companyId') || ''}
        handleClose={handleCloseEditSubscriptionModal}
        invalidateQuery={invalidateQuery}
        isOpenModal={isOpenEditSubscriptionModal}
      />
      <MobileFilterModal
        handleApplyFilters={handleApplyFilters}
        handleCloseModal={handleCloseMobileFilterModal}
        handleResetFilters={handleResetFilters}
        isOpen={isOpenMobileFilterModal}
        selectedFreqFilter={selectedFreqFilter}
        selectedPaymentFilter={selectedPaymentFilter}
        selectedPlanFilter={selectedPlanFilter}
        setSelectedFreqFilter={setSelectedFreqFilter}
        setSelectedPaymentFilter={setSelectedPaymentFilter}
        setSelectedPlanFilter={setSelectedPlanFilter}
        toggler={togglerMobileFilterModal}
      />
      <div className="flex h-fit min-h-[400px] w-full flex-col gap-4 rounded-md lg:py-4 lg:shadow-sm">
        <CompanyHeader
          filteredCompaniesCount={filteredCompanies.length || 0}
          handleOpenMobileFilterModal={handleOpenMobileFilterModal}
          invalidateQuery={invalidateQuery}
          search={search}
          selectedFreqFilter={selectedFreqFilter}
          selectedPaymentFilter={selectedPaymentFilter}
          selectedPlanFilter={selectedPlanFilter}
          selectedTab={selectedTab}
          setCurrentPage={setCurrentPage}
          setSearch={setSearch}
          setSelectedFreqFilter={setSelectedFreqFilter}
          setSelectedPaymentFilter={setSelectedPaymentFilter}
          setSelectedPlanFilter={setSelectedPlanFilter}
          setTab={setTab}
        />
        {isMobile && <div className="h-[1px] w-full bg-gray-100" />}
        <div
          className="flex h-full w-full flex-col justify-between gap-4"
          style={{ minHeight: !isMobile ? 'calc(100vh - 216px)' : undefined }}
        >
          <CompanyTab
            companies={selectedTab === CompanyTabType.ALL ? companies : filteredCompanies}
            currentPage={currentPage}
            handleDeactivate={(companyId) => {
              deactivateCompany(
                { companyId },
                {
                  onSuccess: () => {
                    invalidateQuery();
                    setSelectedCompanyId(null);
                    setSelectedCompaniesIds([]);
                    toast.success('Company has been deactivated');
                  },
                },
              );
            }}
            handleEdit={(companyId) => {
              setSelectedCompanyId(companyId);
              handleOpenEditSubscriptionModal();
            }}
            handlePageChange={setCurrentPage}
            handleSelectNumberOfFetchedItems={handleSelectNumberOfFetchedItems}
            handleView={(companyId) => {
              setSelectedCompanyId(companyId);
              handleOpenViewCompanyModal();
            }}
            isMobile={isMobile}
            isSelected={(selectedId) => {
              return !!selectedCompaniesIds.find((id) => id === selectedId);
            }}
            isSelectStarted={selectedCompaniesIds.length !== 0}
            numberOfFetchedItems={numberOfFetchedItems}
            onRemoveSelect={(selectedId) =>
              setSelectedCompaniesIds((prev) => prev.filter((id) => id !== selectedId))
            }
            onRemoveSelectAll={() => setSelectedCompaniesIds([])}
            onSelect={(companyId) => {
              setSelectedCompaniesIds((prev) => [...prev, companyId]);
            }}
            onSelectAll={(companiesIds) => setSelectedCompaniesIds(companiesIds)}
            onSortClick={(newField) => handleSortField(newField)}
            sortField={sortState.field}
            sortMode={sortState.sortMode}
            totalPages={selectedTab === CompanyTabType.ALL ? totalPages : filteredTotalPages}
          />
        </div>
      </div>
    </>
  );
};
export default Companies;

import React, { FC } from 'react';

import { ChevronDownIcon } from '../../../assets/icons';
import Button from '../../../components/Button';
import { CompanyOrder, CompanySortBy } from '../../../types/company.types';

export type TableSortingCellProps = {
  onSortClick: (field: CompanySortBy) => void;
  sortMode: CompanyOrder | null;
  sortField: CompanySortBy | null;
  title: string;
  sortByCell: CompanySortBy;
};

export const TableSortingCell: FC<TableSortingCellProps> = ({
  onSortClick,
  sortField,
  sortMode,
  title,
  sortByCell,
}) => {
  return (
    <div className="flex items-center gap-1" onClick={() => onSortClick(sortByCell)}>
      <span className="text-label-md font-[450] text-[#172335]">{title}</span>
      <Button
        className="relative flex size-4 justify-center rounded hover:bg-gray-200"
        styleType="NONE"
      >
        {sortField === sortByCell ? (
          sortMode == null ? (
            <>
              <ChevronDownIcon className="absolute -top-[3px] w-2 rotate-180" />
              <ChevronDownIcon className="absolute -bottom-[3px] w-2" />
            </>
          ) : (
            <>
              {sortMode === CompanyOrder.ASC ? (
                <ChevronDownIcon className="w-2" />
              ) : (
                <ChevronDownIcon className="w-2 rotate-180" />
              )}
            </>
          )
        ) : (
          <>
            <ChevronDownIcon className="absolute -top-[3px] w-2 rotate-180" />
            <ChevronDownIcon className="absolute -bottom-[3px] w-2" />
          </>
        )}
      </Button>
    </div>
  );
};

import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { ApiService } from '../../services';
import { useAppMutation } from '../useAppMutation';
import { useInvalidateQueries } from '../useInvalidateQueries';

export const useAccess = () => {
  const { invalidateQuery } = useInvalidateQueries(QueryKey.GET_STAKEHOLDERS);

  const { mutate: toggleAccess, isPending } = useAppMutation([QueryKey.TOGGLE_STAKEHOLDER_ACCESS], {
    mutationFn: (stakeholderId: string) =>
      ApiService.patch({
        endpoint: BackendRoute.STAKEHOLDERS,
        routePath: [stakeholderId, 'access'],
      }),
    onSuccess: () => {
      invalidateQuery();
    },
  });
  return { toggleAccess, isPending };
};

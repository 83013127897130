import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { ApiService } from '../../services';
import { SubscriptionDuration, SubscriptionType } from '../../types/plan.types';
import { useAppQuery } from '../useAppQuery';

type GetSubscriptionResponse = {
  amount: number;
  stakeholderLimit: number;
  currency: string;
};

export const useSubscriptionData = ({
  companyId,
  type,
  frequency,
}: {
  companyId: string;
  type: SubscriptionType;
  frequency: SubscriptionDuration;
}) => {
  const queryClient = useQueryClient();

  const { data } = useAppQuery<GetSubscriptionResponse>({
    queryKey: [QueryKey.GET_SUBSCRIPTION_DATA, companyId, { type, frequency }],
    queryFn: () =>
      ApiService.get(
        { endpoint: BackendRoute.COMPANIES, routePath: [companyId, 'subscription-data'] },
        {
          queryParams: {
            type,
            frequency,
          },
        },
      ),
    enabled: !!companyId,
  });

  const invalidateQuery = useCallback(() => {
    queryClient.invalidateQueries({ queryKey: [QueryKey.GET_SUBSCRIPTION_DATA] });
  }, [queryClient]);

  return {
    data,
    invalidateQuery,
  };
};

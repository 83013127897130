import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query';
import { useMemo } from 'react';

import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { FilterVariant } from '../../pages/Stakeholders/types';
import { ApiService } from '../../services';
import {
  Stakeholder,
  StakeholderOrder,
  StakeholderSortBy,
  StakeholderType,
} from '../../types/stakeholder.types';

type GetCompaniesStakeholderResponse = {
  stakeholders: Stakeholder[];
  totalPages: number;
};

export const useStakeholders = ({
  take = 10,
  filter,
  orderBy,
  currentPage,
  search,
  searchType,
  sortBy,
  companyIds = [],
  isDeleted = false,
  exceptCompanyIds = [],
}: {
  take?: number;
  currentPage: number;
  search: string;
  companyIds?: string[];
  searchType: StakeholderType[];
  filter: FilterVariant | undefined;
  isDeleted?: boolean;
  orderBy: StakeholderOrder | null;
  sortBy: StakeholderSortBy | null;
  exceptCompanyIds?: string[];
}) => {
  const queryClient = useQueryClient();
  const skip = (currentPage - 1 <= 0 ? 0 : currentPage - 1) * take;
  const { data, fetchNextPage, hasNextPage } = useInfiniteQuery<GetCompaniesStakeholderResponse>({
    queryKey: [
      QueryKey.GET_STAKEHOLDERS,
      {
        take,
        filter,
        page: currentPage,
        sortBy,
        search,
        orderBy,
        skip,
        companyIds: companyIds.join(','),
        exceptCompanyIds: exceptCompanyIds.join(','),
        isDeleted,
      },
    ],
    queryFn: ({ pageParam = 0 }) =>
      ApiService.get(
        { endpoint: BackendRoute.STAKEHOLDERS },
        {
          queryParams: {
            take,
            skip: currentPage ? skip : pageParam,
            search,
            searchType: searchType.length > 0 ? searchType.join(',') : '',
            companyIds,
            exceptCompanyIds,
            filter: filter === 'all' ? '' : filter,
            ...(orderBy && sortBy ? { sortBy, sortOrder: orderBy } : {}),
            isDeleted,
            onlyNames: true,
          },
        },
      ),
    initialPageParam: 0,
    getNextPageParam: (lastPage, allPages) =>
      lastPage.totalPages > allPages.length ? allPages.length * take : undefined,
  });

  const stakeholderList = useMemo(
    () =>
      data?.pages?.reduce<Stakeholder[]>((acc, page) => {
        return [...acc, ...page.stakeholders];
      }, []),
    [data?.pages],
  );

  const invalidateQuery = () => {
    queryClient.invalidateQueries({ queryKey: [QueryKey.GET_STAKEHOLDERS] });
  };

  return {
    fetchNextPage,
    hasNextPage,
    invalidateQuery,
    stakeholders: stakeholderList || [],
    totalPages: data?.pages[0]?.totalPages || 0,
  };
};

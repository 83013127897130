import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { CapQuestLogo, EyeIcon } from '../../assets/icons';
import Button from '../../components/Button';
import { Input } from '../../components/Input';
import { Tag, TagVariant } from '../../components/Tag';
import { BackendRoute } from '../../config';
import { HttpException } from '../../exceptions';
import { useAppMutation } from '../../hooks';
import { ApiService } from '../../services';
import { accessTokenStorage } from '../../storage/accessTokenStorage';

interface LoginDto {
  email: string;
  password: string;
}

const Login = () => {
  const [loginDto, setLoginDto] = useState<LoginDto>({
    email: '',
    password: '',
  });
  const [isPasswordShown, setPasswordShown] = useState(false);
  const navigate = useNavigate();

  const { isError, error, isPending, mutate } = useAppMutation<void, HttpException>([], {
    mutationFn: async () => {
      const { accessToken } = await ApiService.post<{ accessToken: string }, LoginDto>(
        { endpoint: BackendRoute.LOGIN },
        { body: loginDto },
      );
      accessTokenStorage.set(accessToken);
    },
    onSuccess: () => navigate('/companies'),
    defaultErrorHandling: false,
  });

  return (
    <div className="flex h-full items-center justify-center overflow-y-auto bg-gray-50 font-inter">
      <div className="max-h-full max-lg:h-full max-lg:w-full">
        <div
          className="flex h-fit min-h-full w-full flex-col gap-8 rounded-lg bg-white px-4 pb-8 pt-16 lg:h-fit lg:w-[458px] lg:items-center lg:px-8 lg:pt-8 lg:text-center"
          style={{
            filter:
              'drop-shadow(0px 1px 2px #1018280F) drop-shadow(0px 2px 3px #1018281A) drop-shadow(0px -1px 3px #1018280A)',
          }}
        >
          <CapQuestLogo size={48} />
          <div className="flex flex-col items-center gap-4">
            <Tag title=" Admin Control Center" variant={TagVariant.BLUE} />
            <div className="text-sm font-[450] text-gray-500">
              Sign in to your CapQuest Admin account
            </div>
          </div>
          <div className="mt-2 flex w-full flex-col gap-6">
            <form className="flex flex-col gap-4">
              <Input
                isError={isError}
                onChange={(value) => setLoginDto({ ...loginDto, email: value })}
                placeholder="Email address"
                type="email"
                value={loginDto.email}
              />
              <Input
                errorMessage={error?.body?.message}
                icon={<EyeIcon variant={isPasswordShown ? 'ACTIVE' : 'CLOSE'} />}
                isError={isError}
                onChange={(value) => setLoginDto({ ...loginDto, password: value })}
                onIconClick={() => setPasswordShown((prev) => !prev)}
                placeholder="Password"
                type={isPasswordShown ? 'text' : 'password'}
                value={loginDto.password}
              />
            </form>
          </div>
          <div className="mt-2 w-full">
            <Button
              disabled={!loginDto.email.trim() || !loginDto.password.trim()}
              isLoading={isPending}
              onClick={() => mutate()}
            >
              Sing In
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;

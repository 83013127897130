import React, { FC } from 'react';
import { Control } from 'react-hook-form';

import { ChevronDownIcon } from '../../../assets/icons';
import {
  ComboboxButton,
  ComboboxOptions,
  FormCombobox,
  FormComboboxInput,
  FormComboboxOption,
} from '../../../components/Combobox';
import { subscriptionDurationTitle } from '../../../types/plan.types';
import { FormSchema } from './validation';

type FrequencyItemsListProps = {
  control: Control<FormSchema>;
};

const FrequencyItemsList: FC<FrequencyItemsListProps> = ({ control }) => {
  return (
    <>
      {Object.entries(subscriptionDurationTitle).map(([key, value]) => (
        <FormComboboxOption
          className="w-full"
          control={control}
          key={key}
          name="frequency"
          value={key}
        >
          <span className="text-sm text-gray-700">{value}</span>
        </FormComboboxOption>
      ))}
    </>
  );
};

export type FrequencyComboboxProps = {
  control: Control<FormSchema>;
};

export const FrequencyCombobox: FC<FrequencyComboboxProps> = ({ control }) => {
  return (
    <FormCombobox className="w-full" control={control} name="frequency">
      <div className="relative">
        <FormComboboxInput
          control={control}
          customValue={(value) => value && subscriptionDurationTitle[value]}
          icon={<ChevronDownIcon />}
          name="frequency"
          placeholder="Frequency"
          readOnly
        />
        <ComboboxButton className="absolute left-0 top-0 z-20 h-full w-full" />
      </div>
      <ComboboxOptions className="z-20 w-[418px] overflow-x-hidden">
        <FrequencyItemsList control={control} />
      </ComboboxOptions>
    </FormCombobox>
  );
};

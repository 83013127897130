import React, { FC, ReactNode } from 'react';
import { toast } from 'react-toastify';
import { twMerge } from 'tailwind-merge';

import { GrantAccessIcon, RevokeAccessIcon, TrashBinIcon } from '../../../assets/icons';
import Button from '../../../components/Button';
import { Checkbox } from '../../../components/Checkbox';
import { Sheet, SheetContent } from '../../../components/Sheet';
import { Stakeholder } from '../../../types/stakeholder.types';
import { SelectedStakeholder } from '../Stakeholders';
import { getStakeholderCommonActions } from './StakeholderPermissions';

const mergeTrueFields = <T extends { [key: string]: boolean }>(arr: T[]): Partial<T> => {
  let result: Partial<T> = {};

  if (arr.length === 0) {
    return result;
  }

  result = { ...arr[0] };

  Object.keys(result).forEach((key) => {
    const allTrue = arr.every((obj) => obj[key] === true);
    if (!allTrue) {
      delete result[key];
    }
  });

  return result;
};

export type BulkActionProps = {
  title: ReactNode;
  icon: ReactNode;
  onClick: () => void;
  className?: string;
};

const BulkAction: FC<BulkActionProps> = ({ icon, onClick, title, className }) => (
  <Button
    className={twMerge(
      'flex h-5 gap-2 text-nowrap pl-3 text-sm font-[450] text-gray-700',
      className,
    )}
    onClick={onClick}
    styleType="NONE"
  >
    {icon}
    {title}
  </Button>
);

const useBulkStakeholderActions = (stakeholders: SelectedStakeholder[]) => {
  const data = stakeholders.map((stakeholder) => {
    const possibleActions = getStakeholderCommonActions(stakeholder);
    return possibleActions;
  });

  return mergeTrueFields(data);
};

type ActionProps = { id: string[]; onSuccess: () => void };

export type BulkActionsProps = {
  selectedStakeholderIds: string[];
  invalidateQuery: () => void;
  onDeselectAll: () => void;
  stakeholders: Stakeholder[];
  isOpenModal: boolean;
  handleDelete: (props: ActionProps) => void;
  handleRevokeAccess: (props: ActionProps) => void;
  handleGrantAccess: (props: ActionProps) => void;
};

export const BulkActionsModal: FC<BulkActionsProps> = ({
  isOpenModal,
  selectedStakeholderIds,
  stakeholders,
  handleDelete,
  handleGrantAccess,
  handleRevokeAccess,
  onDeselectAll,
  invalidateQuery,
}) => {
  const selectedStakeholders = stakeholders.filter(({ id }) => selectedStakeholderIds.includes(id));
  const isPlural = selectedStakeholderIds.length > 1;

  const { canDelete, grantAccess, revokeAccess } = useBulkStakeholderActions(selectedStakeholders);

  const isSomeDeleted = stakeholders.some((stakeholder) => stakeholder.deletedAt);

  return (
    <Sheet modal={false} open={isOpenModal}>
      <SheetContent
        className="m-auto flex h-fit w-fit justify-center border-transparent bg-transparent p-0 pb-6 shadow-none"
        side="BOTTOM"
      >
        <div className="flex h-10 items-center justify-center divide-x divide-gray-100 rounded-[40px] bg-white px-4 py-2 shadow-2xl">
          <div className="flex h-5 items-center gap-3 text-nowrap pr-3 text-sm font-[450] text-gray-700">
            <Checkbox checked={selectedStakeholderIds.length > 0} onClick={onDeselectAll} />

            <span>
              {selectedStakeholderIds.length} row{isPlural && 's'} selected
            </span>
          </div>

          {grantAccess && (
            <BulkAction
              icon={<GrantAccessIcon className="size-4" />}
              onClick={() => {
                handleGrantAccess({
                  id: selectedStakeholderIds,
                  onSuccess: () => {
                    invalidateQuery();
                    toast.success('Access successfully granted');
                  },
                });
                onDeselectAll();
              }}
              title="Grant Access"
            />
          )}

          {revokeAccess && !isSomeDeleted && (
            <BulkAction
              className="text-fireside-500"
              icon={<RevokeAccessIcon className="size-4" iconColor="#F04438" />}
              onClick={() => {
                handleRevokeAccess({
                  id: selectedStakeholderIds,
                  onSuccess: () => {
                    invalidateQuery();
                    toast.success(
                      isPlural
                        ? 'Stakeholders access successfully revoked'
                        : 'Stakeholder access successfully revoked',
                    );
                  },
                });
                onDeselectAll();
              }}
              title="Revoke Access"
            />
          )}

          {canDelete && !isSomeDeleted && (
            <BulkAction
              className="text-fireside-500"
              icon={<TrashBinIcon className="size-4" iconColor="#F04438" />}
              onClick={() => {
                handleDelete({
                  id: selectedStakeholderIds,
                  onSuccess: () => {
                    invalidateQuery();
                    toast.success(
                      isPlural ? 'Successfully deleted' : 'Stakeholder successfully deleted',
                    );
                  },
                });
                onDeselectAll();
              }}
              title={isPlural ? 'Delete Stakeholders' : 'Delete Stakeholder'}
            />
          )}
        </div>
      </SheetContent>
    </Sheet>
  );
};

import { FC, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

import { ChevronDownIcon, PaginationArrowIcon, ThreeDotsIcon } from '../../assets/icons';
import Button from '../Button';
import { Select, SelectButton, SelectOption, SelectOptions } from '../Select';

type PaginationControllerItemProps = {
  children: ReactNode;
  isSelected?: boolean;
  className?: string;
  onClick?: () => void;
  isInactive?: boolean;
};

const paginate = (totalPages: number, currentPage: number) => {
  const pages: (number | string)[] = [];
  const maxVisiblePages = 7;
  if (totalPages <= maxVisiblePages) {
    for (let i = 1; i <= totalPages; i++) {
      pages.push(i);
    }
  } else {
    const leftEdge = 1;
    const rightEdge = totalPages;
    const leftRange = [1, 2, 3];
    const rightRange = [totalPages - 2, totalPages - 1, totalPages];

    if (currentPage < 3) {
      pages.push(...leftRange);
      pages.push('...');
      pages.push(...rightRange.slice(-3));
    } else if (currentPage > totalPages - 2) {
      pages.push(leftEdge);
      pages.push('...');
      pages.push(...rightRange);
    } else if (currentPage === totalPages - 2) {
      pages.push(leftEdge);
      pages.push('...');
      pages.push(totalPages - 3);
      pages.push(totalPages - 2);
      pages.push(totalPages - 1);
      pages.push(totalPages);
    } else {
      pages.push(leftEdge);
      if (currentPage > 4) pages.push('...');
      pages.push(currentPage - 1);
      pages.push(currentPage);
      pages.push(currentPage + 1);
      pages.push('...');
      pages.push(rightEdge);
    }
  }

  return pages;
};

export const PaginationControllerItem: FC<PaginationControllerItemProps> = ({
  children,
  isSelected = false,
  className,
  onClick,
  isInactive = false,
}) => (
  <div
    className={twMerge(
      'flex h-10 min-w-10 shrink-0 cursor-pointer items-center justify-center transition-colors',
      isSelected && 'bg-brand-25',
      !isInactive && 'hover:bg-gray-100',
      className,
    )}
    onClick={onClick}
  >
    {children}
  </div>
);

type PaginationControllerProps<T extends string> = {
  totalPages: number;
  currentPage: number;
  onClick: (pageNumber: number) => void;
  className?: string;
  itemClassName?: string;
} & Optional<{
  onFetchAmountChange: (amount: T) => void;
  itemsToFetchMap: T[];
  itemsToFetch: T;
}>;
export const PaginationController = <T extends string>({
  totalPages,
  onClick,
  currentPage,
  className,
  itemClassName,
  onFetchAmountChange,
  itemsToFetch,
  itemsToFetchMap = [],
}: PaginationControllerProps<T>) => {
  const shouldRenderPagination = !(totalPages < currentPage || totalPages <= 1);

  const pages = paginate(totalPages, currentPage);
  const handlePageChange = (currentPage: number) => {
    if (currentPage < 1) return;
    if (currentPage > totalPages) return;
    onClick?.(currentPage);
  };

  const handleItemsToFetchChange = (amount: T) => {
    onFetchAmountChange?.(amount);
  };

  return (
    <div className="flex w-full items-center justify-between p-4">
      {onFetchAmountChange && (
        <Select onChange={handleItemsToFetchChange} value={itemsToFetch}>
          {({ open }) => (
            <div className="relative rounded border-gray-200 max-lg:hidden">
              <SelectButton className="flex items-center gap-4 bg-gray-25 py-[10px] pl-[12px] pr-[6px]">
                <span className="text-m font-[450] text-gray-700">{itemsToFetch}</span>
                <ChevronDownIcon
                  className={twMerge(
                    'h-[13px] w-[13px] transition-transform duration-200',
                    open && 'rotate-180',
                  )}
                />
              </SelectButton>
              <SelectOptions
                className={twMerge(
                  'absolute left-0 right-0 top-[-6px] mt-1 max-h-60 w-[58px] -translate-y-full transform overflow-auto rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none',
                )}
              >
                {itemsToFetchMap.map((select) => (
                  <SelectOption key={select} value={select}>
                    <span className="block text-m font-normal text-gray-700">{select}</span>
                  </SelectOption>
                ))}
              </SelectOptions>
            </div>
          )}
        </Select>
      )}

      {shouldRenderPagination && (
        <div
          className={twMerge(
            'flex h-10 w-full divide-gray-200 self-end overflow-hidden rounded border-gray-200 lg:w-fit lg:divide-x-[1px] lg:border lg:shadow-xs',
            className,
          )}
        >
          <Button
            className="flex items-center gap-2 px-4 max-lg:size-9 max-lg:rounded-lg max-lg:border"
            disabled={currentPage === 1}
            onClick={() => handlePageChange(currentPage - 1)}
            styleType="NONE"
          >
            <PaginationArrowIcon iconColor="#344054" />
            <span className="text-sm font-[550] text-gray-800 max-lg:hidden">Previous</span>
          </Button>
          <div className="flex divide-x-[1px] divide-gray-200 max-lg:hidden">
            {pages.map((page, i) =>
              typeof page === 'number' ? (
                <PaginationControllerItem
                  className={itemClassName}
                  isSelected={currentPage === page}
                  key={page}
                  onClick={() => handlePageChange(page)}
                >
                  {page}
                </PaginationControllerItem>
              ) : (
                <PaginationControllerItem className={itemClassName} isInactive key={`${page}_${i}`}>
                  <ThreeDotsIcon />
                </PaginationControllerItem>
              ),
            )}
          </div>

          <div className="flex h-full w-full items-center justify-center lg:hidden">
            <span className="text-nowrap text-xs font-[450] text-gray-700">
              Page {currentPage} of {totalPages}
            </span>
          </div>
          <Button
            className="flex items-center gap-2 px-4 max-lg:size-9 max-lg:rounded-lg max-lg:border"
            disabled={currentPage === totalPages}
            onClick={() => handlePageChange(currentPage + 1)}
            styleType="NONE"
          >
            <span className="text-sm font-[550] text-gray-800 max-lg:hidden">Next</span>
            <PaginationArrowIcon className="rotate-180" iconColor="#344054" />
          </Button>
        </div>
      )}
    </div>
  );
};

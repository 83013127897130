import React, { FC, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

import {
  StakeholderRole,
  stakeholderRoleTitle,
  stakeholderTypeTitle,
} from '../../../types/stakeholder.types';

export enum StakeholderActionStatus {
  ACCESS_REVOKED = 'access-revoked',
  DELETED = 'deleted',
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

const stakeholderTitle: Record<StakeholderActionStatus | StakeholderRole, string> = {
  ...stakeholderTypeTitle,
  ...stakeholderRoleTitle,
  [StakeholderActionStatus.DELETED]: 'Deleted',
  [StakeholderActionStatus.ACCESS_REVOKED]: 'Access Revoked',
  [StakeholderActionStatus.ACTIVE]: 'Active',
  [StakeholderActionStatus.INACTIVE]: 'Inactive',
};

type StakeHolderStatusProps = {
  type: StakeholderActionStatus | StakeholderRole;
  onClick?: () => void;
  icon?: ReactNode;
  className?: string;
};

const StakeholderActionStatusVariant: Record<StakeholderActionStatus | StakeholderRole, string> = {
  [StakeholderActionStatus.ACCESS_REVOKED]: 'bg-fireside-50 text-fireside-600',
  [StakeholderActionStatus.DELETED]: 'bg-fireside-50 text-fireside-600 w-fit',
  [StakeholderActionStatus.ACTIVE]: 'bg-forest-50 text-forest-600',
  [StakeholderActionStatus.INACTIVE]: 'bg-[#FFFAEB] text-[#B54708]',
  [StakeholderRole.ADMIN]: 'bg-brand-25 text-brand-700',
  [StakeholderRole.CONTRIBUTOR]: 'bg-[#FCFAFF] text-[#7F56D9]',
  [StakeholderRole.STAKEHOLDER]: 'bg-[#F4FAFF] text-[#25A1C8]',
};

const StakeholderStatus: FC<StakeHolderStatusProps> = ({ type, onClick, icon, className }) => (
  <span
    className={twMerge(
      'flex shrink-0 items-center gap-1 rounded-2xl px-2 py-[2px] !text-label-md font-[450]',
      StakeholderActionStatusVariant[type],
      className,
    )}
    onClick={() => onClick?.()}
  >
    {stakeholderTitle[type]}
    {icon}
  </span>
);

export default StakeholderStatus;

import React, { FC } from 'react';
import { Control } from 'react-hook-form';

import { ChevronDownIcon } from '../../../assets/icons';
import {
  ComboboxButton,
  ComboboxOptions,
  FormCombobox,
  FormComboboxInput,
  FormComboboxOption,
} from '../../../components/Combobox';
import { subscriptionTypeTitle } from '../../../types/plan.types';
import { FormSchema } from './validation';

type SubscriptionItemsListProps = {
  control: Control<FormSchema>;
};

const SubscriptionItemsList: FC<SubscriptionItemsListProps> = ({ control }) => {
  return (
    <>
      {Object.entries(subscriptionTypeTitle).map(([key, value]) => (
        <FormComboboxOption
          className="w-full"
          control={control}
          key={key}
          name="planType"
          value={key}
        >
          <span className="text-sm text-gray-700">{value}</span>
        </FormComboboxOption>
      ))}
    </>
  );
};

export type SubscriptionTypeComboboxProps = {
  control: Control<FormSchema>;
};

export const SubscriptionTypeCombobox: FC<SubscriptionTypeComboboxProps> = ({ control }) => {
  return (
    <FormCombobox className="w-full" control={control} name="planType">
      <div className="relative">
        <FormComboboxInput
          control={control}
          customValue={(value) => value && subscriptionTypeTitle[value]}
          icon={<ChevronDownIcon />}
          name="planType"
          placeholder="Plan"
          readOnly
        />
        <ComboboxButton className="absolute left-0 top-0 z-20 h-full w-full" />
      </div>
      <ComboboxOptions className="z-20 w-[418px] overflow-x-hidden">
        <SubscriptionItemsList control={control} />
      </ComboboxOptions>
    </FormCombobox>
  );
};

import { Field, Label } from '@headlessui/react';
import { FC, useCallback, useMemo, useState } from 'react';

import { MinusIcon } from '../../../assets/icons';
import { Checkbox } from '../../../components/Checkbox';
import { PaginationController } from '../../../components/PaginationController';
import { Table } from '../../../components/Table';
import { StakeholderHooks } from '../../../hooks';
import {
  StakeholderBulkAction,
  StakeholderOrder,
  StakeholderSortBy,
  StakeholderType,
  stakeholderTypeTitleMap,
} from '../../../types/stakeholder.types';
import { FilterVariant } from '../types';
import { BulkActionsModal } from './BulkActionsModal';
import { StakeholderTableRow } from './Row';
import { Sort } from './Sort';
import { StakeholderItem } from './StakeholderMobile';
import {
  AmountOfFetchedItems,
  amountOfFetchedItemsMap,
  StakeholdersColumn,
  stakeholdersColumnMap,
  stakeholdersColumnTitle,
} from './types';

const findStakeholderTypeKeys = (search: string) =>
  stakeholderTypeTitleMap
    .filter(([_, value]) => (search ? value.toLowerCase().includes(search.toLowerCase()) : false))
    .map(([key]) => key as StakeholderType);

export type StakeholdersTableProps = {
  selectedFilter: FilterVariant | undefined;
  deletedOnly: boolean;
  companyFilterIds: string[];
  search: string;
  exceptCompanyIds: string[];
  currentPage: number;
  setCurrentPage: (page: number) => void;
};

export const StakeholdersTable: FC<StakeholdersTableProps> = ({
  selectedFilter: filter,
  search,
  companyFilterIds: companyIds,
  deletedOnly,
  exceptCompanyIds,
  currentPage,
  setCurrentPage,
}) => {
  const [{ orderBy, sortBy }, setSortState] = useState<{
    sortBy: StakeholderSortBy | null;
    orderBy: StakeholderOrder | null;
  }>({ orderBy: null, sortBy: null });

  const [selectedStakeholderIds, setSelectedStakeholderIds] = useState<string[]>([]);
  const [itemsToFetch, setItemsToFetch] = useState(AmountOfFetchedItems.TEN);

  const { bulkActions } = StakeholderHooks.useBulkActions();
  const onDeselectAll = () => {
    setSelectedStakeholderIds([]);
  };

  const onSelectAll = (stakeholdersId: string[]) => {
    setSelectedStakeholderIds(stakeholdersId);
  };

  const handleSelectStakeholder = (stakeholderId: string) => {
    setSelectedStakeholderIds((prev) => [...prev, stakeholderId]);
  };
  const handleDeselectStakeholder = (stakeholderId: string) => {
    setSelectedStakeholderIds((prev) => prev.filter((id) => id !== stakeholderId));
  };

  const searchType = useMemo(() => findStakeholderTypeKeys(search || ''), [search]);

  const { totalPages, stakeholders, invalidateQuery } = StakeholderHooks.useStakeholders({
    currentPage,
    filter,
    orderBy,
    sortBy,
    search,
    searchType,
    companyIds,
    isDeleted: deletedOnly,
    take: Number(itemsToFetch),
    exceptCompanyIds,
  });

  const handleSortField = useCallback(
    (newField: StakeholderSortBy) => {
      setSortState((prevState) => {
        if (prevState.sortBy === newField) {
          const nextSortMode = !prevState.orderBy
            ? StakeholderOrder.ASC
            : prevState.orderBy === StakeholderOrder.ASC
              ? StakeholderOrder.DESC
              : null;

          return { ...prevState, orderBy: nextSortMode };
        } else {
          return { sortBy: newField, orderBy: StakeholderOrder.ASC };
        }
      });
      invalidateQuery();
    },
    [invalidateQuery],
  );

  return (
    <>
      <BulkActionsModal
        handleDelete={({ id: stakeholderIds }) =>
          bulkActions({ action: StakeholderBulkAction.DELETE, stakeholderIds })
        }
        handleGrantAccess={({ id: stakeholderIds, onSuccess }) =>
          bulkActions(
            {
              action: StakeholderBulkAction.TOGGLE_ACCESS,
              stakeholderIds,
            },
            { onSuccess },
          )
        }
        handleRevokeAccess={({ id: stakeholderIds, onSuccess }) =>
          bulkActions(
            {
              action: StakeholderBulkAction.TOGGLE_ACCESS,
              stakeholderIds,
            },
            { onSuccess },
          )
        }
        invalidateQuery={invalidateQuery}
        isOpenModal={selectedStakeholderIds.length > 0}
        onDeselectAll={onDeselectAll}
        selectedStakeholderIds={selectedStakeholderIds}
        stakeholders={stakeholders}
      />

      <div className="w-full overflow-x-auto">
        <Table
          className="rounded-none max-lg:hidden"
          columns={stakeholdersColumnMap}
          columnsTitle={{
            ...stakeholdersColumnTitle,
            [StakeholdersColumn.NAME]: (
              <div
                className="flex h-full w-fit cursor-pointer items-center gap-1"
                onClick={() => handleSortField(StakeholderSortBy.NAME)}
              >
                {stakeholdersColumnTitle[StakeholdersColumn.NAME]}
                <Sort isSelected={sortBy === StakeholderSortBy.NAME} sortOrder={orderBy} />
              </div>
            ),
            [StakeholdersColumn.COMPANY]: (
              <div
                className="flex h-full w-fit cursor-pointer items-center gap-1"
                onClick={() => handleSortField(StakeholderSortBy.COMPANY)}
              >
                {stakeholdersColumnTitle[StakeholdersColumn.COMPANY]}
                <Sort isSelected={sortBy === StakeholderSortBy.COMPANY} sortOrder={orderBy} />
              </div>
            ),
            [StakeholdersColumn.STATUS]: (
              <div
                className="flex h-full w-fit cursor-pointer items-center gap-1"
                onClick={() => handleSortField(StakeholderSortBy.STATUS)}
              >
                {stakeholdersColumnTitle[StakeholdersColumn.STATUS]}
                <Sort isSelected={sortBy === StakeholderSortBy.STATUS} sortOrder={orderBy} />
              </div>
            ),
            [StakeholdersColumn.SELECT]: (
              <Field className="flex cursor-pointer items-center">
                <Checkbox
                  checked={selectedStakeholderIds.length > 0}
                  id="select-all-hurdle-plans"
                  onChange={(checked) =>
                    checked ? onSelectAll(stakeholders?.map(({ id }) => id) || []) : onDeselectAll()
                  }
                  selectIcon={<MinusIcon />}
                />
                <Label
                  className="absolute left-0 top-0 h-full w-full cursor-pointer"
                  htmlFor="select-all-hurdle-plans"
                />
              </Field>
            ),
          }}
          tHeadClassNames={{
            thClassName: 'pl-4',
            spanClassName: 'font-500 whitespace-nowrap text-#172335 text-label-md',
          }}
        >
          {stakeholders.map((stakeholder) => (
            <StakeholderTableRow
              {...stakeholder}
              id={stakeholder.id}
              isSelected={selectedStakeholderIds.includes(stakeholder.id)}
              key={stakeholder.id}
              onDeselect={handleDeselectStakeholder}
              onSelect={handleSelectStakeholder}
            />
          ))}
        </Table>
      </div>
      <div className="mt-4 flex w-full flex-col items-center justify-center gap-4 lg:hidden">
        {stakeholders.map((stakeholder) => (
          <StakeholderItem {...stakeholder} key={stakeholder.id} />
        ))}
      </div>

      <PaginationController
        currentPage={currentPage}
        itemsToFetch={itemsToFetch}
        itemsToFetchMap={amountOfFetchedItemsMap}
        onClick={setCurrentPage}
        onFetchAmountChange={setItemsToFetch}
        totalPages={totalPages}
      />
    </>
  );
};

import { Field, Label } from '@headlessui/react';
import { FC, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { CloseIcon, SearchIcon } from '../../../assets/icons';
import Button from '../../../components/Button';
import { Checkbox } from '../../../components/Checkbox';
import { Input } from '../../../components/Input';
import Loader from '../../../components/Loader';
import { Sheet, SheetContent } from '../../../components/Sheet';
import { CompanyHooks } from '../../../hooks';
import { FilterItem } from './FilterItem';

export type CompanyMobileFilterProps = {
  toggler: (state: boolean) => void;
  isOpen: boolean;
  handleDeselectCompany: (id: string) => void;
  handleSelectCompany: (id: string) => void;
  handleSelectAllCompanies: () => void;
  handleDeselectAllCompanies: () => void;
  handleResetFilters: () => void;
  handleApplyFilters: () => void;
  isSelectedAll: boolean;
  setSelectedAll: (state: boolean) => void;
  companyIds: string[];
  exceptCompanyIds: string[];
};
const take = 20;
export const CompanyMobileFilter: FC<CompanyMobileFilterProps> = ({
  isOpen,
  toggler,
  handleApplyFilters,
  handleDeselectAllCompanies,
  handleDeselectCompany,
  handleResetFilters,
  handleSelectAllCompanies,
  handleSelectCompany,
  isSelectedAll,
  setSelectedAll,
  companyIds,
  exceptCompanyIds,
}) => {
  const [search, setSearch] = useState('');
  const { companies, fetchNextPage, hasNextPage, totalPages } = CompanyHooks.useCompanies({
    search,
    take,
    onlyNames: true,
  });

  return (
    <Sheet onOpenChange={toggler} open={isOpen}>
      <SheetContent className="w-full rounded-t-lg bg-white p-4" side="BOTTOM">
        <div className="flex h-[56px] w-full items-center justify-between">
          <span className="text-base font-semibold text-gray-700">Filters</span>
          <Button
            className="centered flex !size-7 h-fit w-fit rounded-full"
            onClick={() => toggler(false)}
            style={{
              boxShadow: '0px 0px 18.67px 0px #00000014',
              border: '0.78px solid #F2F2F2',
            }}
            styleType="NONE"
          >
            <CloseIcon />
          </Button>
        </div>
        <div className="flex divide-x divide-gray-100">
          <div className="flex w-[144px] flex-col pr-4">
            <div className={'justify-start rounded bg-gray-50 px-2 py-[6px] text-sm text-gray-700'}>
              Company
            </div>
          </div>
          <div className="flex h-fit max-h-[300px] w-full flex-col gap-2 overflow-y-auto pl-4">
            <Input
              icon={<SearchIcon />}
              onChange={setSearch}
              value={search}
              wrapperClassName="h-10 w-full mb-2"
            />
            <Field className="relative flex cursor-pointer items-center gap-2 text-sm text-gray-700">
              <Checkbox
                checked={isSelectedAll}
                id="select-all-companies"
                onChange={(checked) => {
                  setSelectedAll(checked);
                  checked ? handleSelectAllCompanies() : handleDeselectAllCompanies();
                }}
              />
              <span>All Companies ({totalPages * take})</span>
              <Label
                className="absolute left-0 top-0 h-full w-full cursor-pointer"
                htmlFor="select-all-companies"
              />
            </Field>
            <InfiniteScroll
              className="flex h-fit w-full flex-col items-stretch justify-start gap-2 max-lg:justify-center"
              dataLength={companies.length}
              hasMore={hasNextPage}
              loader={<Loader />}
              next={fetchNextPage}
              scrollableTarget="scrollbar-target"
            >
              {companies.map(({ name, id }) => (
                <FilterItem
                  checked={[...companyIds, ...exceptCompanyIds].includes(id)}
                  id={id}
                  isSelectedAll={isSelectedAll}
                  key={id}
                  name={name}
                  onChange={(checked) =>
                    checked ? handleSelectCompany(id) : handleDeselectCompany(id)
                  }
                />
              ))}
            </InfiniteScroll>
          </div>
        </div>
        <div className="mt-6 flex w-full gap-4">
          <Button
            className="w-[137px] border border-gray-100 text-sm font-[450] text-gray-700"
            onClick={handleResetFilters}
            styleType="DEFAULT_ROUNDED"
          >
            Reset
          </Button>
          <Button
            className="w-full px-4 py-[10px] text-sm font-[550] text-white"
            onClick={handleApplyFilters}
          >
            Apply Filter
          </Button>
        </div>
      </SheetContent>
    </Sheet>
  );
};

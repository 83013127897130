import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { ApiService } from '../../services';
import { Stakeholder } from '../../types/stakeholder.types';
import { useAppQuery } from '../useAppQuery';

export const useStakeholder = (stakeholderId: string) => {
  const queryClient = useQueryClient();

  const { data: stakeholder, isLoading } = useAppQuery({
    queryKey: [QueryKey.GET_STAKEHOLDER, stakeholderId],
    queryFn: () =>
      ApiService.get<Stakeholder>({
        endpoint: BackendRoute.STAKEHOLDERS,
        routePath: [stakeholderId],
      }),
    enabled: !!stakeholderId,
  });

  const invalidateQuery = useCallback(() => {
    queryClient.invalidateQueries({ queryKey: [QueryKey.GET_STAKEHOLDER] });
  }, [queryClient]);

  return {
    invalidateQuery,
    stakeholder,
    isLoading,
  };
};

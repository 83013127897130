import React, { FC } from 'react';
import { twMerge } from 'tailwind-merge';

import { ChevronDownIcon } from '../../../assets/icons';
import { PaginationController } from '../../../components/PaginationController';
import { Select, SelectButton, SelectOption, SelectOptions } from '../../../components/Select';
import {
  Company,
  CompanyOrder,
  CompanySortBy,
  NumberOfFetchedItems,
  SelectNumberOfFetchedItems,
  selectVariant,
} from '../../../types/company.types';
import { CompanyMobile } from '../CompanyMobile';
import { CompanyTable } from './CompanyTable';

export type CompanyTabProps = {
  currentPage: number;
  handlePageChange: (page: number) => void;
  totalPages: number;
  companies: Company[];
  isSelectStarted: boolean;
  isSelected: (id: string) => boolean;
  onSelectAll: (id: string[]) => void;
  onRemoveSelectAll: () => void;
  onSortClick: (field: CompanySortBy) => void;
  onSelect: (id: string) => void;
  onRemoveSelect: (id: string) => void;
  handleView: (id: string) => void;
  handleEdit: (id: string) => void;
  handleDeactivate: (id: string) => void;
  sortMode: CompanyOrder | null;
  sortField: CompanySortBy | null;
  isMobile: boolean;
  handleSelectNumberOfFetchedItems: (select: SelectNumberOfFetchedItems) => void;
  numberOfFetchedItems: NumberOfFetchedItems;
};

export const CompanyTab: FC<CompanyTabProps> = ({
  currentPage,
  handlePageChange,
  totalPages,
  companies,
  isSelectStarted,
  isSelected,
  onSelectAll,
  onRemoveSelectAll,
  onSortClick,
  onSelect,
  onRemoveSelect,
  handleView,
  handleEdit,
  handleDeactivate,
  sortMode,
  sortField,
  isMobile,
  handleSelectNumberOfFetchedItems,
  numberOfFetchedItems,
}) => {
  return (
    <>
      {isMobile ? (
        <CompanyMobile
          companies={companies}
          handleDeactivate={handleDeactivate}
          handleEdit={handleEdit}
          handleView={handleView}
        />
      ) : (
        <CompanyTable
          companies={companies}
          handleDeactivate={handleDeactivate}
          handleEdit={handleEdit}
          handleView={handleView}
          isSelected={isSelected}
          isSelectStarted={isSelectStarted}
          onRemoveSelect={onRemoveSelect}
          onRemoveSelectAll={onRemoveSelectAll}
          onSelect={onSelect}
          onSelectAll={onSelectAll}
          onSortClick={onSortClick}
          sortField={sortField}
          sortMode={sortMode}
        />
      )}
      <div className="flex w-full items-center justify-between px-6 pt-4">
        {!isMobile && (
          <Select onChange={handleSelectNumberOfFetchedItems} value={numberOfFetchedItems}>
            {({ open }) => (
              <div className="relative rounded border-gray-200 py-4">
                <SelectButton className="flex items-center gap-4 bg-gray-25 py-[10px] pl-[12px] pr-[6px]">
                  <span className="text-m font-[450] text-gray-700">{numberOfFetchedItems}</span>
                  <ChevronDownIcon
                    className={twMerge(
                      'h-[13px] w-[13px] transition-transform duration-200',
                      open && 'rotate-180',
                    )}
                  />
                </SelectButton>
                <SelectOptions className="absolute left-0 right-0 top-[-6px] mt-1 max-h-60 w-[58px] -translate-y-full transform overflow-auto rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  {selectVariant.map((select) => (
                    <SelectOption key={select} value={select}>
                      <span className="block text-m font-normal text-gray-700">{select}</span>
                    </SelectOption>
                  ))}
                </SelectOptions>
              </div>
            )}
          </Select>
        )}
        <PaginationController
          className="ml-auto shrink-0"
          currentPage={currentPage}
          onClick={handlePageChange}
          totalPages={totalPages || 0}
        />
      </div>
    </>
  );
};

import React, { FC } from 'react';

import { CapQuestLogo } from '../../assets/icons';
import { useScreenSize } from '../../hooks';

const Error: FC = () => {
  const { width } = useScreenSize();
  const isMobile = width <= 1023;
  return (
    <div className="flex h-full w-full items-center justify-center overflow-y-auto">
      <div className="flex max-h-full max-lg:h-full max-lg:w-full">
        <div className="flex h-fit w-full flex-col gap-8 rounded-lg p-4 max-lg:pt-16 lg:w-fit lg:p-8 lg:shadow-sm">
          <div className="flex flex-col gap-8 border-b border-gray-200 pb-8">
            <div className="flex flex-col justify-between gap-8 lg:items-center lg:text-center">
              <CapQuestLogo className={isMobile ? 'size-12' : 'size-16'} />
              <div className="flex flex-col gap-4">
                <span
                  className="!bg-clip-text text-4xl-mobile font-bold text-transparent lg:text-4xl"
                  style={{
                    background: 'linear-gradient(132.59deg, #475467 29.58%, #101828 84.27%)',
                  }}
                >
                  Oops
                </span>
                <div className="flex flex-col">
                  <span className="text-sm font-[450] text-gray-500">
                    The link you clicked might be out of date or incorrect.
                  </span>
                  <span className="text-sm font-[450] text-gray-500">
                    Don't worry, these things happen!
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-8">
            <div className="flex flex-col gap-6 border-b border-gray-200 pb-8">
              <span className="text-base font-[450] text-black">
                Here are a few things you can try,
              </span>
              <div className="flex items-center gap-3">
                <div className="size-[10px] shrink-0 rounded-full bg-gray-500" />
                <span className="text-sm font-medium text-gray-500">
                  Check the URL for any typos and try again.
                </span>
              </div>

              <div className="flex items-center gap-3">
                <div className="size-[10px] shrink-0 rounded-full bg-gray-500" />
                <span className="text-sm font-[450] text-gray-500">
                  If you received the link in an email, try contacting the sender for a new one.
                </span>
              </div>

              <div className="flex items-center gap-3">
                <div className="size-[10px] shrink-0 rounded-full bg-gray-500" />
                <span className="text-sm font-[450] text-gray-500">
                  Use the website's search bar to find the information you were looking for.
                </span>
              </div>
            </div>
            <div className="flex flex-wrap gap-2">
              <span className="text-sm font-[450] text-gray-700">
                In the meantime, feel free to browse our
              </span>
              <a className="text-sm font-[450] lowercase text-brand-700 underline" href={'/'}>
                Website
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Error;

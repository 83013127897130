import React, { FC } from 'react';
import { twMerge } from 'tailwind-merge';

import { IconProps } from './type';

export type CheckCircleIconProps = IconProps;

const CheckCircleIcon: FC<CheckCircleIconProps> = ({
  iconColor = '#344054',
  className,
  ...props
}) => (
  <svg
    className={twMerge('shrink-0', className)}
    fill="none"
    height="20"
    viewBox="0 0 20 20"
    width="20"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.3334 9.23745V10.0041C18.3323 11.8011 17.7504 13.5497 16.6745 14.989C15.5985 16.4283 14.0861 17.4812 12.3628 17.9907C10.6395 18.5002 8.79774 18.439 7.11208 17.8163C5.42642 17.1935 3.98723 16.0425 3.00915 14.535C2.03108 13.0275 1.56651 11.2442 1.68475 9.45105C1.80299 7.65792 2.49769 5.95106 3.66525 4.58501C4.83281 3.21897 6.41068 2.26694 8.16351 1.87091C9.91635 1.47488 11.7502 1.65607 13.3917 2.38745M18.3334 3.33268L10 11.6743L7.50002 9.17435"
      stroke={iconColor}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.3"
    />
  </svg>
);

export default CheckCircleIcon;

export const replaceIdWithPath = (path: string) => {
  return ('/' + path.split('/')[1]) as PageRoute;
};

export enum PageRoute {
  HOME = '/',
  DASHBOARD = '/dashboard',
  STAKEHOLDERS = '/stakeholders',
  COMPANIES = '/companies',
  LOGS = '/logs',
  SETTINGS = '/settings',
  LOGIN = '/login',
  ERROR = '*',
}

export const pageTitle: Record<PageRoute, string> = {
  [PageRoute.HOME]: 'Home',
  [PageRoute.DASHBOARD]: 'Dashboard',
  [PageRoute.STAKEHOLDERS]: 'Stakeholders',
  [PageRoute.COMPANIES]: 'Companies',
  [PageRoute.LOGS]: 'Logs',
  [PageRoute.SETTINGS]: 'Settings',
  [PageRoute.LOGIN]: 'Login',
  [PageRoute.ERROR]: 'Error',
};

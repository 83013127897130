import { Transition } from '@headlessui/react';
import React, { FC, Fragment, useCallback, useContext, useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { ChevronDownIcon, CloseIcon, FilterIcon, SearchIcon } from '../../assets/icons';
import Button from '../../components/Button';
import { Input } from '../../components/Input';
import { Radio, RadioGroup } from '../../components/RadioGroup';
import { Select, SelectButton, SelectOption, SelectOptions } from '../../components/Select';
import { Sheet, SheetContent, SheetTrigger } from '../../components/Sheet';
import { useDebounce, useScreenSize } from '../../hooks';
import { sideBarContext } from '../../storage/sideBarContext';
import {
  arrayOfCompanyFreqFilter,
  arrayOfCompanyPaymentFilter,
  arrayOfCompanyPlanFilter,
  arrayOfCompanyTab,
  CompanyAllFreqFilter,
  CompanyAllPaymentFilter,
  CompanyAllPlanFilter,
  CompanyFreqFilterTitle,
  CompanyPaymentFilterTitle,
  CompanyPlanFilterTitle,
  CompanyTabTitle,
  CompanyTabType,
} from '../../types/company.types';

export type CompanyHeaderProps = {
  selectedTab: CompanyTabType;
  setTab: React.Dispatch<React.SetStateAction<CompanyTabType>>;
  search: string | null;
  setSearch: React.Dispatch<React.SetStateAction<string | null>>;
  invalidateQuery: () => void;
  selectedPlanFilter: CompanyAllPlanFilter;
  setSelectedPlanFilter: React.Dispatch<React.SetStateAction<CompanyAllPlanFilter>>;
  selectedFreqFilter: CompanyAllFreqFilter;
  setSelectedFreqFilter: React.Dispatch<React.SetStateAction<CompanyAllFreqFilter>>;
  selectedPaymentFilter: CompanyAllPaymentFilter;
  setSelectedPaymentFilter: React.Dispatch<React.SetStateAction<CompanyAllPaymentFilter>>;
  handleOpenMobileFilterModal: (data?: unknown) => void;
  filteredCompaniesCount: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
};

export const CompanyHeader: FC<CompanyHeaderProps> = ({
  selectedTab,
  setTab,
  search,
  setSearch,
  invalidateQuery,
  selectedFreqFilter,
  selectedPaymentFilter,
  selectedPlanFilter,
  setSelectedFreqFilter,
  setSelectedPaymentFilter,
  setSelectedPlanFilter,
  handleOpenMobileFilterModal,
  filteredCompaniesCount,
  setCurrentPage,
}) => {
  const { width } = useScreenSize();
  const inputRef = useRef<HTMLDivElement>(null);
  const { isOpen: isSideBarExpanded } = useContext(sideBarContext);

  const [isOpenSearch, setOpenSearch] = useState(false);
  const [isFocusedInput, setInputFocus] = useState(false);

  const { debounceCallback } = useDebounce({
    callback: () => {
      invalidateQuery();
    },
  });

  const handleSearch = useCallback(
    (value: string) => {
      setCurrentPage(1);
      setSearch(value);
      debounceCallback(value);
    },
    [debounceCallback],
  );

  const isMobile = width <= 1023;
  const isTablet = width <= 1233;
  const showMobileSearchBar = isTablet || (width <= 1300 && isSideBarExpanded);

  return (
    <div className="flex items-center justify-between gap-1 lg:px-4">
      {isMobile ? (
        <Select onChange={(value: CompanyTabType) => setTab(value)} value={selectedTab}>
          {({ open }) => (
            <>
              <div className="relative rounded border-gray-100">
                <SelectButton className="flex justify-between border-[#EAECF0] bg-brand-25 p-3 px-4">
                  <span className="text-sm font-[450] text-[#344054]">
                    {CompanyTabTitle[selectedTab]}
                  </span>
                  <ChevronDownIcon className="h-4 w-4 text-gray-400" />
                </SelectButton>

                <Transition
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                  show={open}
                >
                  <SelectOptions className="w-fit">
                    {arrayOfCompanyTab.map(([tabKey, tab]) => (
                      <SelectOption key={tabKey} value={tabKey}>
                        {({ selected }) => (
                          <span
                            className={twMerge(
                              selected ? 'font-[450]' : 'font-normal',
                              'block text-sm text-gray-700',
                            )}
                          >
                            {tab}
                          </span>
                        )}
                      </SelectOption>
                    ))}
                  </SelectOptions>
                </Transition>
              </div>
            </>
          )}
        </Select>
      ) : (
        <RadioGroup
          className="h-fit w-full divide-x divide-gray-200 overflow-visible rounded border border-gray-200"
          onChange={setTab}
          value={selectedTab}
        >
          {arrayOfCompanyTab.map(([tabKey, tab]) => (
            <Radio
              className="relative flex h-9 w-full items-center justify-center px-4 text-sm font-[450] text-[#858593] data-[checked]:bg-brand-25 data-[checked]:font-[550] data-[checked]:text-[#172335]"
              key={tabKey}
              styleType="NONE"
              value={tabKey}
            >
              <span className="text-ellipsis whitespace-nowrap">{tab}</span>
              {tabKey === CompanyTabType.ENTERPRISE_REQUEST && filteredCompaniesCount > 0 && (
                <span
                  className="absolute -right-1 -top-1 flex size-4 shrink-0 items-center justify-center rounded-full bg-fireside-600 text-white"
                  style={{
                    fontSize: '9px',
                    fontWeight: '600',
                    lineHeight: '11.2px',
                    textAlign: 'center',
                  }}
                >
                  {filteredCompaniesCount}
                </span>
              )}
            </Radio>
          ))}
        </RadioGroup>
      )}
      <div className="flex items-center gap-3">
        <Sheet modal={false} open={showMobileSearchBar && isOpenSearch}>
          <div className="flex items-center justify-end" ref={inputRef}>
            <Input
              onBlur={() => setInputFocus(false)}
              onChange={handleSearch}
              onFocus={() => {
                setInputFocus(true);
              }}
              placeholder={search ? '' : 'Search'}
              value={search || ''}
              wrapperClassName={twMerge(
                'transition-all duration-500 w-[200px] overflow-hidden max-h-9',
                isOpenSearch ? 'delay-500' : 'w-0',
                showMobileSearchBar && 'w-0',
              )}
            />
            {!showMobileSearchBar && isOpenSearch && typeof search === 'string' && (
              <div
                className="absolute mr-8 cursor-pointer p-2"
                onClick={() => {
                  setSearch('');
                  invalidateQuery();
                }}
              >
                <CloseIcon />
              </div>
            )}
            <SheetTrigger
              className={twMerge(
                'flex size-9 items-center justify-center border-[1px] border-gray-100 bg-gray-25 transition-all duration-300',
                !showMobileSearchBar && isOpenSearch
                  ? 'h-9 rounded-l-none rounded-br-none border-x-0 border-t-0 border-b-black'
                  : 'delay-1000',
                !showMobileSearchBar &&
                  isFocusedInput &&
                  'border-b-[2px] border-b-brand-700 transition-none',
              )}
              onClick={() => {
                setOpenSearch((prev) => !prev);
                setSearch(null);
                invalidateQuery();
              }}
            >
              <SearchIcon iconColor={isFocusedInput ? '#2565C8' : '#344054'} />
            </SheetTrigger>
          </div>
          <SheetContent
            className="w-full"
            onClick={(e) => e.stopPropagation()}
            onInteractOutside={() => {
              if (!search) {
                setOpenSearch(false);
                setSearch(null);
                invalidateQuery();
              }
            }}
            side="TOP"
          >
            <div className={twMerge('flex')}>
              <Input
                icon={search && <CloseIcon />}
                onBlur={() => setInputFocus(false)}
                onChange={handleSearch}
                onFocus={(e) => {
                  setInputFocus(true);
                  e.stopPropagation();
                }}
                onIconClick={() => {
                  setTimeout(() => {
                    setSearch('');
                    invalidateQuery();
                  }, 0);
                }}
                placeholder="Search"
                value={search || ''}
                wrapperClassName={twMerge('transition-all duration-1000 w-full')}
              />
            </div>
          </SheetContent>
        </Sheet>

        <Select
          onChange={(value: CompanyAllPlanFilter) => {
            setCurrentPage(1);
            setSelectedPlanFilter(value);
          }}
          value={selectedPlanFilter}
        >
          {({ open }) => (
            <>
              <div className="relative rounded border-gray-100 px-[6px] py-2 max-lg:hidden">
                <SelectButton className="flex justify-between border-gray-100 bg-gray-25 p-3 px-[6px]">
                  <span className="text-sm font-[450] text-[#344054]">
                    Plan: {CompanyPlanFilterTitle[selectedPlanFilter]}
                  </span>
                  <ChevronDownIcon className="h-4 w-4 text-gray-400" />
                </SelectButton>

                <Transition
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                  show={open}
                >
                  <SelectOptions className="w-[calc(100%-12px)]">
                    {arrayOfCompanyPlanFilter.map(([filterKey, filter]) => (
                      <SelectOption key={filterKey} value={filterKey}>
                        {({ selected }) => (
                          <span
                            className={twMerge(
                              selected ? 'font-[450]' : 'font-normal',
                              'block text-sm text-gray-700',
                            )}
                          >
                            {filter}
                          </span>
                        )}
                      </SelectOption>
                    ))}
                  </SelectOptions>
                </Transition>
              </div>
            </>
          )}
        </Select>

        <Select
          onChange={(value: CompanyAllFreqFilter) => {
            setCurrentPage(1);
            setSelectedFreqFilter(value);
          }}
          value={selectedFreqFilter}
        >
          {({ open }) => (
            <>
              <div className="relative rounded border-gray-100 px-[6px] py-2 max-lg:hidden">
                <SelectButton className="flex justify-between border-gray-100 bg-gray-25 p-3 px-[6px]">
                  <span className="text-sm font-[450] text-[#344054]">
                    Freq: {CompanyFreqFilterTitle[selectedFreqFilter]}
                  </span>
                  <ChevronDownIcon className="h-4 w-4 text-gray-400" />
                </SelectButton>

                <Transition
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                  show={open}
                >
                  <SelectOptions className="w-[calc(100%-12px)]">
                    {arrayOfCompanyFreqFilter.map(([filterKey, filter]) => (
                      <SelectOption key={filterKey} value={filterKey}>
                        {({ selected }) => (
                          <span
                            className={twMerge(
                              selected ? 'font-[450]' : 'font-normal',
                              'block text-sm text-gray-700',
                            )}
                          >
                            {filter}
                          </span>
                        )}
                      </SelectOption>
                    ))}
                  </SelectOptions>
                </Transition>
              </div>
            </>
          )}
        </Select>

        <Select
          onChange={(value: CompanyAllPaymentFilter) => {
            setCurrentPage(1);
            setSelectedPaymentFilter(value);
          }}
          value={selectedPaymentFilter}
        >
          {({ open }) => (
            <>
              <div className="relative rounded border-gray-100 px-[6px] py-2 max-lg:hidden">
                <SelectButton className="flex justify-between border-gray-100 bg-gray-25 p-3 px-[6px]">
                  <span className="text-sm font-[450] text-[#344054]">
                    Payment Status: {CompanyPaymentFilterTitle[selectedPaymentFilter]}
                  </span>
                  <ChevronDownIcon className="h-4 w-4 text-gray-400" />
                </SelectButton>

                <Transition
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                  show={open}
                >
                  <SelectOptions className="w-[calc(100%-12px)]">
                    {arrayOfCompanyPaymentFilter.map(([filterKey, filter]) => (
                      <SelectOption key={filterKey} value={filterKey}>
                        {({ selected }) => (
                          <span
                            className={twMerge(
                              selected ? 'font-[450]' : 'font-normal',
                              'block text-sm text-gray-700',
                            )}
                          >
                            {filter}
                          </span>
                        )}
                      </SelectOption>
                    ))}
                  </SelectOptions>
                </Transition>
              </div>
            </>
          )}
        </Select>

        {isMobile && (
          <Button
            className="flex size-9 gap-1 border-[1px] border-gray-100 bg-gray-25 px-3 py-[6px]"
            onClick={handleOpenMobileFilterModal}
          >
            <FilterIcon />
          </Button>
        )}

        {/* <Button
          className="flex size-9 gap-1 border-[1px] border-gray-100 bg-gray-25 px-3 py-[6px] lg:w-fit"
          onClick={() => ''}
        >
          <ExportIcon className="max-lg:rotate-180" />
          <span className="text-sm font-[450] text-gray-700 max-lg:hidden">
            {isSideBarExpanded && isTablet ? '' : 'Export'}
          </span>
        </Button> */}
      </div>
    </div>
  );
};

import {
  Checkbox as HeadlessCheckbox,
  CheckboxProps as HeadlessCheckboxProps,
} from '@headlessui/react';
import React, { ElementRef, FC, forwardRef, memo, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

import { CheckIcon } from '../../assets/icons';

export type CheckboxProps = {
  selectIcon?: ReactNode;
} & HeadlessCheckboxProps;

const Checkbox: FC<CheckboxProps> = memo(
  forwardRef<ElementRef<typeof HeadlessCheckbox>, CheckboxProps>(
    ({ className, selectIcon, checked, ...props }, ref) => (
      <HeadlessCheckbox
        as={'span'}
        checked={checked}
        className={(props) =>
          twMerge(
            'group flex size-4 cursor-pointer items-center justify-center rounded bg-white ring-1 ring-inset ring-gray-300 disabled:cursor-default data-[checked]:bg-brand-500 data-[checked]:ring-brand-500',
            typeof className === 'string' ? className : className?.(props),
            props.disabled &&
              'cursor-default data-[checked]:bg-gray-200 data-[checked]:ring-gray-200',
          )
        }
        ref={ref}
        {...props}
      >
        {selectIcon ? (
          <span className="flex h-full w-full items-center justify-center">{selectIcon}</span>
        ) : (
          <CheckIcon
            className="hidden size-[10px] group-data-[checked]:block"
            iconColor={props.disabled ? '#D0D5DD' : '#ffffff'}
          />
        )}
      </HeadlessCheckbox>
    ),
  ),
);

Checkbox.displayName = 'Checkbox';

export default Checkbox;

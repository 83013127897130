import { BackendRoute } from '../config';
import { HttpException } from '../exceptions';
import { accessTokenStorage } from '../storage/accessTokenStorage';
import { HttpService } from './HttpService';
import { QueryParams } from './QueryParamBuilder';

export class ApiService extends HttpService {
  private basicRoutePath = '/admin-panel';
  constructor(public readonly backendUrl = process.env.REACT_APP_BACKEND_URL) {
    super();
  }

  public buildRoutePath(routePath: (string | number)[] = []) {
    return routePath.length > 0 ? '/' + routePath.join('/') : '';
  }

  public async delete<T, B>(
    { endpoint, routePath = [] }: { endpoint: BackendRoute; routePath?: (string | number)[] },
    { body, ...params }: { body?: B; queryParams?: QueryParams } = {},
  ) {
    return this.requestWithAuth<T>(endpoint + this.buildRoutePath(routePath), {
      init: {
        method: 'DELETE',
        body: JSON.stringify(body),
      },
      ...params,
    });
  }

  // public async downloadFile<T>(
  //   {
  //     endpoint,
  //     routePath = [],
  //   }: {
  //     endpoint: BackendRoute;
  //     routePath?: (string | number)[];
  //   },

  //   { queryParams, body }: { queryParams?: QueryParams; body?: T } = {},
  // ) {
  //   try {
  //     const res = await fetch(
  //       super.buildQueryParam(
  //         this.getJoinedPath(endpoint + this.buildRoutePath(routePath)),
  //         queryParams,
  //       ),
  //       {
  //         method: body ? 'POST' : 'GET',
  //         body: JSON.stringify(body),
  //         headers: {
  //           credentials: 'include',
  //           'Content-Type': 'application/json',
  //           Authorization: `Bearer ${accessTokenStorage.get() || ''}`,
  //         },
  //       },
  //     );

  //     const filename = decodeURIComponent(
  //       res?.headers?.get('Content-Disposition')?.split('filename=')[1] || '',
  //     );
  //     const blob = await res.blob();

  //     const file = window.URL.createObjectURL(blob);
  //     const a = document.createElement('a');

  //     a.href = file;
  //     a.download = filename;

  //     document.body.appendChild(a);

  //     a.click();
  //     a.remove();

  //     URL.revokeObjectURL(file);
  //   } catch (e) {
  //     try {
  //       await this.refreshTokens();
  //     } catch (_ingore) {
  //       throw e;
  //     }
  //   }
  // }

  public async get<T>(
    {
      endpoint,
      routePath = [],
    }: {
      endpoint: BackendRoute;
      routePath?: (string | number)[];
    },
    params: { queryParams?: QueryParams } = {},
  ) {
    return this.requestWithAuth<T>(endpoint + this.buildRoutePath(routePath), {
      ...params,
    });
  }

  public getJoinedPath(endpoint: string) {
    return `${this.backendUrl}${this.basicRoutePath}${endpoint}`;
  }

  public async patch<T, B>(
    { endpoint, routePath = [] }: { endpoint: BackendRoute; routePath?: (string | number)[] },
    { body, ...params }: { queryParams?: QueryParams; body?: B } = {},
  ) {
    return this.requestWithAuth<T>(endpoint + this.buildRoutePath(routePath), {
      init: {
        method: 'PATCH',
        body: JSON.stringify(body),
      },
      ...params,
    });
  }

  public async post<T, B = unknown>(
    { endpoint, routePath = [] }: { endpoint: BackendRoute; routePath?: (string | number)[] },
    { body, ...params }: { queryParams?: QueryParams; body?: B } = {},
  ) {
    return this.requestWithAuth<T>(endpoint + this.buildRoutePath(routePath), {
      init: {
        method: 'POST',
        body: JSON.stringify(body),
      },
      ...params,
    });
  }

  // public async refreshTokens() {
  //   const { accessToken } = await super.requestWithAuth<{
  //     accessToken: string;
  //   }>(this.getJoinedPath(BackendRoute.AUTH_REFRESH));

  //   accessTokenStorage.set(accessToken);
  // }

  public async requestWithAuth<T>(
    route: string,
    {
      init,
      ...params
    }: {
      init?: RequestInit | undefined;
      queryParams?: QueryParams;
    } = {},
  ): Promise<T> {
    const accessToken = accessTokenStorage.get() || '';

    const url = this.getJoinedPath(route);

    let res: T;

    try {
      res = await super.requestWithAuth<T>(url, {
        init: {
          headers: { 'Content-Type': 'application/json', ...init?.headers },
          ...init,
        },
        ...params,
        accessToken,
      });
      return res;
    } catch (e) {
      if (!(e instanceof HttpException) || e.response.status !== 401) throw e;

      // try {
      //   await this.refreshTokens();
      // } catch (_ingore) {
      //   throw e;
      // }
      res = await super.requestWithAuth<T>(url, {
        init,
        accessToken: accessTokenStorage.get() || '',
      });
      return res;
    }
  }
}

const apiService = new ApiService();

export default apiService;

import { FC } from 'react';

import {
  CrossIcon,
  EditIcon,
  EyeIconSmall,
  GrantAccessIcon,
  RevokeAccessIcon,
  TrashBinIcon,
} from '../../../assets/icons';
import { PopoverWrapper } from '../../../components/Popover';
import { StakeholderHooks, useModalState } from '../../../hooks';
import {
  Stakeholder,
  stakeholderExTypeTitle,
  stakeholderTypeTitle,
} from '../../../types/stakeholder.types';
import { getStakeholderCommonActions } from './StakeholderPermissions';
import StakeholderStatus, { StakeholderActionStatus } from './StakeholderStatus';

type StakeholderItemProps = Stakeholder;

export const StakeholderItem: FC<StakeholderItemProps> = ({
  role,
  fullName,
  email,
  isActive,
  isAccessRevoked,
  type,
  id,
  isTerminated,
  company,
}) => {
  const { canDelete, grantAccess, revokeAccess } = getStakeholderCommonActions({
    role,
    isAccessRevoked,
    isActive,
  });

  const { toggleAccess } = StakeholderHooks.useAccess();
  const { deleteStakeholder } = StakeholderHooks.useDelete();

  const handleGrantAccess = toggleAccess;
  const handleDelete = deleteStakeholder;
  const handleRevokeAccess = toggleAccess;

  const { setParamValue: onView } = useModalState({ paramKey: 'view-modal' });
  const { setParamValue: onEdit } = useModalState({ paramKey: 'edit-modal' });

  const isDeleted = false;

  return (
    <div className="flex h-fit w-full min-w-[300px] max-w-[440px] flex-col divide-y-[1px] divide-gray-100 rounded-md shadow-sm">
      <div className="flex items-center justify-between px-3 py-4">
        <div className="flex gap-3">
          <div className="relative flex size-10 shrink-0 items-center justify-center rounded-full bg-gray-200 text-sm font-[450] text-gray-700">
            {fullName[0]}
            {isDeleted && (
              <div className="absolute bottom-0 right-0 flex size-[14px] shrink-0 items-center justify-center rounded-full border-[1px] border-white bg-fireside-600">
                <CrossIcon className="size-[10px]" iconColor="#ffffff" />
              </div>
            )}
          </div>
          <div className="flex h-full flex-col justify-between">
            <span className="max-w-[200px] truncate text-sm font-[450] text-gray-700">
              {fullName}
            </span>
            <span className="max-w-[200px] truncate text-sm text-gray-700">{email}</span>
          </div>
        </div>
        <PopoverWrapper
          items={[
            {
              onClick: () => onView(id),
              icon: <EyeIconSmall className="size-4" />,
              text: 'View Details',
            },
            {
              onClick: () => onEdit(id),
              icon: <EditIcon className="size-4" />,
              text: 'Edit Role',
            },
            {
              condition: grantAccess,
              onClick: () => handleGrantAccess(id),
              icon: <GrantAccessIcon className="size-4" />,
              text: 'Grant Access',
            },
            {
              condition: revokeAccess,
              onClick: () => handleRevokeAccess(id),
              icon: <RevokeAccessIcon className="size-4" />,
              text: 'Revoke Access',
            },
            {
              condition: canDelete,
              onClick: () => handleDelete(id),
              icon: <TrashBinIcon className="size-4" iconColor="#F04438" />,
              text: 'Delete',
              className: 'text-fireside-500',
            },
          ]}
        />
      </div>
      <div className="flex w-full justify-between p-3 pr-4">
        <span className="text-sm font-[450] text-gray-700">Company</span>
        <span className="text-sm font-[450] text-gray-700">{company?.name}</span>
      </div>
      <div className="flex w-full justify-between p-3 pr-4">
        <span className="text-sm font-[450] text-gray-700">Type</span>
        <span className="text-sm font-[450] text-gray-700">
          {isTerminated ? stakeholderExTypeTitle[type] : stakeholderTypeTitle[type]}
        </span>
      </div>

      <div className="flex gap-3 overflow-x-auto p-3">
        {isDeleted ? (
          <StakeholderStatus type={StakeholderActionStatus.DELETED} />
        ) : (
          <>
            <StakeholderStatus
              type={isActive ? StakeholderActionStatus.ACTIVE : StakeholderActionStatus.INACTIVE}
            />
            {isActive && <StakeholderStatus type={role} />}

            {!isActive && isAccessRevoked && (
              <StakeholderStatus type={StakeholderActionStatus.ACCESS_REVOKED} />
            )}
          </>
        )}
      </div>
    </div>
  );
};

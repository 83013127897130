import { NotificationType } from '../../types/notification.types';

export enum ActivityType {
  RESOLVED = 'resolved',
}

export type ActivityVariant = ActivityType | NotificationType;

export const activityVariantTitle: Record<ActivityType | NotificationType, string> = {
  [NotificationType.ACTIVITY]: 'Activity',
  [NotificationType.REQUEST]: 'Requests',
  [ActivityType.RESOLVED]: 'Resolved',
};

export const activityVariantMap = Object.entries(activityVariantTitle);

import React, { FC, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { CloseIcon } from '../../../assets/icons';
import Button from '../../../components/Button';
import { Checkbox } from '../../../components/Checkbox';
import { Sheet, SheetContent } from '../../../components/Sheet';
import {
  arrayOfCompanyFreqFilter,
  arrayOfCompanyPaymentFilter,
  arrayOfCompanyPlanFilter,
  CompanyAllFreqFilter,
  CompanyAllPaymentFilter,
  CompanyAllPlanFilter,
  FilterType,
} from '../../../types/company.types';

export type MobileFilterModalProps = {
  isOpen: boolean;
  toggler: (state: boolean) => void;
  handleCloseModal: () => void;
  selectedPlanFilter: CompanyAllPlanFilter;
  setSelectedPlanFilter: React.Dispatch<React.SetStateAction<CompanyAllPlanFilter>>;
  selectedFreqFilter: CompanyAllFreqFilter;
  setSelectedFreqFilter: React.Dispatch<React.SetStateAction<CompanyAllFreqFilter>>;
  selectedPaymentFilter: CompanyAllPaymentFilter;
  setSelectedPaymentFilter: React.Dispatch<React.SetStateAction<CompanyAllPaymentFilter>>;
  handleResetFilters: () => void;
  handleApplyFilters: () => void;
};

export const MobileFilterModal: FC<MobileFilterModalProps> = ({
  isOpen,
  toggler,
  handleCloseModal,
  selectedPlanFilter,
  selectedFreqFilter,
  selectedPaymentFilter,
  setSelectedPlanFilter,
  setSelectedFreqFilter,
  setSelectedPaymentFilter,
  handleResetFilters,
  handleApplyFilters,
}) => {
  const [selectedFilter, setSelectedFilter] = useState<FilterType>(FilterType.BY_TYPE);

  return (
    <Sheet onOpenChange={toggler} open={isOpen}>
      <SheetContent className="w-full rounded-t-lg bg-white p-4" side="BOTTOM">
        <div className="flex h-[56px] w-full items-center justify-between">
          <span className="text-base font-semibold text-gray-700">Filters</span>
          <Button
            className="centered flex !size-7 h-fit w-fit rounded-full"
            onClick={handleCloseModal}
            style={{
              boxShadow: '0px 0px 18.67px 0px #00000014',
              border: '0.78px solid #F2F2F2',
            }}
            styleType="NONE"
          >
            <CloseIcon />
          </Button>
        </div>
        <div className="flex h-full divide-x divide-gray-100">
          <div className="flex w-[144px] flex-col pr-4">
            <Button
              className={twMerge(
                'justify-start px-2 py-[6px] text-sm text-gray-700',
                selectedFilter === FilterType.BY_TYPE && 'rounded bg-gray-50 font-[550]',
              )}
              onClick={() => setSelectedFilter(FilterType.BY_TYPE)}
              styleType="NONE"
            >
              Plan Type
            </Button>
            <Button
              className={twMerge(
                'justify-start px-2 py-[6px] text-sm text-gray-700',
                selectedFilter === FilterType.BY_FREQ && 'rounded bg-gray-50 font-[550]',
              )}
              onClick={() => setSelectedFilter(FilterType.BY_FREQ)}
              styleType="NONE"
            >
              Frequency
            </Button>
            <Button
              className={twMerge(
                'justify-start text-nowrap px-2 py-[6px] text-sm text-gray-700',
                selectedFilter === FilterType.BY_PAYMENT && 'rounded bg-gray-50 font-[550]',
              )}
              onClick={() => setSelectedFilter(FilterType.BY_PAYMENT)}
              styleType="NONE"
            >
              Payment Status
            </Button>
          </div>
          <div className="flex w-full flex-col pl-4">
            {selectedFilter === FilterType.BY_TYPE && (
              <>
                {arrayOfCompanyPlanFilter.map(([sortKey, sortTitle]) => (
                  <div
                    className="flex w-full items-center gap-2 rounded p-[6px] pr-8 text-sm text-gray-700"
                    key={sortKey}
                  >
                    <Checkbox
                      checked={sortKey === selectedPlanFilter}
                      className="rounded-full"
                      onChange={() => setSelectedPlanFilter(sortKey)}
                    />
                    <span className="text-sm font-normal text-gray-700">{sortTitle}</span>
                  </div>
                ))}
              </>
            )}
            {selectedFilter === FilterType.BY_FREQ && (
              <>
                {arrayOfCompanyFreqFilter.map(([sortKey, sortTitle]) => (
                  <div
                    className="flex w-full items-center gap-2 rounded p-[6px] pr-8 text-sm text-gray-700"
                    key={sortKey}
                  >
                    <Checkbox
                      checked={sortKey === selectedFreqFilter}
                      className="rounded-full"
                      onChange={() => setSelectedFreqFilter(sortKey)}
                    />
                    <span className="text-sm font-normal text-gray-700">{sortTitle}</span>
                  </div>
                ))}
              </>
            )}
            {selectedFilter === FilterType.BY_PAYMENT && (
              <>
                {arrayOfCompanyPaymentFilter.map(([sortKey, sortTitle]) => (
                  <div
                    className="flex w-full items-center gap-2 rounded p-[6px] pr-8 text-sm text-gray-700"
                    key={sortKey}
                  >
                    <Checkbox
                      checked={sortKey === selectedPaymentFilter}
                      className="rounded-full"
                      onChange={() => setSelectedPaymentFilter(sortKey)}
                    />
                    <span className="text-sm font-normal text-gray-700">{sortTitle}</span>
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
        <div className="mt-10 flex w-full gap-4">
          <Button
            className="w-[137px] border border-gray-100 text-sm font-[450] text-gray-700"
            onClick={handleResetFilters}
            styleType="DEFAULT_ROUNDED"
          >
            Reset
          </Button>
          <Button
            className="w-full px-4 py-[10px] text-sm font-[550] text-white"
            onClick={handleApplyFilters}
          >
            Apply Filter
          </Button>
        </div>
      </SheetContent>
    </Sheet>
  );
};

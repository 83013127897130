import React, { FC } from 'react';

import {
  CopyLinkIcon,
  EditIcon,
  EyeIconSmall,
  SlashCircleIcon,
  ThreeDotsIcon,
} from '../../../assets/icons';
import { Popover, PopoverClose, PopoverContent, PopoverTrigger } from '../../../components/Popover';
import { Tag, TagVariant } from '../../../components/Tag';
import { useFormat } from '../../../hooks';
import { Company, CompanyFreqFilterTitle } from '../../../types/company.types';
import {
  SubscriptionStatus,
  subscriptionStatusTitle,
  subscriptionTypeTitle,
} from '../../../types/plan.types';
import { copyToClipboard } from '../../../utils/copy-to-clipboard';

export type CompanyMobileProps = {
  companies: Company[];
  handleView: (id: string) => void;
  handleEdit: (id: string) => void;
  handleDeactivate: (id: string) => void;
};

export const CompanyMobile: FC<CompanyMobileProps> = ({
  companies,
  handleView,
  handleEdit,
  handleDeactivate,
}) => {
  const { format } = useFormat();
  return (
    <>
      <div className="flex w-full flex-col items-center justify-center gap-4">
        {companies.map((company) => (
          <div
            className="flex h-fit w-full min-w-[300px] max-w-[440px] flex-col divide-y-[1px] divide-gray-100 rounded-md shadow-sm"
            key={company.id}
          >
            <div className="flex items-center justify-between px-3 py-4">
              <div className="flex h-full flex-col justify-between">
                <span className="max-w-[200px] truncate py-[6px] text-m font-semibold text-gray-700">
                  {company.name}
                </span>
                <span className="max-w-[200px] truncate text-xs font-[450] text-gray-700">
                  {company.adminName}
                </span>
              </div>
              <Popover>
                <PopoverTrigger className="h-fit w-fit rounded data-[state=open]:shadow-md">
                  <div className="flex size-6 rotate-90 items-center justify-center rounded bg-transparent transition-colors hover:bg-gray-100">
                    <ThreeDotsIcon />
                  </div>
                </PopoverTrigger>
                <PopoverContent className="flex w-fit flex-col gap-[2px] rounded p-2 font-normal shadow-2xl [&>*]:font-inter [&>*]:font-normal">
                  <PopoverClose>
                    <div
                      className="flex w-full items-center gap-2 p-[6px] pr-8 text-sm text-gray-700 hover:bg-gray-50"
                      onClick={() => handleView(company?.id)}
                    >
                      <EyeIconSmall />
                      <span className="text-sm font-normal text-gray-700">View Company</span>
                    </div>

                    <div
                      className="flex w-full items-center gap-2 p-[6px] pr-8 text-sm text-gray-700 hover:bg-gray-50"
                      onClick={() => handleEdit(company?.id)}
                    >
                      <EditIcon />
                      <span className="text-sm font-normal text-gray-700">Edit subscription</span>
                    </div>

                    <div
                      className="flex w-full items-center gap-2 p-[6px] pr-8 text-sm text-gray-700 hover:bg-gray-50"
                      onClick={() => copyToClipboard(company?.lastInvoiceLink)}
                    >
                      <CopyLinkIcon />
                      <span className="text-sm font-normal text-gray-700">Copy Link</span>
                    </div>

                    {!company.toCancelAt && (
                      <div
                        className="flex w-full items-center gap-2 p-[6px] pr-8 text-sm text-gray-700 hover:bg-gray-50"
                        onClick={() => handleDeactivate(company?.id)}
                      >
                        <SlashCircleIcon />
                        <span className="text-sm font-normal text-gray-700">
                          Deactivate Company
                        </span>
                      </div>
                    )}
                  </PopoverClose>
                </PopoverContent>
              </Popover>
            </div>
            <div className="flex w-full justify-between p-3 pr-4">
              <span className="text-sm font-[450] text-gray-700">Stakeholders</span>
              <span className="text-sm font-[450] text-gray-700">{company?.stakeholdersCount}</span>
            </div>
            <div className="flex w-full justify-between p-3 pr-4">
              <span className="text-sm font-[450] text-gray-700">Plan</span>
              <span className="text-sm font-[450] text-gray-700">
                {company?.planType ? subscriptionTypeTitle[company?.planType] : '-'}
              </span>
            </div>
            <div className="flex w-full justify-between p-3 pr-4">
              <span className="text-sm font-[450] text-gray-700">Frequency</span>
              <span className="text-sm font-[450] text-gray-700">
                {company?.planFrequency ? CompanyFreqFilterTitle[company?.planFrequency] : '-'}
              </span>
            </div>
            <div className="flex w-full justify-between p-3 pr-4">
              <span className="text-sm font-[450] text-gray-700">Status</span>
              {company?.isEnterpriseRequested ? (
                <Tag title="Awaiting for enterprise request approval" variant={TagVariant.YELLOW} />
              ) : company?.isActive && !company?.toCancelAt ? (
                <Tag title="Active" variant={TagVariant.GREEN} />
              ) : company?.toCancelAt && new Date(company?.toCancelAt) > new Date() ? (
                <Tag
                  title={`Active until ${format(company?.toCancelAt, 'dd/MM/yyyy')}`}
                  variant={TagVariant.YELLOW}
                />
              ) : (
                <Tag title="Inactive" variant={TagVariant.GRAY} />
              )}
            </div>
            <div className="flex w-full justify-between p-3 pr-4">
              <span className="text-sm font-[450] text-gray-700">Payment</span>
              {company?.subscriptionStatus === SubscriptionStatus.ACTIVE ||
              company?.subscriptionStatus === SubscriptionStatus.TRIALING ? (
                <Tag title="Active" variant={TagVariant.GREEN} />
              ) : (
                <Tag
                  title={
                    company?.subscriptionStatus
                      ? subscriptionStatusTitle[company?.subscriptionStatus]
                      : 'No subscription'
                  }
                  variant={TagVariant.GRAY}
                />
              )}
            </div>
            <div className="flex w-full justify-between p-3 pr-4">
              <span className="text-sm font-[450] text-gray-700">Next Payment</span>
              <span className="text-sm font-[450] text-gray-700">
                {company?.nextPayment ? format(company?.nextPayment * 1000, 'dd/MM/yyyy') : '-'}
              </span>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { ApiService } from '../../services';
import { StakeholderRole } from '../../types/stakeholder.types';
import { useAppMutation } from '../useAppMutation';

export const useChangeRole = () => {
  const { mutate: changeRole } = useAppMutation([QueryKey.PATCH_STAKEHOLDER_ROLE], {
    mutationFn: ({ role, stakeholderId }: { stakeholderId: string; role: StakeholderRole }) =>
      ApiService.patch({
        endpoint: BackendRoute.STAKEHOLDERS,
        routePath: [stakeholderId, 'change-role', role],
      }),
  });
  return { changeRole };
};

import { Field, Label } from '@headlessui/react';
import { FC } from 'react';

import {
  EditIcon,
  EyeIconSmall,
  GrantAccessIcon,
  RevokeAccessIcon,
  TrashBinIcon,
} from '../../../assets/icons';
import { AlertDialogWrapper } from '../../../components/AlertDialog';
import { Checkbox } from '../../../components/Checkbox';
import { PopoverWrapper } from '../../../components/Popover';
import { TableRow } from '../../../components/Table';
import { StakeholderHooks, useModalState } from '../../../hooks';
import { Stakeholder } from '../../../types/stakeholder.types';
import { Modal } from './Modal';
import { getStakeholderCommonActions } from './StakeholderPermissions';
import StakeholderStatus, { StakeholderActionStatus } from './StakeholderStatus';
import { StakeholdersColumn, stakeholdersColumnMap } from './types';

export type StakeholderTableRowProps = {
  isSelected: boolean;
  onSelect: (stakeholderId: string) => void;
  onDeselect: (stakeholderId: string) => void;
  id: string;
} & Stakeholder;

export const StakeholderTableRow: FC<StakeholderTableRowProps> = ({
  isSelected,
  onDeselect,
  onSelect,
  id,
  isActive,
  isAccessRevoked,
  role,
  company,
  email,
  fullName,
  deletedAt,
}) => {
  const { canDelete, grantAccess, revokeAccess } = getStakeholderCommonActions({
    role,
    isAccessRevoked,
    isActive,
  });

  const { toggleAccess } = StakeholderHooks.useAccess();
  const { deleteStakeholder } = StakeholderHooks.useDelete();

  const handleGrantAccess = toggleAccess;
  const handleDelete = deleteStakeholder;
  const handleRevokeAccess = toggleAccess;

  const {
    isOpen: isOpenDeleteModal,
    handleOpenModal: handleOpenDeleteModal,
    handleCloseModal: handleCloseDeleteModal,
    handleSuccessModal: handleSuccessDeleteModal,
  } = useModalState({ onSuccess: () => handleDelete(id) });
  const {
    isOpen: isOpenRevokeAccessModal,
    handleOpenModal: handleOpenRevokeAccessModal,
    handleCloseModal: handleCloseRevokeAccessModal,
    handleSuccessModal: handleSuccessRevokeAccessModal,
  } = useModalState({ onSuccess: () => handleRevokeAccess(id) });

  const isDeleted = deletedAt || null;

  const { setParamValue: onView } = useModalState('view-modal');
  const { setParamValue: onEdit } = useModalState('edit-modal');

  return (
    <>
      <AlertDialogWrapper control={{ open: isOpenDeleteModal }}>
        <Modal
          cancelButtonText="Deny"
          onDismiss={handleCloseDeleteModal}
          onSuccess={handleSuccessDeleteModal}
          submitButtonText="Approve"
          text={`Are you sure you want to delete ${fullName} access to ${company?.name}? The Stakeholder will be unable to access CapQuest.`}
          title="Delete Stakeholder"
        />
      </AlertDialogWrapper>
      <AlertDialogWrapper control={{ open: isOpenRevokeAccessModal }}>
        <Modal
          cancelButtonText="Cancel"
          onDismiss={handleCloseRevokeAccessModal}
          onSuccess={handleSuccessRevokeAccessModal}
          submitButtonText="Revoke"
          text={`Are you sure you want to revoke ${fullName} access to ${company?.name}? The Stakeholder will be unable to access CapQuest.`}
          title="Revoke Access"
        />
      </AlertDialogWrapper>
      <TableRow
        columns={stakeholdersColumnMap}
        fields={{
          id,
          [StakeholdersColumn.SELECT]: (
            <Field className="flex h-full w-full items-center">
              <Checkbox
                checked={isSelected}
                id={id}
                onChange={(checked) => {
                  checked ? onSelect(id) : onDeselect(id);
                }}
              />
              <Label className="absolute left-0 top-0 h-full w-full cursor-pointer" htmlFor={id} />
            </Field>
          ),
          [StakeholdersColumn.NAME]: fullName,
          [StakeholdersColumn.EMAIL]: email,
          [StakeholdersColumn.COMPANY]: company?.name || '-',
          [StakeholdersColumn.STATUS]: isDeleted ? (
            <StakeholderStatus type={StakeholderActionStatus.DELETED} />
          ) : (
            <div className="flex items-center gap-4">
              <StakeholderStatus
                type={isActive ? StakeholderActionStatus.ACTIVE : StakeholderActionStatus.INACTIVE}
              />
              {isActive && <StakeholderStatus type={role} />}
              {isAccessRevoked && (
                <StakeholderStatus type={StakeholderActionStatus.ACCESS_REVOKED} />
              )}
            </div>
          ),
          [StakeholdersColumn.POPOVER]: isDeleted ? (
            ''
          ) : (
            <PopoverWrapper
              items={[
                {
                  onClick: () => onView(id),
                  icon: <EyeIconSmall className="size-4" />,
                  text: 'View Details',
                },
                {
                  onClick: () => onEdit(id),
                  icon: <EditIcon className="size-4" />,
                  text: 'Edit Role',
                },
                {
                  condition: grantAccess,
                  onClick: () => handleGrantAccess(id),
                  icon: <GrantAccessIcon className="size-4" />,
                  text: 'Grant Access',
                },
                {
                  condition: revokeAccess,
                  onClick: handleOpenRevokeAccessModal,
                  icon: <RevokeAccessIcon className="size-4" />,
                  text: 'Revoke Access',
                },
                {
                  condition: canDelete,
                  onClick: handleOpenDeleteModal,
                  icon: <TrashBinIcon className="size-4" iconColor="#F04438" />,
                  text: 'Delete',
                  className: 'text-fireside-500',
                },
              ]}
            />
          ),
        }}
        spanClassName="max-w-[300px] w-full  truncate"
        tdClassName="pl-4"
        trClassName={isSelected && 'bg-gray-50'}
      />
    </>
  );
};

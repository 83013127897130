import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { ApiService } from '../../services';
import { useAppMutation } from '../useAppMutation';
import { useInvalidateQueries } from '../useInvalidateQueries';

export const useDelete = () => {
  const { invalidateQuery } = useInvalidateQueries(QueryKey.GET_STAKEHOLDERS);
  const { mutate: deleteStakeholder } = useAppMutation([QueryKey.DELETE_STAKEHOLDER], {
    mutationFn: (stakeholderId: string) =>
      ApiService.delete({
        endpoint: BackendRoute.STAKEHOLDERS,
        routePath: [stakeholderId],
      }),
    onSuccess: () => {
      invalidateQuery();
    },
  });

  return {
    deleteStakeholder,
  };
};

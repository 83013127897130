export enum StakeholdersColumn {
  SELECT = 'select',
  NAME = 'name',
  EMAIL = 'email',
  COMPANY = 'company',
  STATUS = 'status',
  POPOVER = 'popover',
}

export enum AmountOfFetchedItems {
  FIVE = '5',
  TEN = '10',
  FIFTEEN = '15',
}

export const amountOfFetchedItemsMap = Object.values(AmountOfFetchedItems);

export const stakeholdersColumnMap = Object.values(StakeholdersColumn);

export const stakeholdersColumnTitle: Record<StakeholdersColumn, string> = {
  [StakeholdersColumn.SELECT]: '',
  [StakeholdersColumn.NAME]: 'Name',
  [StakeholdersColumn.EMAIL]: 'Email Address',
  [StakeholdersColumn.COMPANY]: 'Company',
  [StakeholdersColumn.STATUS]: 'Status',
  [StakeholdersColumn.POPOVER]: '',
};

import React from 'react';

import { LoaderIcon } from '../../assets/icons';

const CircleLoader = () => {
  return (
    <div className="animate-spin">
      <LoaderIcon bgIconColor="#93BAF7" iconColor="#ffffff" />
    </div>
  );
};

export default CircleLoader;

import React, { FC } from 'react';

import { CopyLinkIcon } from '../../../assets/icons';
import { SummaryItem } from '../../../components/SummaryItem';
import { Company } from '../../../types/company.types';
import { copyToClipboard } from '../../../utils/copy-to-clipboard';
import {
  companyAnnualRevenueTranslation,
  companyIndustryTranslation,
  companyNumberOfEmployeesTranslation,
  companyTypeTranslation,
} from './types';

export type CompanyDetailsProps = {
  company?: Company;
};

export const CompanyDetails: FC<CompanyDetailsProps> = ({ company }) => {
  return (
    <>
      <div className="flex h-fit flex-col gap-2 rounded-lg bg-gray-50 pt-2">
        <span className="px-4 text-sm font-[550] text-gray-700">General Info</span>
        <div className="flex flex-col divide-y divide-gray-100 rounded-b-lg border border-gray-100 bg-white p-4 pb-3">
          <SummaryItem className="pt-0" title="Name" value={company?.name} />
          <SummaryItem title="URL" value={company?.url || '-'} />
          <SummaryItem
            title="Industry"
            value={company?.industry && companyIndustryTranslation[company.industry]}
          />
          <SummaryItem
            title="Company Type"
            value={company?.type && companyTypeTranslation[company.type]}
          />
          <SummaryItem
            title="Annual Revenue"
            value={company?.annualRevenue && companyAnnualRevenueTranslation[company.annualRevenue]}
          />
          <SummaryItem
            title="No of Employees"
            value={
              company?.numberOfEmployees &&
              companyNumberOfEmployeesTranslation[company.numberOfEmployees]
            }
          />
          <SummaryItem title="Comp. Identifier" value={company?.companyId || '-'} />
          <SummaryItem title="Admin" value={company?.adminName} />
          <div className="flex w-full gap-3 py-3 pb-0">
            <span className="w-[120px] flex-shrink-0 text-xs font-[450] text-gray-500">
              Admin email:
            </span>
            <div className="flex items-center gap-4">
              <span className="max-w-[250px] truncate text-xs font-[450] text-brand-700 underline">
                {company?.adminEmail}
              </span>
              <CopyLinkIcon
                className="cursor-pointer p-[1px]"
                iconColor="#2565C8"
                onClick={() => copyToClipboard(company?.adminEmail)}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="flex h-fit flex-col gap-2 rounded-lg bg-gray-50 pt-2">
        <div className="flex w-full justify-between px-4">
          <span className="text-sm font-[550] text-gray-700">Address Info</span>
        </div>
        <div className="flex flex-col divide-y divide-gray-100 rounded-b-lg border border-gray-100 bg-white p-4 pb-3">
          <SummaryItem className="pt-0" title="Country" value={company?.countryName} />
          <SummaryItem title="Currency" value={company?.currencyName} />
          <SummaryItem title="State" value={company?.stateName} />
          <SummaryItem title="City" value={company?.city} />
          <SummaryItem title="Zip Code" value={company?.zipCode} />
          <SummaryItem className="pb-0" title="Address" value={company?.address} />
        </div>
      </div>
    </>
  );
};

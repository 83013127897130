import { useQueryClient } from '@tanstack/react-query';

import { QueryKey } from '../constants';

export const useInvalidateQueries = (...keys: QueryKey[]) => {
  const queryClient = useQueryClient();

  return {
    invalidateQuery: () => {
      for (const key of keys) {
        queryClient.invalidateQueries({ queryKey: [key] });
      }
    },
  };
};

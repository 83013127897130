import { FC, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import Button from '../../Button';
import { getDateIntervalFromToday } from '../formatDate';

type NotificationProps = {
  type: string;
  createdAt: Date;
  message: string;
  isRead: boolean;
  resolved: boolean;
  onMarkRead: () => void;
  onActivate: () => void;
  onMarkResolved: () => void;
};

export const RequestNotification: FC<NotificationProps> = ({
  createdAt,
  isRead,
  resolved,
  message,
  onMarkRead,
  type,
  onActivate,
  onMarkResolved,
}) => {
  const [read, setRead] = useState(false);
  const [isResolved, setResolved] = useState(false);
  return (
    <div
      className={twMerge(
        'flex flex-col gap-4 rounded-lg border border-brand-50 bg-brand-25 px-4 py-3',
        (isRead || read) && 'border-gray-200 bg-white',
      )}
    >
      <div className="flex w-full justify-between text-label-md font-medium text-gray-500">
        <span>{type}</span>
        <span>{getDateIntervalFromToday(createdAt)}</span>
      </div>
      <div className="flex w-full gap-3">
        <div className="size-10 shrink-0 rounded-full bg-brand-700" />
        <div className="flex w-full flex-col gap-4">
          <span className="text-sm font-[450] text-black">{message}</span>
          <div className="flex w-full gap-4 max-lg:flex-col lg:justify-between">
            {!(isRead || read) && (
              <>
                <Button
                  className="w-fit text-sm font-medium text-brand-700"
                  onClick={() => {
                    onMarkRead();
                    setRead(true);
                  }}
                  styleType="NONE"
                >
                  Mark as read
                </Button>
              </>
            )}
            {!(resolved || isResolved) && (
              <div className="flex h-9 justify-end gap-2 text-sm font-[450] text-gray-700">
                <Button
                  className="w-fit rounded border border-gray-100 bg-white px-3 shadow-xs"
                  onClick={() => {
                    onMarkResolved();
                    setResolved(true);
                  }}
                  styleType="NONE"
                >
                  Mark as Resolved
                </Button>
                <Button className="w-fit px-4 font-[550] text-white" onClick={onActivate}>
                  Activate
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { ApiService } from '../../services';
import { useAppMutation } from '../useAppMutation';
import { useNotifications } from '../../context/notifications.context';

export const useMarkReadAll = ({ onSuccess }: { onSuccess?: () => void }) => {
  const { handleReadAllNotification } = useNotifications();
  const { mutate: markAllAsRead } = useAppMutation([QueryKey.MARK_NOTIFICATION_AS_READ], {
    mutationFn: ({ data }: { data: { adminEmail: string } }) =>
      ApiService.post(
        {
          endpoint: BackendRoute.NOTIFICATIONS,
          routePath: ['mark-read-all'],
        },
        { body: data },
      ),
    onSuccess: () => {
      handleReadAllNotification();
      onSuccess?.();
    },
  });

  return {
    markAllAsRead,
  };
};
